import React, { useEffect, useState } from 'react';
import '../newDiscount/NewDiscount.css';
import './EditDiscount.css'
import { updateDiscountFailure, updateDiscountStart, updateDiscountSuccess } from '../../../redux/discountRedux';
import { userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { validateRequired } from '../../../formValidation';
import { getAllDiscounts, getProducts } from '../../../redux/apiCalls';
import Popup from '../../../global/Popup';
import { MEDIA_URL, isUrl } from '../../../config';

const EditDiscount = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    useEffect(() => {
        getAllDiscounts(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const discountCouponId = location.pathname.split('/')[2];

    const discount = useSelector((state) => state.discounts.discounts.find((item) => item._id === discountCouponId));


    const [couponCode, setCouponCode] = useState(discount && discount.couponCode);
    const [amount, setAmount] = useState(discount && discount.amount);
    const [minOrder, setMinOrder] = useState(discount && discount.minOrder);
    const [discountType, setDiscountType] = useState(discount && discount.discountType);
    const [validFrom, setValidFrom] = useState(discount && discount.validFrom);
    const [validTill, setValidTill] = useState(discount && discount.validTill);
    const [isActive, setIsActive] = useState(discount && discount.isActive);
    const [isForSpecificProduct, setIsForSpecificProduct] = useState(discount && discount.isForSpecificProducts);
    const [selectedProducts, setSelectedProducts] = useState(discount && discount.eligibleProducts ? discount.eligibleProducts !== null && discount.eligibleProducts : []);
    // const [isForSpecificProduct, setIsForSpecificProduct] = useState(discount && discount.isForSpecificProducts);
    const [couponError, setCouponError] = useState(false);
    const [couponErrorMessage, setCouponErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [discountTypeError, setDiscountTypeError] = useState(false);
    const [discountTypeErrorMessage, setDiscountTypeErrorMessage] = useState('');
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);

    useEffect(() => {
        setCouponCode(discount && discount.couponCode);
        setAmount(discount && discount.amount);
        setMinOrder(discount && discount.minOrder);
        setDiscountType(discount && discount.discountType);
        setValidFrom(discount && discount.validFrom);
        setValidTill(discount && discount.validTill);
        setIsActive(discount && discount.status)
        setIsForSpecificProduct(discount && discount.isForSpecificProducts);
        setSelectedProducts(discount && discount.eligibleProducts);
    }, [discount])


    

    const { isFetching, products } = useSelector((state) => state.products);


    const [searchTerm, setSearchTerm] = useState('');


    const handleStatusChange = (e) => {
        // Handle the change of the "For Specific Product" dropdown
        const status = e.target.value;
        // Do something with the selected status (true or false)
    };

    const handleSearchChange = (e) => {
        // Handle the change of the search input
        setSearchTerm(e.target.value);
    };

    // const handleProductSelect = (e) => {
    //     // Handle the change of selected products in the multi-select dropdown
    //     const selectedProductIds = Array.from(e.target.selectedOptions, option => option.value);
    //     setSelectedProducts(selectedProductIds);
    // };

    const handleProductToggle = (productId) => {
        setSelectedProducts((prevSelectedProducts) => {
            if (prevSelectedProducts.includes(productId)) {
                // Remove the product if it's already selected
                return prevSelectedProducts.filter((id) => id !== productId);
            } else {
                // Add the product if it's not selected
                return [...prevSelectedProducts, productId];
            }
        });
    };


    // Filter products based on the search term
    const filteredProducts = products?.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const generateCouponCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 16;
        let code = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            code += characters.charAt(randomIndex);
        }

        setCouponCode(code);
    };

    const handleCouponChange = (event) => {
        setCouponCode(event.target.value);
    };

    const updateDiscountCoupon = async (dispatch, discountCouponId, coupon) => {
        dispatch(updateDiscountStart());
        try {

            const res = await userRequest.put(`/discountCoupon/${discountCouponId}`, coupon);
            if (res.status === 200) {
                setActivePopup(true)
                setPopuptext("Coupon has been updated");
                setPopupStatus(true);
            }
            dispatch(updateDiscountSuccess(res.data));

        } catch (error) {
            dispatch(updateDiscountFailure());
            if (error.response.status === 409) {
                setCouponError(true);
                setCouponErrorMessage(error.response.data);
            }

            else {
                setSuccess(false);
                setError(true);
                setErrorMessage("Something went wrong!");
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const couponValidErr = validateRequired(couponCode);
        const amountValidErr = validateRequired(amount);
        // const minOrderValidErr = validateRequired(minOrder);
        const discountTypeValidErr = validateRequired(discountType);
        const validFromValidErr = validateRequired(validFrom);
        const validTillValidErr = validateRequired(validTill);

        if (couponValidErr) {
            formIsValid = false;
            newErrors.coupon = couponValidErr;
        }
        if (amountValidErr) {
            formIsValid = false;
            newErrors.amount = amountValidErr;
        }
        // if (minOrderValidErr) {
        //     formIsValid = false;
        //     newErrors.minOrder = minOrderValidErr;
        // }
        if (discountTypeValidErr) {
            formIsValid = false;
            newErrors.discountType = discountTypeValidErr;
        }
        if (validFromValidErr) {
            formIsValid = false;
            newErrors.validFrom = validFromValidErr;
        }
        if (validTillValidErr) {
            formIsValid = false;
            newErrors.validTill = validFromValidErr;
        }

        if (minOrder === 0) {
            formIsValid = false;
            newErrors.minOrder = "Minimum order value should be greater than 0";
        }

        if (discountType === 'percentage' && amount > 100) {
            formIsValid = false;
            newErrors.amount = "Discount percentage should be less than or equal to 100";
        }

        if (selectedProducts.length < 1 && isForSpecificProduct === "true" || isForSpecificProduct === true) {
            formIsValid = false;
            newErrors.selectProductError = "Please select at least one product"
        }


        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end======

    const handleUpdateDiscount = () => {
        setCouponError(false);
        setDiscountTypeError(false);
        setSuccess(false);

        if (validateForm()) {
            const trimmmedCoupon = couponCode.trim();
            if (isForSpecificProduct === "true" || isForSpecificProduct === true) {
                updateDiscountCoupon(dispatch, discountCouponId, { couponCode: trimmmedCoupon, amount, minOrder, discountType, validFrom, validTill, isActive, isForSpecificProducts: isForSpecificProduct, eligibleProducts: selectedProducts });
            }
            else {
                updateDiscountCoupon(dispatch, discountCouponId, { couponCode: trimmmedCoupon, amount, minOrder, discountType, validFrom, validTill, isActive, isForSpecificProducts: isForSpecificProduct, eligibleProducts: [] });
            }
        }
    }

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    return (
        <>
            <div className='container'>
                <div className='row mt-100'>
                    {/* <div className="col-12">
                        <h2 className='page-heading mb-4 pl-4'>Update Coupon</h2>
                    </div> */}
                    {/* <div className="col-lg-4">
                        <div className="discountCouponShowWrapper">
                            <h2 className='editCouponInnerHeading'>Current Details</h2>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>coupon code</p>
                                <p>{discount.couponCode}</p>
                            </div>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>Amount</p>
                                <p>{discount.amount}</p>
                            </div>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>Discount Type</p>
                                <p>{discount.discountType}</p>
                            </div>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>Minimum Order</p>
                                <p>{discount.minOrder}</p>
                            </div>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>Valid From</p>
                                <p>{discount.validFrom}</p>
                            </div>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>Valid Till</p>
                                <p>{discount.validTill}</p>
                            </div>
                            <div className="discountCouponShowItems mb-2">
                                <p className='item-heading'>Status</p>
                                <p>{discount.isActive ? "Active" : "Inactive"}</p>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-12">
                        <div className="discountCardWrapper">
                            <div className="discountHeader">
                                <h2 className='editCouponInnerHeading'>Update Coupon</h2>
                            </div>
                            <div className="discountMiddleWrapper pt-2">
                                <div className="row gy-3">
                                    <div className="col-12">
                                        <div className="discountInputFieldWrapper">
                                            <label>Enter a promocode</label>
                                            <div className="dicountInfoWrapper">
                                                <input type="text" value={couponCode} onChange={handleCouponChange} placeholder="e.g. coupon100" required />
                                                {
                                                    couponError && (
                                                        <p className='error-text text-danger'>{couponErrorMessage}</p>
                                                    )
                                                }
                                                <span className='error-text'>{errors.coupon}</span>
                                                <button className='btn couponGenerateBtn text-capitalize' onClick={generateCouponCode}>Generate a coupon</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="discountInputFieldWrapper">
                                            {/* <label>Discount (In Rs)</label>
                                            <div className="dicountInfoWrapper">
                                                <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 999" />
                                                <span className='error-text'>{errors.amount}</span>
                                            </div> */}
                                            {
                                                discountType === 'fixed' && (
                                                    <>
                                                        <label>Discount (In Rs)</label>
                                                        <div className="dicountInfoWrapper">
                                                            <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 999" />
                                                            <span className='error-text'>{errors.amount}</span>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            {
                                                discountType === 'percentage' && (
                                                    <>
                                                        <label>Discount (In Percentage)</label>
                                                        <div className="dicountInfoWrapper">
                                                            <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 50%" />
                                                            <span className='error-text'>{errors.amount}</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {/* <label>Amount (In Rs)</label>
                                            <div className="dicountInfoWrapper">
                                                <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="e.g. 999" />
                                                <span className='error-text'>{errors.amount}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="discountInputFieldWrapper">
                                            <label>Discount Type</label>
                                            <div className="dicountTypeBtnWrapper">
                                                <button className={`${discountType == "fixed" ? "active" : ""} discountTypeBtn btn`} value="fixed" type="btn" onClick={(e) => setDiscountType(e.target.value)} >fixed</button>
                                                <button className={`${discountType == "percentage" ? "active" : ""} discountTypeBtn btn`} value="percentage" type="btn" onClick={(e) => setDiscountType(e.target.value)} >percentage</button>
                                            </div>
                                            {
                                                discountTypeError && (
                                                    <p className='error-text'>{discountTypeErrorMessage}</p>
                                                )
                                            }
                                            <span className='error-text'>{errors.discountType}</span>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="discountInputFieldWrapper">
                                            <label>Minimum Order</label>
                                            <div className="dicountInfoWrapper">
                                                <input value={minOrder} placeholder='e.g. 999' onChange={(e) => setMinOrder(e.target.value)} />
                                                <span className='error-text'>{errors.minOrder}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="discountInputFieldWrapper">
                                            <label>Valid From</label>
                                            <div className="dicountInfoWrapper">
                                                <input type="date" value={validFrom} onChange={(e) => setValidFrom(e.target.value)} />
                                                <span className='error-text'>{errors.validFrom}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="discountInputFieldWrapper">
                                            <label>Valid Till</label>
                                            <div className="dicountInfoWrapper">
                                                <input type="date" value={validTill} onChange={(e) => setValidTill(e.target.value)} />
                                                <span className='error-text'>{errors.validTill}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="discountInputFieldWrapper">
                                            <label>Status</label>
                                            <div className="dicountInfoWrapper">
                                                <select name="status" value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                    <option value="" disabled>Select status</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="discountInputFieldWrapper">
                                            <label>For Specific Product</label>
                                            <div className="dicountInfoWrapper">
                                                <select value={isForSpecificProduct} onChange={(e) => setIsForSpecificProduct(e.target.value)}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isForSpecificProduct === "true" || isForSpecificProduct === true ? (
                                            <div className="col-12">
                                                <div className="discountInputFieldWrapper">
                                                    <label>Select Product</label>
                                                    <div className="dicountInfoWrapper">
                                                        <input type="text" placeholder='Search here...' value={searchTerm}
                                                            onChange={handleSearchChange} />
                                                        <div className="product-list-wrapper">
                                                            {filteredProducts.map((product, index) => {
                                                                return (
                                                                    <button className={`product-option-button ${selectedProducts?.length > 0 && selectedProducts?.includes(product._id) ? 'selected' : ''
                                                                        }`} key={index} onClick={() => handleProductToggle(product?._id)}>
                                                                        <div className="product-option-wrapper">
                                                                            <div className="option-img-wrapper">
                                                                                {
                                                                                    isUrl(product.productImg) ? (
                                                                                        <img src={product.productImg} alt='img' />
                                                                                    ) : (
                                                                                        <img src={MEDIA_URL + product.productImg} alt='img' />
                                                                                    )
                                                                                }

                                                                            </div>
                                                                            <p>{product.title}</p>
                                                                            <span className={`${product.status === true ? "green-bg" : "red-bg"} product-status`}>{product.status === true ? "active" : "draft"}</span>
                                                                        </div>
                                                                    </button>
                                                                )
                                                            })}
                                                        </div>
                                                        <span className='error-text'>{errors.selectProductError}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="col-12">
                                        <div className="discountSubmitBtnWrapper">
                                            <button className='custom-btn btn' onClick={handleUpdateDiscount}>Update</button>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        {
                                            success ? (
                                                <div className='alert alert-success'>
                                                    {successMessage}
                                                </div>
                                            ) : error && (
                                                <div className='alert alert-danger'>
                                                    {errorMessage}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditDiscount
