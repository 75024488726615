import React from 'react';
import './SchoolHODTable.css';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const SchoolHODTable = () => {

    // const currentSchool = useSelector((state) => state.user.currentUser);
    const user = useSelector((state) => state.user.currentUser);

    let schoolId;

    user.isSchoolPrincipal ? schoolId = user._id : schoolId = user.schoolId;

    // const schoolId = currentSchool._id;


    // School HOD variable
    const schoolHOD = useSelector((state) => state.allUsers.users.filter((item) => item.schoolId === schoolId && item.isSchoolHOD === true));

    // Add index numbers to the  array
    const schoolHODWithIndex = schoolHOD?.map((schoolHOD, index) => ({
        ...schoolHOD,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));




    // columns for HOD
    const hodColumn = [
        { field: 'index', headerName: '#', width: 50 },
        // { field: '_id', headerName: 'ID', width: 200 },
        // {
        //     field: 'schoolName',
        //     headerName: 'School',
        //     width: 200,
        //     editable: false,
        //     renderCell: (params) => {
        //         return params.row.schoolId === schoolId ? user.schoolName : "Not associated with this school"
        //     }
        // },
        {
            field: 'firstName',
            headerName: 'HOD Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        // {
        //     field: 'edit',
        //     headerName: 'Edit',
        //     // sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Link to={'/edit-school-hod/' + params.id}>
        //                     <button className='btn btn-primary studentListEdit'>Edit</button>
        //                 </Link>
        //                 {/* <DeleteOutline className='studentListDelete text-danger' /> */}
        //             </>
        //         )
        //     }
        // },
    ];

    return (
        <div className='container'>
            <div className="row py-5">
                <div className="col-12">
                    <div className="schoolTeacherTableWrapper">
                        <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                            <h2>School HODs</h2>
                            <Link to={`/add-school-hod/${schoolId}`}>
                                <button className='custom-btn purple-bg'>Create HOD <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div>
                        {
                            schoolHOD && (
                                <DataGrid
                                    rows={schoolHODWithIndex}
                                    getRowId={(rows) => rows._id}
                                    columns={hodColumn}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            )
                        }

                        {
                            schoolHOD.length < 1 && (
                                <p className='text-center'>No HOD found</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolHODTable
