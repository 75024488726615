import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Link } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Face6OutlinedIcon from '@mui/icons-material/Face6Outlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import ThreePIcon from '@mui/icons-material/ThreeP';
import VideocamIcon from '@mui/icons-material/Videocam';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import EventIcon from '@mui/icons-material/Event';
import QuizIcon from '@mui/icons-material/Quiz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { useSelector } from 'react-redux';

const SidebarNav = () => {
    const { collapseSidebar } = useProSidebar();
    const { isFetching, currentUser: userType } = useSelector((state) => state.user);

    const [allMeritusAdmin, setAllMeritusAdmin] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [schoolPrincipal, setSchoolPrincipal] = useState(false);
    const [schoolAdmin, setSchoolAdmin] = useState(false);
    const [schoolHOD, setSchoolHOD] = useState(false);
    const [schoolTeacher, setSchoolTeacher] = useState(false);
    const [meritusTeacher, setMeritusTeacher] = useState(false);

    useEffect(() => {
        if (userType) {
            if (userType.isMeritusSuperAdmin || userType.isMeritusAdmin) {
                setAllMeritusAdmin(true);
            }

            if (userType.isMeritusSuperAdmin) {
                setSuperAdmin(true);
            }

            if (userType.isSchoolPrincipal) {
                setSchoolPrincipal(true);
            }

            if (userType.isSchoolAdmin) {
                setSchoolAdmin(true);
            }

            if (userType.isSchoolHOD) {
                setSchoolHOD(true);
            }

            if (userType.isSchoolTeacher) {
                setSchoolTeacher(true);
            }

            if(userType.isMeritusTeacher){
                setMeritusTeacher(true)
            }
        }

    }, [userType])



    if (isFetching) {
        return <h2>logging you out</h2>
    }
    return (
        <div id='sidebar'>
            <Sidebar collapsedWidth="100px">
                <div className='sidebar-top'>
                    {/* <h3>Meritus</h3> */}
                    <div className="sidebar-logo-wrapper">
                        <img className='logo' src={`${process.env.PUBLIC_URL}/images/logo/brand-logo.svg`}  alt="logo" />
                    </div>
                    <div className='sidebar-hamburger-wrapper' onClick={() => console.log(collapseSidebar())}>
                        <MenuOutlinedIcon />
                    </div>
                </div>
                <Menu>

                    {
                        allMeritusAdmin && (
                            <>
                                <MenuItem component={<Link to="/" />}
                                    icon={<DashboardOutlinedIcon />}
                                >Dashboard
                                </MenuItem>
                                <span className='sidebar-category'>E-Commerce</span>
                                <MenuItem component={<Link to="/course" />} icon={<MenuBookOutlinedIcon />}> Courses</MenuItem>
                                <MenuItem component={<Link to="/robotix-products" />} icon={<MenuBookOutlinedIcon />}> Robotix Product</MenuItem>
                                {/* <MenuItem component={<Link to="/quiz" />} icon={<QuizIcon />}> Quiz</MenuItem> */}
                                {/* <MenuItem component={<Link to="/all-libraries" />} icon={<VideocamIcon />}> Lectures</MenuItem> */}
                                <MenuItem component={<Link to="/users" />} icon={<Groups2OutlinedIcon />}> Users</MenuItem>
                                <MenuItem component={<Link to="/orders" />} icon={<ShoppingCartCheckoutOutlinedIcon />}> Orders</MenuItem>
                                <MenuItem component={<Link to="/discount" />} icon={<DiscountOutlinedIcon />}> Discount</MenuItem>
                                <MenuItem component={<Link to="/giftCards" />} icon={<DiscountOutlinedIcon />}> Gift Card</MenuItem>
                                <MenuItem component={<Link to="/blogs" />} icon={<ArticleIcon />}>Blog</MenuItem>
                                <MenuItem component={<Link to="/testimonials" />} icon={<ThreePIcon />}>Testimonial</MenuItem>
                                <MenuItem component={<Link to="/events" />} icon={<EventIcon />}>Events</MenuItem>
                                <MenuItem component={<Link to="/news" />} icon={<NewspaperIcon />}>News</MenuItem>
                                <MenuItem component={<Link to="/coming-soon" />} icon={<EmojiEventsIcon />}>Referral Program</MenuItem>
                                <MenuItem component={<Link to="/custom-section" />} icon={<AutoFixHighIcon />}>Custom Section</MenuItem>
                                <MenuItem component={<Link to="/contact-form-queries" />} icon={<ContactPhoneIcon />}>Contact Form Queries</MenuItem>
                                <MenuItem component={<Link to="/school-form-queries" />} icon={<ContactPhoneIcon />}>School Form Queries</MenuItem>
                                <MenuItem component={<Link to="/success-stories" />} icon={<VideocamIcon />}>Video Testimonials</MenuItem>
                                <MenuItem component={<Link to="/milestone" />} icon={<TrackChangesIcon />}>Milestone</MenuItem>
                            </>
                        )
                    }

                    {
                        superAdmin && (
                            <>
                                <span className='sidebar-category'>Admin</span>
                                <MenuItem component={<Link to="/our-admins" />} icon={<SupervisorAccountOutlinedIcon />}>Admin</MenuItem>
                            </>
                        )
                    }

                    {
                        allMeritusAdmin && (
                            <>
                                <span className='sidebar-category'>LMS</span>
                                <SubMenu label="D2C" icon={<Groups2OutlinedIcon />}>
                                    <MenuItem component={<Link to="/student" />} icon={<Face6OutlinedIcon />}>Student</MenuItem>
                                    <MenuItem component={<Link to="/teacher" />} icon={<SupportAgentOutlinedIcon />}> Trainers</MenuItem>
                                </SubMenu>
                                {/* <MenuItem component={<Link to="/D2C" />} icon={<Groups2OutlinedIcon />}> D2C</MenuItem> */}
                                <SubMenu label="B2B" icon={<Groups2OutlinedIcon />}>
                                    <MenuItem component={<Link to="/b2b-school" />} icon={<ApartmentOutlinedIcon />}>School</MenuItem>
                                    {/* <MenuItem component={<Link to="/franchises" />} icon={<ApartmentOutlinedIcon />}>Franchise</MenuItem> */}
                                </SubMenu>
                            </>
                        )
                    }

                    {
                        meritusTeacher && (
                            <>
                                {/* <MenuItem component={<Link to="/" />} icon={<Face6OutlinedIcon />}>Dashboard</MenuItem> */}
                                <MenuItem component={<Link to="/our-students" />} icon={<Face6OutlinedIcon />}>Students</MenuItem>
                            </>
                        )
                    }

                    {
                        schoolPrincipal && (
                            <>
                                {/* <MenuItem component={<Link to="/" />} icon={<Face6OutlinedIcon />}>Dashboard</MenuItem> */}
                                <MenuItem component={<Link to="/school-admins" />} icon={<Face6OutlinedIcon />}>Admin</MenuItem>
                                <MenuItem component={<Link to="/school-hod" />} icon={<Face6OutlinedIcon />}>HOD</MenuItem>
                                <MenuItem component={<Link to="/school-teacher" />} icon={<Face6OutlinedIcon />}>Teacher</MenuItem>
                                <MenuItem component={<Link to="/school-students" />} icon={<Face6OutlinedIcon />}>Student</MenuItem>
                            </>
                        )
                    }
                    {
                        schoolAdmin && (
                            <>
                                {/* <MenuItem component={<Link to="/" />} icon={<Face6OutlinedIcon />}>Dashboard</MenuItem> */}
                                {/* <MenuItem component={<Link to="/school-admins" />} icon={<Face6OutlinedIcon />}>Admin</MenuItem> */}
                                <MenuItem component={<Link to="/school-hod" />} icon={<Face6OutlinedIcon />}>HOD</MenuItem>
                                <MenuItem component={<Link to="/school-teacher" />} icon={<Face6OutlinedIcon />}>Teacher</MenuItem>
                                <MenuItem component={<Link to="/school-students" />} icon={<Face6OutlinedIcon />}>Student</MenuItem>
                            </>
                        )
                    }

                    {
                        schoolHOD && (
                            <>
                                <MenuItem component={<Link to="/school-teacher" />} icon={<Face6OutlinedIcon />}>Teacher</MenuItem>
                                <MenuItem component={<Link to="/school-students" />} icon={<Face6OutlinedIcon />}>Student</MenuItem>
                            </>
                        )
                    }

                    {
                        schoolTeacher && (
                            <>
                                <MenuItem component={<Link to="/school-students" />} icon={<Face6OutlinedIcon />}>Student</MenuItem>
                            </>
                        )
                    }

                    {/* <SubMenu label="B2B" icon={<Groups2OutlinedIcon />}>
                        <MenuItem component={<Link to="/franchises" />} icon={<ApartmentOutlinedIcon />}>Franchise</MenuItem>
                    </SubMenu> */}
                    {/* <MenuItem component={<Link to="/B2B" />} icon={<BusinessCenterOutlinedIcon />}> B2B</MenuItem> */}
                    {/* <MenuItem component={<Link to="/dashboard" />}> </MenuItem> */}
                </Menu>
            </Sidebar>
        </div>
    )
}

export default SidebarNav
