import React, { useEffect, useRef, useState } from 'react';
import '../../course/newCourse/NewCourse.css'
import './EditRobotixProduct.css'
import { fileUploadRequest, publicRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart, addProductSuccess, addProductFailure, updateProductStart, updateProductSuccess, updateProductFailure } from '../../../redux/productRedux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAllAgeGroups, getAllCourseTypes, getAllLectureLibraries, getAllQuiz, getAllRobotixProducts, getProducts } from '../../../redux/apiCalls';
import { getLecturesFailure, getLecturesStart, getLecturesSuccess } from '../../../redux/lectureRedux';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { useLocation } from 'react-router-dom/dist';
import Popup from '../../../global/Popup';
import { MEDIA_URL, isUrl } from '../../../config';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

// import 'firebase/storage';



const EditRobotixProduct = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const productId = location.pathname.split('/')[2];


    const isFetching = useSelector((state) => state.products.isFetching);

    const products = useSelector((state) => state.products.products.filter((item) => item.isRobotixProduct)
    );

    const productData = products && products.find(item => item._id === productId);

    const ageGroups = useSelector((state) => state.ageGroup.ageGroups);

    const [title, setTitle] = useState(productData && productData.title);
    const [description, setDescription] = useState(productData && productData.description);
    const [shortDescription, setShortDescription] = useState(productData && productData.shortDescription);
    const [metaTitle, setMetaTitle] = useState(productData && productData.metaTitle);
    const [metaDescription, setMetaDescription] = useState(productData && productData.metaDescription);
    const [metaKeywords, setMetaKeywords] = useState(productData && productData.metaKeywords);
    // const [ageGroup, setAgeGroup] = useState([]);
    const [ageGroup, setAgeGroup] = useState(productData && productData.ageGroup);
    const [courseType, setCourseType] = useState(productData && productData.courseType);
    const [price, setPrice] = useState(productData && productData.price);
    const [status, setStatus] = useState(productData && productData.status);
    const [priceVisibility, setPriceVisibility] = useState(productData && productData.priceVisibility);
    const [priceInDollar, setPriceInDollar] = useState(productData && productData.priceInDollar);
    const [freeInIndia, setFreeInIndia] = useState(productData && productData.freeInIndia);
    const [freeOutOfIndia, setFreeOutOfIndia] = useState(productData && productData.freeOutOfIndia);
    const [isForSubscription, setIsForSubscription] = useState(productData && productData.isForSubscription);
    const [whatIsInTheBox, setWhatIsInTheBox] = useState(productData && productData.whatIsInTheBox);
    const [whatWillChildLearn, setWhatWillChildLearn] = useState(productData && productData.whatWillChildLearn);
    const [productImage, setProductImage] = useState(null);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [currentProductImg, setCurrentProductImg] = useState(productData && productData.productImg);
    const [overview, setOverview] = useState(productData && productData.overview);
    const [overviewImages, setOverviewImages] = useState(productData && productData.overviewImages);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [imagePreview, setImagePreview] = useState(productData && productData.productImg);
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [isBestSeller, setIsBestSeller] = useState(productData && productData.bestSeller);
    const [videoThumbnail, setVideoThumbnail] = useState(productData && productData.videoThumbnail);
    const [availability, setAvailability] = useState(productData && productData.availability);


    useEffect(() => {
        setTitle(productData && productData.title);
        setDescription(productData && productData.description);
        setMetaDescription(productData && productData.metaDescription);
        setMetaTitle(productData && productData.metaTitle);
        setMetaKeywords(productData && productData.metaKeywords);
        setAgeGroup(productData && productData.ageGroup);
        setCourseType(productData && productData.courseType);
        setPrice(productData && productData.price);
        setPriceInDollar(productData && productData.priceInDollar);
        setStatus(productData && productData.status);
        setCurrentProductImg(productData && productData.productImg);
        setOverview(productData && productData.overview);
        setOverviewImages(productData && productData.overviewImages)
        setImagePreview(productData && productData.productImg);
        setIsBestSeller(productData && productData.bestSeller);
        setShortDescription(productData && productData.shortDescription);
        setFreeInIndia(productData && productData.freeInIndia);
        setFreeOutOfIndia(productData && productData.freeOutOfIndia);
        setWhatIsInTheBox(productData && productData.whatIsInTheBox);
        setWhatWillChildLearn(productData && productData.whatWillChildLearn);
        setPriceVisibility(productData && productData.priceVisibility);
        setIsForSubscription(productData && productData.isForSubscription);
        setVideoThumbnail(productData && productData.videoThumbnail);
        setAvailability(productData && productData.availability);
    }, [productData])



    useEffect(() => {
        getAllLectureLibraries(dispatch);
    }, [dispatch]);

    // fetch all existing course type
    useEffect(() => {
        getAllCourseTypes(dispatch);
    }, [dispatch]);

    // fetch all existing age groups
    useEffect(() => {
        getAllAgeGroups(dispatch);
    }, [dispatch]);

    // fetch all quiz
    useEffect(() => {
        getAllQuiz(dispatch);
    }, [dispatch]);

    const state = useSelector((state) => state.products.products);

    const allowedImageFormats = ['image/jpeg', 'image/png', 'image/gif'];

    const handleWhatIsInTheBox = (value) => {
        setWhatIsInTheBox(value);
    };

    const handleWhatWillChildLearn = (value) => {
        setWhatWillChildLearn(value);
    };

    // handle price according to free in india property
    const handleFreeInIndia = (value) => {
        if (value === "true") {
            setFreeInIndia(true);
            setPrice("0");
        } else {
            setFreeInIndia(false)
        }
    }

    // handle price according to free out of india property
    const handleFreeOutOfIndia = (value) => {
        if (value === "true") {
            setFreeOutOfIndia(true);
            setPriceInDollar("0")
        } else {
            setFreeOutOfIndia(false)
        }
    }

    const updatedProduct = async (dispatch, product) => {
        dispatch(updateProductStart());
        // setActiveLoader(true);
        try {
            const res = await userRequest.put(`/product/${productId}`, product);
            if (res.status === 200) {
                setActiveLoader(false);
                setActivePopup(true);
                setPopuptext("Product has been updated");
                setPopupStatus(true);
            }
            dispatch(updateProductSuccess(res.data));
        } catch (error) {
            dispatch(updateProductFailure());
            setActiveLoader(false);
            if (error.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const priceErr = validateRequired(price);
        const ageGroupErr = validateRequired(ageGroup);
        const overviewErr = validateRequired(overview);
        // const topicsErr = validateRequired(topics);
        // const productImageErr = validateRequired(productImage);
        const shortDescriptionErr = validateRequired(shortDescription);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }

        if (shortDescriptionErr) {
            formIsValid = false;
            newErrors.shortDescription = shortDescriptionErr;
        }

        if (priceErr) {
            formIsValid = false;
            newErrors.price = priceErr;
        }

        if (ageGroupErr) {
            formIsValid = false;
            newErrors.ageGroup = ageGroupErr;
        }

        if (overviewErr) {
            formIsValid = false;
            newErrors.overview = overviewErr;
        }

        // if (productImageErr) {
        //     formIsValid = false;
        //     newErrors.productImage = productImageErr;
        // }

        setErrors(newErrors);
        return formIsValid;
    }


    const handleDescriptionChange = (value) => {
        setOverview(value);
    };

    const overviewImageRef = useRef(null);
    const productImageRef = useRef(null);

    const handleAddImages = (fieldName) => {
        if (fieldName == "overviewImage") {
            overviewImageRef.current.click(); // Trigger file input click event
        }
        if (fieldName == "productImage") {
            productImageRef.current.click(); // Trigger file input click event
        }
    };

    const handleImageSelection = (files, fieldName) => {
        const selectedFiles = files; // Get the selected files
        const newImages = Array.from(selectedFiles).map(file => file);
        if (fieldName == "overviewImage") {
            setOverviewImages(prevImages => [...prevImages, ...newImages]);
        }

        if (fieldName == "productImage") {
            setCurrentProductImg(prevImages => [...prevImages, ...newImages]);
        }
    };

    const handleDeleteOverviewImage = (index, fieldName) => {
        if (fieldName == "overviewImage") {
            const newOverviewImages = [...overviewImages];
            newOverviewImages.splice(index, 1); // Remove the image at the specified index
            setOverviewImages(newOverviewImages); // Update the state with the new array of images
        }

        if (fieldName == "productImage") {
            const newProductImages = [...currentProductImg];
            newProductImages.splice(index, 1); // Remove the image at the specified index
            setCurrentProductImg(newProductImages); // Update the state with the new array of images
        }

    };

    // Function to allow the user to select an image from their device
    const selectImageFromDevice = () => {
        return new Promise((resolve) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.onchange = (e) => {
                const file = e.target.files[0];
                resolve(file);
            };
            input.click();
        });
    };

    //================*************** using blob funcationality***********============

    // const handleUpdateOverviewImage = async (index) => {
    //     const newOverviewImages = [...overviewImages];
    //     const file = await selectImageFromDevice(); // Function to allow the user to select an image
    //     if (file) {
    //         newOverviewImages[index] = URL.createObjectURL(file); // Replace the existing image with the new one at the specified index
    //         setOverviewImages(newOverviewImages); // Update the state with the new array of images
    //     }
    // };


    const handleUpdateOverviewImage = async (index, fieldName) => {
        if (fieldName == "overviewImage") {
            const newOverviewImages = [...overviewImages];
            const file = await selectImageFromDevice(); // Function to allow the user to select an image
            if (file) {
                newOverviewImages[index] = file; // Replace the existing image with the new one at the specified index
                setOverviewImages(newOverviewImages); // Update the state with the new array of images
            }
        }

        if (fieldName == "productImage") {
            const newProductImage = [...currentProductImg];
            const file = await selectImageFromDevice(); // Function to allow the user to select an image
            if (file) {
                newProductImage[index] = file; // Replace the existing image with the new one at the specified index
                setCurrentProductImg(newProductImage); // Update the state with the new array of images
            }
        }
    };


    const handleAgeGroupChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setAgeGroup(selectedOptions);
    };




    const uploadProductImageToSpace = async (files) => {
        const formData = new FormData();

        formData.append('file', files);

        if (files) {
            // const response = await fetch(files);
            // const blob = await response.blob();
            // // Extract file name from the Blob URL
            // // const fileName = files.split('/').pop(); // Assuming the file name is at the end of the URL

            // const file = new File([blob], "testing-file.png"); // Create a new File object with the original file name

            // formData.append('file', files);

            // Check the number of entries in the FormData object
            // const formDataEntries = formData.entries();
            // let entryCount = 0;
            // for (const entry of formDataEntries) {
            //     entryCount++;
            // }

            // // Iterate over the FormData entries to see the keys and values
            // for (let [key, value] of formData.entries()) {
            // // }
            try {
                const response = await fileUploadRequest.post('/upload/file', formData);
                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrl;
                    // setProductImage(fileUrl); // Set the product image URL in state
                    return fileUrl
                }
            } catch (error) {
                console.error('Error uploading product image:', error);
            }
        }

        // if (files?.length > 0) {
        //     files?.map((file) => {
        //         formData.append('files', file);
        //     });

        //     try {
        //         const response = await fileUploadRequest.post('/upload/multiple-files', formData);
        //         if (response && response.status === 200) {
        //             const fileUrl = response.data.fileUrls;
        //             // setProductImage(fileUrl); // Set the product image URL in state
        //             return fileUrl
        //         }
        //     } catch (error) {
        //         console.error('Error uploading product image:', error);
        //     }
        // } else {
        // formData.append('file', files);
        // try {
        //     const response = await fileUploadRequest.post('/upload/file', formData);
        //     if (response && response.status === 200) {
        //         const fileUrl = response.data.fileUrl;
        //         // setProductImage(fileUrl); // Set the product image URL in state
        //         return fileUrl
        //     }
        // } catch (error) {
        //     console.error('Error uploading product image:', error);
        // }
        // }
    };


    const handleProductImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setProductImage('');
                return;
            } else {
                setProductImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setProductImage(null);
            setImagePreview(productData && productData.productImg);
        }
    };


    // Function to update product in database
    const handleUpdateProduct = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        const trimmedTitle = title.trim();
        try {
            if (validateForm()) {

                try {
                    setActiveLoader(true);
                    // Checking product title is unique or not
                    const res = await userRequest.post('/product/search', { title: trimmedTitle, id: productId });

                    if (res && res.status == 200) {
                        let productImageURL;
                        let overviewImagesUrl;
                        // if (productImage?.name) {
                        //     productImageURL = await uploadProductImageToSpace(productImage);
                        // }
                        // else {
                        //     productImageURL = currentProductImg;
                        // }

                        // if(overviewImages?.length > 0){

                        //     overviewImages.map( async (item, index) => {
                        //         overviewImagesUrl = await uploadProductImageToSpace(productImage);
                        //     })
                        // }
                        if (overviewImages?.length > 0) {
                            // Map through each item in the overviewImages array
                            await Promise.all(overviewImages.map(async (item, index) => {
                                // Check if the item is a blob URL (indicating a newly added or updated image)
                                // if (item.startsWith('blob:')) {
                                //     // Upload the image to the server
                                //     const imageUrl = await uploadProductImageToSpace(item);
                                //     // Replace the blob URL in the overviewImages array with the uploaded image URL
                                //     overviewImages[index] = imageUrl;
                                // }
                                if (item?.name) {
                                    // Upload the image to the server
                                    const imageUrl = await uploadProductImageToSpace(item);
                                    // Replace the blob URL in the overviewImages array with the uploaded image URL
                                    overviewImages[index] = imageUrl;
                                }
                            }));
                        }

                        if (currentProductImg?.length > 0) {
                            // Map through each item in the overviewImages array
                            await Promise.all(currentProductImg.map(async (item, index) => {
                                // Check if the item is a blob URL (indicating a newly added or updated image)
                                // if (item.startsWith('blob:')) {
                                //     // Upload the image to the server
                                //     const imageUrl = await uploadProductImageToSpace(item);
                                //     // Replace the blob URL in the overviewImages array with the uploaded image URL
                                //     overviewImages[index] = imageUrl;
                                // }
                                if (item?.name) {
                                    // Upload the image to the server
                                    const imageUrl = await uploadProductImageToSpace(item);
                                    // Replace the blob URL in the overviewImages array with the uploaded image URL
                                    currentProductImg[index] = imageUrl;
                                }
                            }));
                        }


                        updatedProduct(dispatch, {
                            title: trimmedTitle,
                            description,
                            ageGroup,
                            courseType,
                            status,
                            price,
                            priceInDollar,
                            freeInIndia,
                            freeOutOfIndia,
                            productImg: currentProductImg,
                            priceVisibility,
                            overview,
                            overviewImages,
                            bestSeller: isBestSeller,
                            shortDescription,
                            whatIsInTheBox,
                            whatWillChildLearn,
                            isForSubscription,
                            videoThumbnail,
                            availability,
                            metaTitle,
                            metaDescription,
                            metaKeywords
                        });
                    }
                } catch (error) {
                    if (error.response && error.response.status === 409) {
                        setActiveLoader(false);
                        setLoaderText('');
                        setTitleError(true);
                        setTitleErrorMessage(error.response.data);
                    }

                    else {
                        // setError(true);
                        // setErrorMessage("Something went wrong!");
                        setActiveLoader(false);
                        setLoaderText('');
                        setActivePopup(true);
                        // setPopuptext(error.response.data);
                        setPopupStatus(false);
                    };
                };
            }
        } catch (error) {
            setActiveLoader(false);
            setLoaderText('');
            console.error('Error uploading files in:', error);
            setError(true);
        };
    };



    // Chapter wise code ended here==============

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update Product</h2>
                    </div>
                </div>
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newCourseInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>short description</label>
                        <input type="text" name='description' value={shortDescription} className='newCourseInput' onChange={(e) => setShortDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.shortDescription}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        <input type="text" name='description' value={description} className='newCourseInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='meta keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta keywords</label>
                        <input type="text" name='meta description' value={metaKeywords} className='newCourseInput' onChange={(e) => setMetaKeywords(e.target.value)} placeholder='meta keywords' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Age Group</label>
                        <select name="ageGroup" value={ageGroup} onChange={handleAgeGroupChange} multiple>
                            <option value="" selected disabled>Select</option>
                            {
                                ageGroups.map((item, index) => {
                                    return <option value={item.ageGroup} key={index} >{item.ageGroup}</option>
                                })
                            }
                        </select>
                        <span className='error-text'>{errors.ageGroup}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Price Visibility</label>
                        <select name="priceVisibility" value={priceVisibility} onChange={(e) => setPriceVisibility(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Visible</option>
                            <option value="false" >Hidden</option>
                        </select>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Add to best seller</label>
                        <select name="status" value={isBestSeller} onChange={(e) => setIsBestSeller(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free In India</label>
                        <select name="status" value={freeInIndia} onChange={(e) => handleFreeInIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free Out Of India</label>
                        <select name="status" value={freeOutOfIndia} onChange={(e) => handleFreeOutOfIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Course is available</label>
                        <select name="availability" value={availability} onChange={(e) => setAvailability(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"all"} >For All </option>
                            <option value={"onlyForIndia"} >Only For India</option>
                            <option value={"onlyForOutOfIndia"} >Only For Out Of India</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Subscription</label>
                        <select name="status" value={isForSubscription} onChange={(e) => setIsForSubscription(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={true} >Yes</option>
                            <option value={false} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Rupees)" : "Price (In Rupees)"}</label>
                        <input name='price' value={price} className='newCourseInput' onChange={(e) => setPrice(e.target.value)} placeholder='Price' disabled={freeInIndia === true ? true : false} />
                    </div>
                    <span className='error-text'>{errors.price}</span>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Dollar)" : "Price (In Dollar)"}</label>
                        <input type="text" name='price' value={priceInDollar} className='newCourseInput' onChange={(e) => setPriceInDollar(e.target.value)} disabled={freeOutOfIndia === true ? true : false} placeholder='Price' />
                        <span className='error-text'>{errors.priceInDollar}</span>
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Price</label>
                        <input type="text" name='price' value={price} className='newCourseInput' onChange={(e) => setPrice(e.target.value)} placeholder='Price' />
                        <span className='error-text'>{errors.price}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Price Visibility</label>
                        <select name="priceVisibility" value={priceVisibility} onChange={(e) => setPriceVisibility(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Visible</option>
                            <option value="false" >Hidden</option>
                        </select>
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Add to best seller</label>
                        <select name="status" value={isBestSeller} onChange={(e) => setIsBestSeller(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Update Image</label>
                        <input type="file" name='file' className='newCourseInput' onChange={handleProductImageChange} placeholder='Price' />
                        <span className='error-text'>{errors.productImage}</span>
                    </div>
                    <div className="form-input-wrapper">
                        <label>Preview Image</label>
                        {/* <div className="course-img-wrapper"> */}
                        {/* <img src={ MEDIA_URL + imagePreview || productImage} alt="course-img" /> */}
                        {/* {
                                isUrl(imagePreview) ? (
                                    <img src={imagePreview || productImage} alt="product-img" />
                                ) : (productImage?.name) ? (
                                    <img src={imagePreview || productImage} alt="product-img" />
                                ) : (
                                    <img src={MEDIA_URL + imagePreview} alt="product-img" />
                                )
                            } */}
                        <div className="overview-img-wrapper">
                            {
                                currentProductImg?.map((item, index) => {
                                    return (
                                        <div className="course-img-wrapper position-relative">
                                            {item?.name ? ( // Check if item is a local image
                                                <img src={URL.createObjectURL(item)} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}${item}`} alt="overview-img" />
                                            )}
                                            {/* {item?.startsWith('blob:') ? ( // Check if item is a local image
                                                <img src={item} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}/${item}`} alt="overview-img" />
                                            )} */}
                                            {
                                                <div className="update-wrapper-main">
                                                    <div className="update-tool-wrapper">
                                                        <button className='update-btn' onClick={() => handleUpdateOverviewImage(index, "productImage")}>Update</button>
                                                        <button className='delete-btn' onClick={() => handleDeleteOverviewImage(index, "productImage")}>Delete</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                <div className="course-img-wrapper">
                                    <button className='add-more-button' onClick={() => handleAddImages("productImage")}>
                                        <img src={`${process.env.PUBLIC_URL}/images/add-img.png`} className='add-more-icon' />
                                        <strong>{`${overviewImages?.length > 0 ? "Add more" : "Add Images"}`}</strong>
                                    </button>
                                    <input type="file" name='file' style={{ display: "none" }} ref={productImageRef} onChange={(e) => handleImageSelection(e.target.files, "productImage")} accept='image/*' multiple />
                                </div>
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Video Thumbnail</label>
                        <input type="text" name='video-url' value={videoThumbnail} className='newCourseInput' onChange={(e) => setVideoThumbnail(e.target.value)} placeholder='Video URL' />
                        {/* <span className='error-text'>{errors.productImage}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>overview</label>
                        <ReactQuill value={overview} onChange={handleDescriptionChange} />
                        <span className='error-text'>{errors.overview}</span>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>overview images</label>
                        <div className="overview-img-wrapper">
                            {
                                overviewImages?.map((item, index) => {
                                    return (
                                        <div className="course-img-wrapper position-relative">
                                            {item?.name ? ( // Check if item is a local image
                                                <img src={URL.createObjectURL(item)} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}${item}`} alt="overview-img" />
                                            )}
                                            {/* {item?.startsWith('blob:') ? ( // Check if item is a local image
                                                <img src={item} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}/${item}`} alt="overview-img" />
                                            )} */}
                                            {
                                                <div className="update-wrapper-main">
                                                    <div className="update-tool-wrapper">
                                                        <button className='update-btn' onClick={() => handleUpdateOverviewImage(index, "overviewImage")}>Update</button>
                                                        <button className='delete-btn' onClick={() => handleDeleteOverviewImage(index, "overviewImage")}>Delete</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                <div className="course-img-wrapper">
                                    <button className='add-more-button' onClick={() => handleAddImages("overviewImage")}>
                                        <img src={`${process.env.PUBLIC_URL}/images/add-img.png`} className='add-more-icon' />
                                        <strong>{`${overviewImages?.length > 0 ? "Add more" : "Add Images"}`}</strong>
                                    </button>
                                    <input type="file" name='file' style={{ display: "none" }} ref={overviewImageRef} onChange={(e) => handleImageSelection(e.target.files, "overviewImage")} accept='image/*' multiple />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>What is in the box </label>
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['image', 'code-block'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ]
                        }} theme="snow" value={whatIsInTheBox} onChange={handleWhatIsInTheBox} />
                        {/* <span className='error-text'>{errors.overview}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>What will your child learn</label>
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['image', 'code-block'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ]
                        }} theme="snow" value={whatWillChildLearn} onChange={handleWhatWillChildLearn} />
                    </div>
                </div>

                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        <button className="custom-btn purple-bg" onClick={handleUpdateProduct}>
                            Update
                        </button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>

    )
}

export default EditRobotixProduct
