import React, { useEffect, useState } from 'react';
import './NewCourse.css'
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart, addProductSuccess, addProductFailure } from '../../../redux/productRedux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAllAgeGroups, getAllCourseTypes, getAllLectureLibraries, getAllQuiz } from '../../../redux/apiCalls';
import { getLecturesFailure, getLecturesStart, getLecturesSuccess } from '../../../redux/lectureRedux';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import Popup from '../../../global/Popup';
import { allowedImageFormats } from '../../../config';
// import CancelIcon from '@mui/icons-material/Cancel';

// import 'firebase/storage';



const NewCourse = () => {

    const [title, setTitle] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [description, setDescription] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [ageGroup, setAgeGroup] = useState('');
    const [courseType, setCourseType] = useState('');
    const [snapAccess, setSnapAccess] = useState(false);
    const [scratchAccess, setScratchAccess] = useState(false);
    const [status, setStatus] = useState(false);
    const [priceInDollar, setPriceInDollar] = useState(null);
    const [price, setPrice] = useState(null);
    const [freeInIndia, setFreeInIndia] = useState(false);
    const [freeOutOfIndia, setFreeOutOfIndia] = useState(false);
    const [isForSubscription, setIsForSubscription] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [productImage, setProductImage] = useState([null]);
    const [videoThumbnail, setVideoThumbnail] = useState('');
    const [overview, setOverview] = useState(null);
    const [overviewImages, setOverviewImages] = useState([null]);
    const [topics, setTopics] = useState([{ topicName: '', topicDetails: '' }]);
    const [skills, setSkills] = useState([{ skillName: '', skillDetails: '' }]);
    const [libraries, setLibraries] = useState([]);
    const [selectedLibrary, setSelectedLibrary] = useState('');
    const [videos, setVideos] = useState([]);
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [documentFile, setDocumentFile] = useState([{ fileTitle: '', fileLocation: '' }]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [numberOfSessions, setNumberOfSessions] = useState(null);
    const [popupStatus, setPopupStatus] = useState(true);
    const [fileInputs, setFileInputs] = useState([{ title: '', file: null }]);
    const [isBestSeller, setIsBestSeller] = useState(false);
    const [availability, setAvailability] = useState("all");

    // New chapter codes start here============
    const [showResourcePopup, setShowResourcePopup] = useState(false);

    // const handleResourcePopup = (status) => {
    //     setShowResourcePopup(status);
    // }


    // New chapter codes end here============




    const dispatch = useDispatch();

    useEffect(() => {
        getAllLectureLibraries(dispatch);
    }, [dispatch]);

    // fetch all existing course type
    useEffect(() => {
        getAllCourseTypes(dispatch);
    }, [dispatch]);

    // fetch all existing age groups
    useEffect(() => {
        getAllAgeGroups(dispatch);
    }, [dispatch]);

    // fetch all quiz
    useEffect(() => {
        getAllQuiz(dispatch);
    }, [dispatch]);

    const state = useSelector((state) => state)

    const lectureLibraries = useSelector((state) => state.lectureLibrary.lectureLibrary);
    const allQuiz = useSelector((state) => state.quiz.quiz);

    useEffect(() => {
        if (lectureLibraries) {
            setLibraries(lectureLibraries);
        }
    }, [lectureLibraries]);

    // handle library select 
    const handleLibrarySelect = async (event) => {
        const libraryId = event.target.value;
        setSelectedLibrary(libraryId);

        dispatch(getLecturesStart());
        setActiveLoader(true)
        setLoaderText("fetching")
        try {
            const res = await userRequest.get(`/product/all-lectures/${libraryId}`);
            setVideos(res.data)
            dispatch(getLecturesSuccess(res.data));
            if (res.data) {
                setActiveLoader(false)
            }
        } catch (error) {
            setActiveLoader(false)
            dispatch(getLecturesFailure());
        }
    };

    // handle price according to free in india property
    const handleFreeInIndia = (value) => {
        if (value === "true") {
            setFreeInIndia(true);
            setPrice("0");
        } else {
            setFreeInIndia(false)
        }
    }

    // handle price according to free out of india property
    const handleFreeOutOfIndia = (value) => {
        if (value === "true") {
            setFreeOutOfIndia(true);
            setPriceInDollar("0")
        } else {
            setFreeOutOfIndia(false)
        }
    }

    // Handle topic input
    const handleTopicChange = (index, event) => {
        const { name, value } = event.target;
        const updatedTopics = [...topics];
        updatedTopics[index][name] = value;
        setTopics(updatedTopics);
    };

    const addTopicField = () => {
        setTopics([...topics, { topicName: '', topicDetails: '' }]);
    };

    const removeTopicField = (index) => {
        if (topics.length > 1) {
            const updatedTopics = [...topics];
            updatedTopics.splice(index, 1);
            setTopics(updatedTopics);
        }
    };


    const removeDocumentFileField = (index) => {
        if (fileInputs.length > 1) {
            const updatedfileInputs = [...fileInputs];
            updatedfileInputs.splice(index, 1);
            setFileInputs(updatedfileInputs);
        }
    };

    // Handle skill input field
    const handleSkillChange = (index, event) => {
        const { name, value } = event.target;
        const updatedSkills = [...skills];
        updatedSkills[index][name] = value;
        setSkills(updatedSkills);
    };

    const addSkillField = () => {
        setSkills([...skills, { skillName: '', skillDetails: '' }]);
    };

    const removeSkillField = (index) => {
        if (skills.length > 1) {
            const updatedSkills = [...skills];
            updatedSkills.splice(index, 1);
            setSkills(updatedSkills);
        };
    };

    // const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    const allowedDocumentFormats = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/zip', 'application/octet-stream', 'application/vnd.h5p', 'application/octet-stream'];
    const allowedDocumentExtensions = ['.pdf', '.ppt', '.zip', '.h5p', '.pptx', '.sb3'];


    const handleFileChange = (event, fieldName) => {
        const files = event.target.files;
        const updatedSelectedFiles = { ...selectedFiles };
        if (files.length > 0) { // Check if any file is selected
            if (fieldName === 'forImage') {
                // Check if the selected file is an image
                const isImage = allowedImageFormats.includes(files[0].type);
                if (!isImage) {
                    // Show an error message or perform some action for invalid image format
                    alert('Please select a valid image file (JPEG, PNG, or GIF).');
                    event.target.value = null;
                    setProductImage('');
                    return;
                }
                else {
                    setProductImage(files[0].name);
                }
            } else if (fieldName === 'forDocument') {
                const selectedFile = files[0];
                const fileExtension = selectedFile.name.slice(((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2); // Extract file extension

                // Check if the selected file is a document
                const isDocument = allowedDocumentFormats.includes(selectedFile.type) || allowedDocumentExtensions.includes(`.${fileExtension}`);
                if (!isDocument) {
                    // Show an error message or perform some action for invalid document format
                    alert('Please select a valid document file (PDF, PPT, or H5P).');
                    event.target.value = null;
                    setDocumentFile('');
                    return;
                }
                else {
                    setDocumentFile({ fileName: event.target.value, fileLocation: selectedFile });
                }
            }
            updatedSelectedFiles[fieldName] = [...files];
            setSelectedFiles(updatedSelectedFiles);
        };
    };


    const handleProductImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                event.target.value = null;
                setProductImage('');
                return;
            } else {
                setProductImage(files[0]);
            }
        }
    }



    const courseTypes = useSelector((state) => state.courseType.courseTypes);
    const ageGroups = useSelector((state) => state.ageGroup.ageGroups);

    const addProducts = async (dispatch, product) => {
        dispatch(addProductStart());
        setActiveLoader(true);
        try {
            const res = await userRequest.post('/product/create', product);

            if (res?.status === 200) {
                // setSuccess(true);
                // setSuccessMessage("Course has been added!");
                setActiveLoader(false);
                setActivePopup(true)
                setPopuptext("Course has been added.");
                setPopupStatus(true);
            }
            dispatch(addProductSuccess(res.data));
        } catch (error) {
            dispatch(addProductFailure());
            setActiveLoader(false);
            if (error?.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                // setError(true);
                // setErrorMessage("Something went wrong!");
                setActivePopup(true)
                setPopuptext(error.response.data);
                setPopupStatus(false);
            }
        }
    };


    const handleDocumentTitleChange = (index, value) => {
        const updatedInputs = [...fileInputs];
        updatedInputs[index].title = value;
        setFileInputs(updatedInputs);
    };

    const handleDocumentFileChange = (index, file) => {
        // Check if the selected file's format is allowed
        if (allowedDocumentFormats.includes(file.type) || allowedDocumentExtensions.some(ext => file.name.endsWith(ext))) {
            const updatedInputs = [...fileInputs];
            updatedInputs[index].file = file;
            setFileInputs(updatedInputs);
        } else {
            // Show an error message or perform some action for invalid file format
            alert('Please select a valid document file (PDF, PPT, or H5P).');
        }
    };

    const handleDocumentAddFileInput = () => {
        setFileInputs([...fileInputs, { title: '', file: null }]);
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        const priceErr = validateRequired(price);
        const priceInDollarErr = validateRequired(priceInDollar);
        const ageGroupErr = validateRequired(ageGroup);
        const courseTypeErr = validateRequired(courseType);
        const overviewErr = validateRequired(overview);
        const topicsErr = validateRequired(topics);
        const productImageErr = validateRequired(productImage);
        const documentFileErr = validateRequired(documentFile);
        const numberOfSessionsErr = validateRequired(numberOfSessions);
        const shortDescriptionErr = validateRequired(shortDescription);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }

        if (shortDescriptionErr) {
            formIsValid = false;
            newErrors.shortDescription = shortDescriptionErr;
        }

        if (priceErr) {
            formIsValid = false;
            newErrors.price = priceErr;
        }

        if (priceInDollarErr) {
            formIsValid = false;
            newErrors.priceInDollar = priceInDollarErr;
        }

        if (ageGroupErr) {
            formIsValid = false;
            newErrors.ageGroup = ageGroupErr;
        }
        if (courseTypeErr) {
            formIsValid = false;
            newErrors.courseType = courseTypeErr;
        }
        if (overviewErr) {
            formIsValid = false;
            newErrors.overview = overviewErr;
        }
        if (topicsErr) {
            formIsValid = false;
            newErrors.topics = topicsErr;
        }
        if (productImageErr) {
            formIsValid = false;
            newErrors.productImage = productImageErr;
        }

        if (numberOfSessionsErr) {
            formIsValid = false;
            newErrors.numberOfSessions = numberOfSessionsErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleDescriptionChange = (value) => {
        setOverview(value);
    };

    const uploadDocumentFileToSpace = async (file, title) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                const fileUrl = response.data.fileUrl;
                return { title, fileUrl };
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const uploadDocumentFiles = async () => {
        const uploadedFiles = await Promise.all(
            fileInputs.map((input) => uploadDocumentFileToSpace(input.file, input.title))
        );
        // Now you have an array of objects containing titles and file URLs
        // You can handle storing these URLs in your database or wherever needed.
        return uploadedFiles;
    };


    // const uploadProductImageToSpace = async (file) => {
    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await fileUploadRequest.post('/upload/file', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         if (response && response.status === 200) {
    //             const fileUrl = response.data.fileUrl;
    //             // setProductImage(fileUrl); // Set the product image URL in state
    //             return fileUrl
    //         }
    //     } catch (error) {
    //         console.error('Error uploading product image:', error);
    //     }
    // };
    const uploadProductImageToSpace = async (files) => {
        const formData = new FormData();

        if (files?.length > 0) {
            files.map((file) => {
                formData.append('files', file);
            });

            try {
                const response = await fileUploadRequest.post('/upload/multiple-files', formData);
                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrls;
                    // setProductImage(fileUrl); // Set the product image URL in state
                    return fileUrl
                }
            } catch (error) {
                console.error('Error uploading product image:', error);
            }
        } else {
            formData.append('file', files);
            try {
                const response = await fileUploadRequest.post('/upload/file', formData);
                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrl;
                    // setProductImage(fileUrl); // Set the product image URL in state
                    return fileUrl
                }
            } catch (error) {
                console.error('Error uploading product image:', error);
            }
        }
    };


    const chekTitle = async () => {
        try {
            const res = await userRequest.post('/product/search', title);
        } catch (error) {
        }
    }

    const [selectedVideos, setSelectedVideos] = useState(Array.from({ length: videos.length }, () => ''));
    const [videoTitles, setVideoTitles] = useState(Array.from({ length: videos.length }, () => ''));
    const [selectedQuizzes, setSelectedQuizzes] = useState(Array.from({ length: videos.length }, () => []));


    // Function to update video title
    // const handleVideoTitleChange = (event, index) => {
    //     const updatedTitles = [...videoTitles];
    //     updatedTitles[index] = event.target.value;
    //     setVideoTitles(updatedTitles);
    // };

    // Function to update selected quizzes
    // const handleQuizSelect = (event, index) => {
    //     const selectedQuizIds = Array.from(event.target.selectedOptions, option => option.value);
    //     setSelectedQuizzes(prevQuizzes => {
    //         const updatedQuizzes = [...prevQuizzes];
    //         updatedQuizzes[index] = selectedQuizIds;
    //         return updatedQuizzes;
    //     });
    // };

    const handleVideoClick = (videoId, videoIndex) => {
        setSelectedVideos(prevSelectedVideos => {
            const updatedSelectedVideos = [...prevSelectedVideos];
            updatedSelectedVideos[videoIndex] =
                updatedSelectedVideos[videoIndex] === videoId ? null : videoId;
            return updatedSelectedVideos;
        });
    };

    const combineData = (titles, videoIds, quizIds) => {
        const combinedData = [];

        for (let i = 0; i < titles.length; i++) {
            const title = titles[i];
            const videoId = videoIds[i];
            const quizzes = quizIds[i] || []; // Handle cases where quizIds[i] might be undefined
            if (videoId) { // Only add if videoId is not null
                combinedData.push({ title, videoId, quizzes });
            }
        }
        return combinedData;
    };

    // Chapter wise code started here==============
    const [chapters, setChapters] = useState([
        {
            title: '',
            resources: [],
        },
    ]);

    // const handleAddChapter = () => {
    //     setChapters([
    //         ...chapters,
    //         {
    //             title: '',
    //         },
    //     ]);
    // };

    const handleAddChapter = () => {
        // Check if all existing chapters are valid
        const allChaptersValid = chapters.every(isChapterValid);

        if (!allChaptersValid) {
            alert('Please fill in all required fields for the existing chapters before adding a new one.');
            return;
        }

        setChapters((prevChapters) => [
            ...prevChapters,
            {
                title: '',
                resources: [],
            },
        ]);
    };

    const isChapterValid = (chapter) => {
        return (
            chapter && chapter.title && chapter.title.trim() !== '' &&
            chapter.resources.every(resource => resource.title && resource.title.trim() !== '')
        );
    };
    // const isChapterValid = (chapter) => {
    //     return (
    //         chapter && chapter.title && chapter.title.trim() !== '' &&
    //         chapter.resources.every(resource => resource.title && resource.title.trim() !== '' && resource.file ? resource.file : resource.url )
    //     );
    // };

    const handleRemoveChapter = (chapterIndex) => {
        const newChapters = [...chapters];
        newChapters.splice(chapterIndex, 1);
        setChapters(newChapters);
    };

    const handleAddResource = (chapterIndex, resourceType, title) => {
        const newChapters = [...chapters];
        if (resourceType === 'quiz') {
            newChapters[chapterIndex].resources.push({
                type: resourceType,
                title: title,
                questions: [{
                    title: '',
                    options: [''], // Add an empty option by default
                    correctAnswer: '',
                    marks: 0,
                }],
                minMarks: 0,
                totalMarks: 0
            });
        } else {
            newChapters[chapterIndex].resources.push({
                type: resourceType,
                title: title,
            });
        }

        setChapters(newChapters);
        resourcePopups[chapterIndex] = false;
    };

    // Event handler to update resource title
    const handleResourceTitleChange = (chapterIndex, resourceIndex, value) => {
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].resources[resourceIndex].title = value;
        setChapters(updatedChapters);
    };

    // Event handler to update resource file
    const handleResourceFileChange = (chapterIndex, resourceIndex, file) => {
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].resources[resourceIndex].file = file;
        setChapters(updatedChapters);
    };

    const handleVideoURLChange = (chapterIndex, resourceIndex, value) => {
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].resources[resourceIndex].url = value;
        setChapters(updatedChapters);
    }

    const handleRemoveResource = (chapterIndex, resourceIndex) => {
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].resources.splice(resourceIndex, 1);
        setChapters(updatedChapters);
    };


    const [resourcePopups, setResourcePopups] = useState(new Array(chapters.length).fill(false));

    const handleResourcePopup = (chapterIndex, show) => {
        const updatedResourcePopups = [...resourcePopups];
        updatedResourcePopups[chapterIndex] = show;
        setResourcePopups(updatedResourcePopups);
    };


    const handleQuestionTitleChange = (chapterIndex, resourceIndex, questionIndex, newQuestionTitle) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].title = newQuestionTitle;
        setChapters(newChapters);
    };

    const handleAddOption = (chapterIndex, resourceIndex, questionIndex) => {
        const newChapters = [...chapters];
        const options = newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options || [];
        options.push(""); // Add an empty option by default
        newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options = options;
        setChapters(newChapters);
    };

    const handleOptionChange = (chapterIndex, resourceIndex, questionIndex, optionIndex, newOption) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options[optionIndex] = newOption;
        setChapters(newChapters);
    };

    const handleAddQuestion = (chapterIndex, resourceIndex) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].questions.push({
            title: '',
            options: [''], // Add an empty option by default
            correctAnswer: '',
            marks: 0,
        });
        setChapters(newChapters);   
    };

    // const handleAddQuestion = (chapterIndex, resourceIndex) => {
    //     const newChapters = [...chapters];
    //     const newQuestions = [...newChapters[chapterIndex].resources[resourceIndex].questions, {
    //         title: '',
    //         options: [''],
    //         correctAnswer: '',
    //         marks: 0,
    //     }];

    //     newChapters[chapterIndex].resources[resourceIndex].questions = newQuestions;
    //     newChapters[chapterIndex].resources[resourceIndex].totalMarks = calculateTotalMarks(newQuestions);

    //     setChapters(newChapters);
    // };

    const handleRemoveQuestion = (chapterIndex, resourceIndex, questionIndex) => {
        const newChapters = [...chapters];
        const questions = newChapters[chapterIndex].resources[resourceIndex].questions || [];

        // Ensure that at least one question remains before removing
        if (questions.length > 1) {
            questions.splice(questionIndex, 1);
            newChapters[chapterIndex].resources[resourceIndex].questions = questions;
            setChapters(newChapters);
        }
    };


    const handleCorrectAnswerChange = (chapterIndex, resourceIndex, questionIndex, correctAnswer) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].correctAnswer = correctAnswer;
        setChapters(newChapters);
    };

    const handleRemoveOption = (chapterIndex, resourceIndex, questionIndex, optionIndex) => {
        const newChapters = [...chapters];
        const options = newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options || [];

        // Ensure that at least one option remains before removing
        if (options.length > 1) {
            options.splice(optionIndex, 1);
            newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options = options;
            setChapters(newChapters);
        }
    };

    // const calculateTotalMarks = (resource) => {
    //     const questions = resource.questions || [];
    //     const totalMarks = questions.reduce((total, question) => total + Number(question.marks), 0);
    //     return totalMarks;
    // };

    const calculateTotalMarks = (questions) => {
        if (!Array.isArray(questions)) {
            return 0; // or any default value if questions is not an array
        }
        return questions.reduce((total, question) => total + Number(question.marks), 0);


        // return questions.reduce((total, question) => total + (question.marks || 0), 0);
    };





    // const handleMinPassingMarksChange = (chapterIndex, resourceIndex, value) => {
    //     const newChapters = [...chapters];
    //     newChapters[chapterIndex].resources[resourceIndex].minMarks = value;
    //     setChapters(newChapters);
    // };

    const [minPassingMarksErr, setMinPassingMarksErr] = useState(false);
    const [minPassingMarksErrMessage, setMinPassingMarksErrMessage] = useState("Minimum passing marks should be greater than 0 and less than total marks");

    const handleMinPassingMarksChange = (chapterIndex, resourceIndex, minPassingMarks) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].minMarks = minPassingMarks;
        setChapters(newChapters);
    };
    // const handleMinPassingMarksChange = (chapterIndex, resourceIndex, minPassingMarks) => {
    //     const newChapters = [...chapters];
    //     const totalMarks = calculateTotalMarks(newChapters[chapterIndex].resources[resourceIndex]);
    //     setMinPassingMarksErr(false)
    //     // Add a validation check
    //     if (minPassingMarks > 0 && minPassingMarks <= totalMarks) {
    //         newChapters[chapterIndex].resources[resourceIndex].minMarks = minPassingMarks;
    //         setChapters(newChapters);
    //         setMinPassingMarksErr(false)
    //     } else {
    //         // You can provide feedback to the user that the min passing marks are invalid
    //         // alert('Invalid minimum passing marks');
    //         newChapters[chapterIndex].resources[resourceIndex].minMarks = minPassingMarks;
    //         setMinPassingMarksErr(true)
    //     }
    // };


    const handleQuestionMarksChange = (chapterIndex, resourceIndex, questionIndex, value) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].marks = value;
        setChapters(newChapters);
    };

    const handleTotalMarksChange = (chapterIndex, resourceIndex, totalMarks) => {
        const newChapters = [...chapters];
        newChapters[chapterIndex].resources[resourceIndex].totalMarks = totalMarks;
        setChapters(newChapters);
    };

    const handleRemoveQuiz = (chapterIndex, quizIndex) => {
        // Your logic to remove the quiz with the specified index from the specified chapter
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex].resources.splice(quizIndex, 1);
        // Set the updated chapters state
        setChapters(updatedChapters);
    };









    // Similar functions can be created for handling changes to correct answer, explanation, marks, etc.

    // Functions to handle quiz module end here=========





    const handleAddVideo = (chapterIndex) => {
        const newChapter = [...chapters];
        newChapter[chapterIndex].videos.push({
            title: '',
            videoId: '',
            quizzes: null,
            searchText: '', // Add this property for each video
        });
        setChapters(newChapter);
    };

    // const handleRemoveVideo = (chapterIndex, videoIndex) => {
    //     const newChapter = [...chapters];
    //     newChapter[chapterIndex].videos.splice(videoIndex, 1);
    //     setChapters(newChapter);
    // };

    // const handleRemoveVideo = (chapterIndex, videoIndex) => {
    //     const newChapter = [...chapters];
    //     if (newChapter[chapterIndex] && newChapter[chapterIndex].videos) {
    //         newChapter[chapterIndex].videos.splice(videoIndex, 1);
    //         setChapters(newChapter);
    //     }
    // };
    const handleRemoveVideo = (chapterIndex, videoIndex) => {
        setChapters(prevChapters => {
            const newChapters = [...prevChapters];
            if (newChapters[chapterIndex] && newChapters[chapterIndex].videos) {
                newChapters[chapterIndex].videos.splice(videoIndex, 1);
            }
            return newChapters;
        });
    };




    const handleChapterTitleChange = (chapterIndex, value) => {
        const newChapter = [...chapters];
        newChapter[chapterIndex].title = value;
        setChapters(newChapter);
    };

    const handleVideoTitleChange = (chapterIndex, videoIndex, value) => {
        const newChapter = [...chapters];
        newChapter[chapterIndex].videos[videoIndex].title = value;
        setChapters(newChapter);
    };

    const handleVideoIdChange = (chapterIndex, videoIndex, value) => {
        const newChapter = [...chapters];
        newChapter[chapterIndex].videos[videoIndex].videoId = value;
        setChapters(newChapter);
    };

    // const handleQuizSelect = (chapterIndex, videoIndex, selectedQuizzes) => {
    //     const newChapter = [...chapters];
    //     newChapter[chapterIndex].videos[videoIndex].quizzes = selectedQuizzes;
    //     setChapters(newChapter);
    // };

    // const handleQuizSelect = (chapterIndex, videoIndex, options) => {
    //     const selectedOptionIds = Array.from(options)
    //         .filter(option => option.selected && option.value !== "-1")
    //         .map(option => option.value);


    //     setChapters((prevChapters) => {
    //         const updatedChapters = [...prevChapters];
    //         updatedChapters[chapterIndex].videos[videoIndex].quizzes = selectedOptionIds;
    //         return updatedChapters;
    //     });
    // };

    // const handleQuizSelect = (chapterIndex, videoIndex, selectedOption) => {
    //     const selectedOptionId = selectedOption.value;


    //     setChapters((prevChapters) => {
    //         const updatedChapters = [...prevChapters];
    //         updatedChapters[chapterIndex].videos[videoIndex].quizzes = selectedOptionId;
    //         return updatedChapters;
    //     });
    // };

    const handlePdfFileSelect = (chapterIndex, videoIndex, selectedFiles) => {
        // Process the selected PDF files (selectedFiles)
        // You can store them in the state or upload them as needed

        setChapters((prevChapters) => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex].videos[videoIndex].pdfFiles = selectedFiles;
            return updatedChapters;
        });
    };

    const handleSb3FileSelect = (chapterIndex, videoIndex, selectedFiles) => {
        // Process the selected SB3 files (selectedFiles)
        // You can store them in the state or upload them as needed

        setChapters((prevChapters) => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex].videos[videoIndex].sb3Files = selectedFiles;
            return updatedChapters;
        });
    };

    const handleh5pFileSelect = (chapterIndex, videoIndex, selectedFiles) => {
        // Process the selected SB3 files (selectedFiles)
        // You can store them in the state or upload them as needed

        setChapters((prevChapters) => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex].videos[videoIndex].h5pFiles = selectedFiles;
            return updatedChapters;
        });
    };



    const handleQuizSelect = (chapterIndex, videoIndex, selectedOptionId) => {
        // const selectedOptionId = e.target.value;


        setChapters((prevChapters) => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex].videos[videoIndex].quizzes = selectedOptionId;
            return updatedChapters;
        });
    };



    // const [searchTextArray, setSearchTextArray] = useState(chapters?.videos ? Array(chapters.videos.length).fill('') : []);


    // const handleSearchTextChange = (chapterIndex, videoIndex, value) => {
    //     setSearchTextArray(prevSearchTextArray => {
    //         const updatedSearchTextArray = [...prevSearchTextArray];
    //         updatedSearchTextArray[videoIndex] = value;
    //         return updatedSearchTextArray;
    //     });
    // }

    const handleVideoSearchTextChange = (chapterIndex, videoIndex, value) => {
        setChapters(prevChapters => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex].videos[videoIndex].searchText = value;
            return updatedChapters;
        });
    };
    // Chapter wise code ended here==============


    const uploadDocumentFilesToSpace = async (files) => {
        const uploadedFileDetails = [];

        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await fileUploadRequest.post('/upload/file', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrl;
                    uploadedFileDetails.push(fileUrl);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }


        return uploadedFileDetails;
    };

    // const handleAddOverviewImages = (e, index) => {
    //     const newImages = [...overviewImages];
    //     newImages[index] = e.target.files[0];
    //     setOverviewImages(newImages);
    // };

    const handleAddOverviewImages = (e) => {
        const files = e.target.files;
        const fileList = [];
        for (let i = 0; i < files.length; i++) {
            fileList.push(files[i]);
        }
        setOverviewImages(fileList);
    };

    const handleAddProductImages = (e) => {
        const files = e.target.files;
        const fileList = [];
        for (let i = 0; i < files.length; i++) {
            fileList.push(files[i]);
        }
        setProductImage(fileList);
    };

    const handleAddImageField = () => {
        setOverviewImages([...overviewImages, null]); // Add a new empty item to the state
    };

    const handleAgeGroupChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setAgeGroup(selectedOptions);
    };

    // const handleAddImageField = () => {
    //     const input = document.createElement('input');
    //     input.type = 'file';
    //     input.name = 'file';
    //     input.className = 'newCourseInput';
    //     input.onChange = handleAddOverviewImages;

    //     // Assuming you have a container div to hold the input fields
    //     const container = document.getElementById('imageContainer');
    //     container.appendChild(input);
    // };

    // Function to upload overview images to space
    const uploadOverviewImagesToSpace = async (images) => {
        const uploadedImageDetails = [];

        for (const image of images) {
            const formData = new FormData();
            formData.append('file', image);

            try {
                const response = await fileUploadRequest.post('/upload/file', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response && response.status === 200) {
                    const imageUrl = response.data.fileUrl;
                    uploadedImageDetails.push(imageUrl);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }

        return uploadedImageDetails;
    };


    const handleAddProduct = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        const trimmedTitle = title.trim();
        setActiveLoader(true);
        setLoaderText('uploading');

        try {
            // const lectureData = combineData(videoTitles, selectedVideos, selectedQuizzes);
            if (validateForm()) {
                try {
                    const res = await userRequest.post('/product/search', trimmedTitle);
                    if (res && res.status == 200) {
                        // const documentFiles = await uploadDocumentFiles();
                        // const productImageURL = await uploadProductImageToSpace(productImage);
                        // // Upload overview images
                        // // const overviewImageURLs = await uploadOverviewImagesToSpace(overviewImages);

                        // let overviewImagesURL;
                        // if (overviewImages.length > 0) {
                        //     overviewImagesURL = await uploadProductImageToSpace(overviewImages);
                        // }

                        let productImageURL;
                        let overviewImagesURL;
                        if (overviewImages.length > 0) {
                            overviewImagesURL = await uploadProductImageToSpace(overviewImages);
                        }
                        if (productImage.length > 0) {
                            productImageURL = await uploadProductImageToSpace(productImage);
                        }

                        // Upload resources for each chapter
                        const updatedChapters = await Promise.all(
                            chapters.map(async (chapter) => {
                                const { title, resources } = chapter;

                                // Upload files for each resource in the chapter
                                const updatedResources = await Promise.all(
                                    resources.map(async (resource) => {
                                        const { title, type, file, url, questions, minMarks, totalMarks } = resource;
                                        // Upload the file to the space
                                        const fileURL = await uploadDocumentFilesToSpace([file]);


                                        return {
                                            title,
                                            type,
                                            // file: fileURL[0], // Assuming uploadDocumentFilesToSpace returns an array with a single URL
                                            url: fileURL[0],
                                            questions,
                                            minMarks,
                                            totalMarks
                                        };
                                    })
                                );

                                return {
                                    title,
                                    resources: updatedResources,
                                };
                            })
                        );


                        addProducts(dispatch, {
                            title: trimmedTitle,
                            description,
                            ageGroup,
                            courseType,
                            snapAccess,
                            scratchAccess,
                            status,
                            price,
                            priceInDollar,
                            productImg: productImageURL,
                            videoThumbnail,
                            topics,
                            overview,
                            skills,
                            numberOfSessions,
                            bestSeller: isBestSeller,
                            shortDescription,
                            chapters: updatedChapters,
                            overviewImages: overviewImagesURL,
                            isForSubscription,
                            freeInIndia,
                            freeOutOfIndia,
                            availability,
                            metaTitle,
                            metaDescription
                        });
                    }
                } catch (error) {
                    console.error(error)
                    if (error.response && error.response.status === 409) {
                        setTitleError(true);
                        setTitleErrorMessage(error.response.data);
                    }

                    else {
                        setError(true);
                        setErrorMessage("Something went wrong!");
                    }
                }
            }
            setActiveLoader(false);
        } catch (error) {
            setActiveLoader(false);
            console.error('Error uploading files:', error);
            setError(true);
        }
    };

    const hidePopup = () => {
        setActivePopup(false);
    }

    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    }






    return (
        <div className='container'>
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Add new course</h2>
                    </div>
                </div>
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newCourseInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>short description</label>
                        <input type="text" name='description' value={shortDescription} className='newCourseInput' onChange={(e) => setShortDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.shortDescription}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        <input type="text" name='description' value={description} className='newCourseInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Age Group</label>
                        <select name="ageGroup" value={ageGroup} onChange={handleAgeGroupChange} multiple>
                            <option value="" selected disabled>Select</option>
                            {
                                ageGroups.map((item, index) => {
                                    return <option value={item.ageGroup} key={index} >{item.ageGroup}</option>
                                })
                            }
                        </select>
                        <span className='error-text'>{errors.ageGroup}</span>

                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Course Type</label>
                        <select name="courseType" value={courseType} onChange={(e) => setCourseType(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                courseTypes.map((item, index) => {
                                    return <option value={item.courseType} key={index} >{item.courseType}</option>
                                })
                            }
                        </select>
                        <span className='error-text'>{errors.courseType}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Snap Access</label>
                        <select name="snapAccess" value={snapAccess} onChange={(e) => setSnapAccess(e.target.value)}>
                            <option value="" disabled >Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Scratch Access</label>
                        <select name="scratchAccess" value={scratchAccess} onChange={(e) => setScratchAccess(e.target.value)}>
                            <option value="" disabled >Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free In India</label>
                        <select name="status" value={freeInIndia} onChange={(e) => handleFreeInIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free Out Of India</label>
                        <select name="status" value={freeOutOfIndia} onChange={(e) => handleFreeOutOfIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Course is available</label>
                        <select name="availability" value={availability} onChange={(e) => setAvailability(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"all"} >For All </option>
                            <option value={"onlyForIndia"} >Only For India</option>
                            <option value={"onlyForOutOfIndia"} >Only For Out Of India</option>
                        </select>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Only For Out Of India</label>
                        <select name="onlyForOutOfIndia" value={onlyForOutOfIndia} onChange={(e) => setOnlyForOutOfIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Subscription</label>
                        <select name="status" value={isForSubscription} onChange={(e) => setIsForSubscription(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={true} >Yes</option>
                            <option value={false} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Rupee)" : "Price (In Rupee)"}</label>
                        <input type="text" name='price' value={price} className='newCourseInput' onChange={(e) => setPrice(e.target.value)} placeholder='Price' disabled={freeInIndia === true ? true : false} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Dollar)" : "Price (In Dollar)"}</label>
                        <input type="text" name='price' value={priceInDollar} className='newCourseInput' onChange={(e) => setPriceInDollar(e.target.value)} disabled={freeOutOfIndia === true ? true : false} placeholder='Price' />
                        <span className='error-text'>{errors.priceInDollar}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Add to best seller</label>
                        <select name="status" value={isBestSeller} onChange={(e) => setIsBestSeller(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image Thumbnail</label>
                        <input type="file" name='file' className='newCourseInput' onChange={handleProductImageChange} placeholder='image' />
                        <span className='error-text'>{errors.productImage}</span>
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Image</label>
                        {/* <input type="file" name='file' className='newCourseInput' onChange={handleProductImageChange} placeholder='Price' /> */}
                        <input
                            type="file"
                            name={`files`}
                            className="newCourseInput"
                            onChange={handleAddProductImages}
                            multiple
                            accept='image/*'
                        />
                        <span className='error-text'>{errors.productImage}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Video Thumbnail</label>
                        <input type="text" name='video-url' className='newCourseInput' onChange={(e) => setVideoThumbnail(e.target.value)} placeholder='Video URL' />
                        {/* <span className='error-text'>{errors.productImage}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>overview</label>
                        <ReactQuill value={overview} onChange={handleDescriptionChange} />
                        <span className='error-text'>{errors.overview}</span>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Overview Images</label>
                            <button className="custom-btn" onClick={handleAddImageField}>
                                Add Image
                            </button>
                        </div>
                        {overviewImages.map((image, index) => (
                            <input
                                key={index}
                                type="file"
                                name={`file-${index}`}
                                className="newCourseInput"
                                onChange={(e) => handleAddOverviewImages(e, index)}
                            />
                        ))}
                    </div>
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Overview Images</label>
                        <input
                            type="file"
                            name={`files`}
                            className="newCourseInput"
                            onChange={handleAddOverviewImages}
                            multiple
                            accept='image/*'
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Topic</label>
                            <button type="button" className='custom-btn' onClick={addTopicField}>
                                Add Topic
                            </button>
                        </div>

                        {topics.map((topic, index) => {
                            return (<>
                                <div key={index} className='row'>
                                    <div className="col-12 pb-3">
                                        <input className='newCourseInput'
                                            type="text"
                                            name="topicName"
                                            value={topic.topicName}
                                            onChange={(event) => handleTopicChange(index, event)}
                                            placeholder="Enter topic name"
                                        />
                                    </div>
                                    <div className="col-11">
                                        <textarea className='newCourseInput'
                                            name="topicDetails"
                                            value={topic.topicDetails}
                                            onChange={(event) => handleTopicChange(index, event)}
                                            placeholder="Enter topic details"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${topics.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeTopicField(index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Skill</label>
                            <button type="button" className='custom-btn' onClick={addSkillField}>
                                Add Skill
                            </button>
                        </div>

                        {skills.map((skill, index) => {
                            return (<>
                                <div key={index} className='row'>
                                    <div className="col-12 pb-3">
                                        <input className='newCourseInput'
                                            type="text"
                                            name="skillName"
                                            value={skill.skillName}
                                            onChange={(event) => handleSkillChange(index, event)}
                                            placeholder="Enter skill name"
                                        />
                                    </div>
                                    <div className="col-11">
                                        <textarea className='newCourseInput'
                                            name="skillDetails"
                                            value={skill.skillDetails}
                                            onChange={(event) => handleSkillChange(index, event)}
                                            placeholder="Enter skill details"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${skills.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeSkillField(index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>

                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Chapter</label>
                            <button type="button" className='custom-btn' >
                                Add Chapter
                            </button>
                        </div>
                    </div>
                </div> */}

                <div className='col-12'>
                    <div className="form-input-wrapper">
                        <label className='my-1'>Add Chapters</label>
                        {
                            chapters.map((chapter, chapterIndex) => (
                                <div key={chapterIndex}>
                                    <div className="topics-header my-3">
                                        <label className='my-1'>{`Chapter ${chapterIndex + 1}`}</label>
                                        <button type="button" className='custom-btn' onClick={() => handleRemoveChapter(chapterIndex)} >
                                            Remove Chapter
                                        </button>
                                    </div>
                                    <input
                                        className='newCourseInput mb-3'
                                        type="text"
                                        placeholder="CHAPTER TITLE"
                                        value={chapter.title}
                                        onChange={(e) => handleChapterTitleChange(chapterIndex, e.target.value)}
                                    />
                                    {
                                        chapter.resources.map((resource, key) => {
                                            if (resource.type === 'pdf' || resource.type === 'ppt' || resource.type === 'h5p' || resource.type === 'video' || resource.type === 'sb3' || resource.type === 'zip' || resource.type === 'html') {
                                                const acceptedTypes =
                                                    resource.type === 'pdf'
                                                        ? '.pdf'
                                                        : resource.type === 'ppt'
                                                            ? '.ppt, .pptx'
                                                            : resource.type === 'zip'
                                                                ? '.zip'
                                                                : resource.type === 'html' ? '.html' : '.zip';

                                                return (
                                                    <div key={key} className='row'>
                                                        <div className="col-12">
                                                            <label className='mb-2'>{resource.type === 'video' ? 'Enter video details' : `Select any ${resource.type} file`}</label>
                                                            <input
                                                                className='newCourseInput mb-3'
                                                                type="text"
                                                                placeholder={`${resource.type.toUpperCase()} Title`}
                                                                value={resource.title || ''}
                                                                onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-11 pb-3">
                                                            <input
                                                                className='newCourseInput mb-3'
                                                                type={resource.type === 'video' ? "text" : "file"}
                                                                placeholder={resource.type === 'video' ? "VIDEO URL" : ''}
                                                                onChange={(e) => resource.type === 'video' ? handleVideoURLChange(chapterIndex, key, e.target.value) : handleResourceFileChange(chapterIndex, key, e.target.files[0])}
                                                                accept={acceptedTypes}
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <div className="remove-btn-wrapper">
                                                                <CancelIcon className="activeRemoveIcon"
                                                                    onClick={() => handleRemoveResource(chapterIndex, key)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (resource.type === 'quiz') {
                                                return (
                                                    <div className="quiz-container">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-input-wrapper mb-0">
                                                                    <div className="topics-header my-3">
                                                                        <label>Quiz Title</label>
                                                                        <button type="button" className='custom-btn' onClick={() => handleRemoveQuiz(chapterIndex, key)} >
                                                                            Remove Quiz
                                                                        </button>
                                                                    </div>

                                                                    <input
                                                                        type="text"
                                                                        className='newUserInput'
                                                                        placeholder='Quiz Title'
                                                                        value={resource.title || ''}
                                                                        onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* Question details */}
                                                            {resource.questions.map((question, questionIndex) => (
                                                                <div key={questionIndex} className="col-12">
                                                                    <div className="form-input-wrapper">
                                                                        <div className="topics-header my-3">
                                                                            <label>{`Question ${questionIndex + 1}`}</label>
                                                                            {
                                                                                resource.questions.length > 1 && (
                                                                                    <button type="button" className='custom-btn' onClick={() => handleRemoveQuestion(chapterIndex, key, questionIndex)} >
                                                                                        Remove Question
                                                                                    </button>
                                                                                )
                                                                            }

                                                                        </div>

                                                                        {/* <input
                                                                            type="text"
                                                                            className="newUserInput"
                                                                            placeholder="Question Title"
                                                                            value={question.title || ''}
                                                                            onChange={(e) => handleQuestionTitleChange(chapterIndex, key, questionIndex, e.target.value)}
                                                                        /> */}
                                                                        <ReactQuill value={question.title || ''} onChange={(value) => handleQuestionTitleChange(chapterIndex, key, questionIndex, value)} modules={{
                                                                            toolbar: [
                                                                                ['image', 'code-block'],
                                                                            ]
                                                                        }} theme="snow" />
                                                                        {/* <span className='error-text'>{errors.title}</span> */}
                                                                    </div>
                                                                    <div>
                                                                        {question.options.map((option, optionIndex) => (
                                                                            <div key={optionIndex} className="form-input-wrapper">
                                                                                <label>{`Option ${optionIndex + 1}`}</label>
                                                                                <div className="row">
                                                                                    <div className="col-11">
                                                                                        {/* <input
                                                                                            type="text"
                                                                                            className="newUserInput w-100"
                                                                                            placeholder={`Option ${optionIndex + 1}`}
                                                                                            value={option || ''}
                                                                                            onChange={(e) => handleOptionChange(chapterIndex, key, questionIndex, optionIndex, e.target.value)}
                                                                                        /> */}
                                                                                        <ReactQuill value={option || ''} onChange={(value) => handleOptionChange(chapterIndex, key, questionIndex, optionIndex, value)} modules={{
                                                                                            toolbar: [
                                                                                                ['image', 'code-block'],
                                                                                            ]
                                                                                        }} theme="snow" />
                                                                                    </div>
                                                                                    <div className="col-1">
                                                                                        <div className="remove-btn-wrapper">
                                                                                            <CancelIcon className={`${question.options.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                                                                onClick={() => handleRemoveOption(chapterIndex, key, questionIndex, optionIndex)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        ))}
                                                                        <button className="custom-btn mb-2" onClick={() => handleAddOption(chapterIndex, key, questionIndex)}>Add Option</button>
                                                                    </div>
                                                                    <div className="form-input-wrapper">
                                                                        <label>Correct Answer</label>
                                                                        <select className='newUserInput' value={question.correctAnswer} onChange={(e) => handleCorrectAnswerChange(chapterIndex, key, questionIndex, e.target.value)}>
                                                                        <option value="" selected disabled>Select correct option</option>
                                                                            {question.options.map((option, optionIndex) => (
                                                                                <>
                                                                                 <option key={optionIndex} value={optionIndex}>
                                                                                    {/* {option} */}
                                                                                    {`Option ${optionIndex + 1}`}
                                                                                </option>
                                                                                </>
                                                                            //      <option key={optionIndex} value={optionIndex}>
                                                                            //      {/* {option} */}
                                                                            //      {`Option ${optionIndex + 1}`}
                                                                            //  </option>
                                                                               
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="form-input-wrapper">
                                                                            <label>Marks for Question</label>
                                                                            <input
                                                                                type="text"
                                                                                className="newUserInput"
                                                                                value={question.marks || ''}
                                                                                onChange={(e) => handleQuestionMarksChange(chapterIndex, key, questionIndex, e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="col-12">
                                                                <button className="custom-btn w-100" onClick={() => handleAddQuestion(chapterIndex, key)}>Add Question</button>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-input-wrapper">
                                                                    <label>Minimum Passing Marks</label>
                                                                    <input
                                                                        type="text"
                                                                        className="newUserInput"
                                                                        value={resource.minMarks || ''}
                                                                        onChange={(e) => handleMinPassingMarksChange(chapterIndex, key, e.target.value)}
                                                                    />
                                                                    {
                                                                        minPassingMarksErr && (
                                                                            <p className="error-text">{minPassingMarksErrMessage}</p>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-input-wrapper">
                                                                    <label>Total Marks</label>
                                                                    <input
                                                                        type="text"
                                                                        className="newUserInput"
                                                                        value={resource.totalMarks || ''}
                                                                        onChange={(e) => handleTotalMarksChange(chapterIndex, key, e.target.value)}

                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12">
                                                            <div className="form-input-wrapper">
                                                                <label>Total Marks (Automatically Calculated)</label>
                                                                <input
                                                                    type="text"
                                                                    className="newUserInput"
                                                                    value={calculateTotalMarks(resource.questions)}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>

                                                );
                                            }

                                            return null; // If resource type is not 'ppt', return null or an empty fragment
                                        })
                                    }
                                    <button className='custom-btn' onClick={() => handleResourcePopup(chapterIndex, true)}>Add Resources</button>
                                    {
                                        resourcePopups[chapterIndex] && (
                                            <div className="resource-container">
                                                <div className="resource-main">
                                                    <div className="resource-header">
                                                        <h4>Add a resource</h4>
                                                        <span>
                                                            <CancelIcon className='close-icon' onClick={() => handleResourcePopup(chapterIndex, false)} />
                                                        </span>
                                                    </div>
                                                    <div className="resource-items-wrapper">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'pdf')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/pdf.png`} alt="" />
                                                                        </div>
                                                                        <p>PDF File</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'ppt')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/ppt.png`} alt="" />
                                                                        </div>
                                                                        <p>PPT File</p>
                                                                    </button>
                                                                </div>
                                                                {/* <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'h5p')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/H5P.png`} alt="" />
                                                                        </div>
                                                                        <p>H5P File</p>
                                                                    </button>
                                                                </div> */}
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'sb3')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/program.png`} alt="" />
                                                                        </div>
                                                                        <p>Sb3 File</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'video')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/video.png`} alt="" />
                                                                        </div>
                                                                        <p>Video</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'quiz')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/quiz.png`} alt="" />
                                                                        </div>
                                                                        <p>Quiz</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'zip')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/zip.png`} alt="" />
                                                                        </div>
                                                                        <p>Zip File</p>
                                                                    </button>
                                                                </div>

                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'html')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/html-5.png`} alt="" />
                                                                        </div>
                                                                        <p>HTML File</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        }
                        <button className='custom-btn' onClick={handleAddChapter}>Add Chapter</button>
                    </div>
                </div>

                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Select Videos</label>
                        <ul className='select-video-ul'>
                            {videos.map((video, index) => {
                                const id = video.uri.split('/')[2];
                                const isSelected = selectedVideos.includes(id);
                                return (
                                    <li key={index}
                                        onClick={() => handleVideoClick(id, index)} className='mb-3'>
                                        <p className={isSelected ? 'select-video selected-video' : 'select-video'}>{video.name}</p>
                                        {isSelected && (
                                            <>
                                                <input
                                                    className='newCourseInput mb-3'
                                                    type="text"
                                                    value={videoTitles[index]}
                                                    placeholder="Video Title"
                                                    onChange={(e) => handleVideoTitleChange(e, index)}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                                <select
                                                    multiple
                                                    value={selectedQuizzes[index] || []}
                                                    onChange={(e) => handleQuizSelect(e, index)} onClick={(e) => e.stopPropagation()}>
                                                    {
                                                        allQuiz && allQuiz.map((quiz, index) => {
                                                            return (
                                                                <option key={index} value={quiz._id}>{quiz.title}</option>
                                                            )
                                                        })}
                                                </select>
                                            </>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div> */}

                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Number of sessions</label>
                        <input type="text" className='newCourseInput' onChange={(e) => setNumberOfSessions(e.target.value)} placeholder='No. of sessions' />
                        <span className='error-text'>{errors.numberOfSessions && errors.numberOfSessions}</span>
                    </div>
                </div>

                {/* <div className="col-12">
                    <label>Document File (PPT, PDF, H5P)</label>
                    <div className="form-input-wrapper">
                        <label>Choose file</label>
                        <input className='newCourseInput'
                            type="text"
                            name='file'
                            placeholder='Title for file'
                        />
                        <input className='newCourseInput'
                            type="file"
                            id="documentFile"
                            // accept=".ppt,.pptx,application/pdf,.h5p"
                            // onChange={(event) => handleFileChange(event, 'forDocument')}
                            name='file'
                        />
                        <span className='error-text'>{errors.documentFile}</span>
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Document File (PPT, PDF, H5P)</label>
                            <button type="button" className='custom-btn' onClick={handleDocumentAddFileInput}>
                                Add File
                            </button>
                        </div>

                        {fileInputs.map((input, index) => (
                            <div key={index}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <input
                                            className='newCourseInput'
                                            type="text"
                                            placeholder='Title for file'
                                            value={input.title}
                                            onChange={(e) => handleDocumentTitleChange(index, e.target.value)}
                                        />
                                    </div>
                                    <div className="col-11">
                                        <input
                                            className='newCourseInput'
                                            type="file"
                                            accept=".ppt,.pptx,application/pdf,.h5p,.sb3"
                                            name='file'
                                            onChange={(e) => handleDocumentFileChange(index, e.target.files[0])}
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${fileInputs.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeDocumentFileField(index)}
                                            />
                                        </div>
                                    </div>
                                    <span className='error-text'>{errors.documentFile}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}


                {/* <button onClick={handleUpload}>Upload Files</button> */}

                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        <button className="custom-btn purple-bg" onClick={handleAddProduct}>
                            Submit
                        </button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div>

    )
}

export default NewCourse








// const handleAddProduct = async () => {
//     setSuccess(false);
//     setError(false);
//     setTitleError(false);
//     const trimmedTitle = title.trim();
//     setActiveLoader(true);
//     setLoaderText('uploading');

//     try {
//         if (validateForm()) {
//             try {
//                 const res = await userRequest.post('/product/search', trimmedTitle);
//                 if (res && res.status == 200) {
//                     const productImageURL = await uploadProductImageToSpace(productImage);
//                     addProducts(dispatch, {
//                         title: trimmedTitle,
//                         description,
//                         ageGroup,
//                         courseType,
//                         snapAccess,
//                         scratchAccess,
//                         price,
//                         productImg: productImageURL,
//                         topics,
//                         overview,
//                         skills,
//                         numberOfSessions,
//                         bestSeller: isBestSeller,
//                         shortDescription
//                     });
//                 }
//             } catch (error) {
//                 console.error(error)
//                 if (error.response && error.response.status === 409) {
//                     setTitleError(true);
//                     setTitleErrorMessage(error.response.data);
//                 }

//                 else {
//                     setError(true);
//                     setErrorMessage("Something went wrong!");
//                 }
//             }
//         }
//         setActiveLoader(false);
//     } catch (error) {
//         setActiveLoader(false);
//         console.error('Error uploading files:', error);
//         setError(true);
//     }
// };