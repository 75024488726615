import React, { useEffect, useState } from 'react';
import './StudentTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getAllStudentsOfMeritus, getAllUsers } from '../../../../redux/apiCalls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Groups3Icon from '@mui/icons-material/Groups3';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CircleLoader from '../../../../components/circleLoader/CircleLoader';
import { encryptUniqueId } from '../../../../config';

const MeritusStudentsTable = () => {

    const dispatch = useDispatch();
    // const [students, setStudents] = useState([]);

    useEffect(() => {
        getAllStudentsOfMeritus(dispatch);
    }, [dispatch]);
    // const state = useSelector((state) => state.);
    // console.log("state from m student table ==>", state)

    const isFetching = useSelector((state) => state.meritusStudents.isFetching);
    const students = useSelector((state) => state.meritusStudents?.students);

     // Add index numbers to the students array
     const studentsWithIndex = students?.map((student, index) => ({
        ...student,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));

    // useEffect(() => {
    //     if (users) {
    //         setStudents(users.filter((item) => item.isMeritusStudent === true))
    //     }
    // }, [users])

    const handleDelete = (studentId) => {
        deleteUser(studentId, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true);
        setDeletingItem(id);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }

    const columns = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 100,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 100,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
            renderCell: (params) => {
            }
        },
        // {
        //     field: 'grade',
        //     headerName: 'Grade',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 120,
        //     renderCell: (params) => {
        //         return params.value ? params.value : "Not assigned";
        //     }
        // },
        // {
        //     field: 'level',
        //     headerName: 'Level',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 120,
        //     renderCell: (params) => {
        //         return params.value ? params.value : "Not assigned";
        //     }
        // },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        // {
        //     field: 'Attendance',
        //     headerName: 'Attendance',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Link to={`/view-attendance/user?user=${encryptUniqueId(params?.id)}`}>
        //                     <button className='btn btn-primary studentListEdit'>View</button>
        //                 </Link>
        //             </>
        //         )
        //     }
        // },
        // {
        //     field: 'Action',
        //     headerName: 'Action',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Link to={'/edit-student/' + params.id}>
        //                     <button className='btn btn-primary studentListEdit'>Edit</button>
        //                 </Link>
        //             </>
        //         )
        //     }
        // },
        // {
        //     field: 'delete',
        //     headerName: 'Delete',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         const studentId = params.row._id;
        //         return (
        //             <>
        //                 <DeleteOutline onClick={() => handlePopup(studentId)} className='text-danger' />
        //             </>
        //         )
        //     }
        // },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Students</h2>
                        {/* <div className="btn-wrapper d-flex" style={{columnGap:'20px'}}>
                            <Link to='/attendance-table'>
                                <button className='custom-btn purple-bg'>Mark Attendance<HowToRegIcon className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/new-meeting'>
                                <button className='custom-btn purple-bg'>Schedule a class <Groups3Icon className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/new-student'>
                                <button className='custom-btn purple-bg'>New Student <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div> */}

                    </div>
                </div>
            </div>

            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This student will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => handleDelete(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                students && (
                    <DataGrid
                        rows={studentsWithIndex}
                        columns={columns}
                        getRowId={(rows) => rows._id}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection = {false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default MeritusStudentsTable
