import React, { useEffect, useState, useRef, createRef } from 'react';
import '../newCourse/NewCourse.css'
import { fileUploadRequest, userRequest } from '../../../requestMethod';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductStart, updateProductSuccess, updateProductFailure } from '../../../redux/productRedux';
import { validateRequired } from '../../../formValidation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAllAgeGroups, getAllCourseTypes, getAllLectureLibraries, getAllQuiz, getProducts } from '../../../redux/apiCalls';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { useLocation } from 'react-router-dom/dist';
import Popup from '../../../global/Popup';
import EditIcon from '@mui/icons-material/Edit';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MEDIA_URL, allowedImageFormats, isUrl } from '../../../config';

const EditCourseNew = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);

    const location = useLocation();
    const courseId = location.pathname.split('/')[2];

    const { isFetching, products } = useSelector((state) => state.products);
    const productData = products && products.find(item => item._id === courseId);



    const courseTypes = useSelector((state) => state.courseType.courseTypes);
    const ageGroups = useSelector((state) => state.ageGroup.ageGroups);



    // const [course, setCourse] = useState({});
    const [title, setTitle] = useState(productData && productData.title);
    const [description, setDescription] = useState(productData && productData.description);
    const [shortDescription, setShortDescription] = useState(productData && productData.shortDescription);
    const [metaTitle, setMetaTitle] = useState(productData && productData.metaTitle);
    const [metaDescription, setMetaDescription] = useState(productData && productData.metaDescription);
    const [ageGroup, setAgeGroup] = useState(productData && productData.ageGroup);
    const [courseType, setCourseType] = useState(productData && productData.courseType);
    const [snapAccess, setSnapAccess] = useState(productData && productData.snapAccess);
    const [scratchAccess, setScratchAccess] = useState(productData && productData.scratchAccess);
    const [price, setPrice] = useState(productData && productData.price);
    const [priceInDollar, setPriceInDollar] = useState(productData && productData.priceInDollar);
    const [isForSubscription, setIsForSubscription] = useState(productData && productData.isForSubscription);
    const [status, setStatus] = useState(productData && productData.status);
    const [productImage, setProductImage] = useState(null);
    const [titleError, setTitleError] = useState(false);
    const [titleErrorMessage, setTitleErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [currentProductImg, setCurrentProductImg] = useState(productData && productData.productImg);
    const [currentDocumentFile, setCurrentDocumentFile] = useState(productData && productData.document ? [...productData.document] : []);
    const [overview, setOverview] = useState(productData && productData.overview);
    const [overviewImages, setOverviewImages] = useState(productData && productData.overviewImages);
    const [topics, setTopics] = useState(productData && productData.topics);
    const [skills, setSkills] = useState(productData && productData.skills);
    const [libraries, setLibraries] = useState([]);
    const [activeLoader, setActiveLoader] = useState(false);
    const [loaderText, setLoaderText] = useState("");
    const [imagePreview, setImagePreview] = useState(productData && productData.productImg);
    const [lectures, setLectures] = useState(productData && productData.lectures);
    const [numberOfSessions, setNumberOfSessions] = useState(productData && productData.numberOfSessions);
    const [activePopup, setActivePopup] = useState(false);
    const [popupText, setPopuptext] = useState('');
    const [popupStatus, setPopupStatus] = useState(true);
    const [fileInputs, setFileInputs] = useState([]);
    const [isBestSeller, setIsBestSeller] = useState(productData && productData.bestSeller);
    const [chapters, setChapters] = useState(productData && productData.chapters);
    const [backupChapters, setBackupChapters] = useState(productData && productData.chapters);
    const [editingFile, setEditingFile] = useState(false);
    const [editedFileTitle, setEditedFileTitle] = useState('');
    const [addingNewResource, setAddingNewResource] = useState(false);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [collapsedChapters, setCollapsedChapters] = useState([]);
    const [videoThumbnail, setVideoThumbnail] = useState(productData && productData.videoThumbnail);
    const [freeInIndia, setFreeInIndia] = useState(productData && productData.freeInIndia);
    const [freeOutOfIndia, setFreeOutOfIndia] = useState(productData && productData.freeOutOfIndia);
    const [availability, setAvailability] = useState(productData && productData.availability);

    useEffect(() => {
        setTitle(productData && productData.title);
        setDescription(productData && productData.description);
        setAgeGroup(productData && productData.ageGroup);
        setCourseType(productData && productData.courseType);
        setSnapAccess(productData && productData.snapAccess);
        setScratchAccess(productData && productData.scratchAccess);
        setPrice(productData && productData.price);
        setPriceInDollar(productData && productData.priceInDollar);
        setIsForSubscription(productData && productData.isForSubscription);
        setStatus(productData && productData.status);
        setCurrentProductImg(productData && productData.productImg);
        setCurrentDocumentFile(productData && productData.document);
        setOverview(productData && productData.overview);
        setSkills(productData && productData.skills);
        setTopics(productData && productData.topics);
        setImagePreview(productData && productData.productImg);
        setLectures(productData && productData.lectures);
        setNumberOfSessions(productData && productData.numberOfSessions);
        setIsBestSeller(productData && productData.bestSeller);
        setShortDescription(productData && productData.shortDescription);
        setChapters(productData && productData.chapters);
        setBackupChapters(productData && productData.chapters);
        setVideoThumbnail(productData && productData.videoThumbnail);
        setFreeInIndia(productData && productData.freeInIndia);
        setFreeOutOfIndia(productData && productData.freeOutOfIndia);
        setAvailability(productData && productData.availability);
        setOverviewImages(productData && productData.overviewImages)
    }, [productData])

    useEffect(() => {
        getAllLectureLibraries(dispatch);
    }, [dispatch]);

    // fetch all existing course type
    useEffect(() => {
        getAllCourseTypes(dispatch);
    }, [dispatch]);

    // fetch all existing age groups
    useEffect(() => {
        getAllAgeGroups(dispatch);
    }, [dispatch]);

    // fetch all quiz
    useEffect(() => {
        getAllQuiz(dispatch);
    }, [dispatch]);

    const state = useSelector((state) => state.products.products);

    const lectureLibraries = useSelector((state) => state.lectureLibrary.lectureLibrary);

    useEffect(() => {
        if (lectureLibraries) {
            setLibraries(lectureLibraries)
        }
    }, [lectureLibraries]);

    // handle price according to free in india property
    const handleFreeInIndia = (value) => {
        if (value === "true") {
            setFreeInIndia(true);
            setPrice("0")
        } else {
            setFreeInIndia(false)
        }
    }

    // handle price according to free out of india property
    const handleFreeOutOfIndia = (value) => {
        if (value === "true") {
            setFreeOutOfIndia(true);
            setPriceInDollar("0")
        } else {
            setFreeOutOfIndia(false)
        }
    }



    // Handle topic input
    const handleTopicChange = (index, event) => {
        const { name, value } = event.target;
        // Create a deep copy of the topics array
        const updatedTopics = [...topics];
        // Create a deep copy of the topic object at the specified index
        const updatedTopic = { ...updatedTopics[index] };
        // Update the property within the copied object
        updatedTopic[name] = value;
        // Update the copied object back into the copied array
        updatedTopics[index] = updatedTopic;
        // Set the state with the updated array
        setTopics(updatedTopics);
    };

    const addTopicField = () => {
        setTopics([...topics, { topicName: '', topicDetails: '' }]);
    };

    const removeTopicField = (index) => {
        if (topics.length > 1) {
            const updatedTopics = [...topics];
            updatedTopics.splice(index, 1);
            setTopics(updatedTopics);
        }
    };

    // Handle skill input field
    const handleSkillChange = (index, event) => {
        const { name, value } = event.target;
        // Create a deep copy of the skills array
        const updatedSkills = [...skills];
        // Create a deep copy of the skill object at the specified index
        const updatedSkill = { ...updatedSkills[index] };
        // Update the property within the copied object
        updatedSkill[name] = value;
        // Update the copied object back into the copied array
        updatedSkills[index] = updatedSkill;
        // Set the state with the updated array
        setSkills(updatedSkills);
    };

    const addSkillField = () => {
        setSkills([...skills, { skillName: '', skillDetails: '' }]);
    };

    const removeSkillField = (index) => {
        if (skills.length > 1) {
            const updatedSkills = [...skills];
            updatedSkills.splice(index, 1);
            setSkills(updatedSkills);
        };
    };


    // New module for chapter is start here 

    const handleAddChapter = () => {
        // Check if all existing chapters are valid
        const allChaptersValid = chapters.every(isChapterValid);
        if (!allChaptersValid) {
            alert('Please fill in all required fields for the existing chapters before adding a new one.');
            return;
        }
        setChapters((prevChapters) => [
            ...prevChapters,
            {
                title: '',
                resources: [],
            },
        ]);
    };


    const handleCollapseStage = (chapterIndex) => {
        // Toggle the collapsed state for the clicked chapter
        setCollapsedChapters((prevCollapsedChapters) => {
            const newCollapsedChapters = [...prevCollapsedChapters];
            newCollapsedChapters[chapterIndex] = !newCollapsedChapters[chapterIndex];
            return newCollapsedChapters;
        });
    };


    const isChapterValid = (chapter) => {
        return (
            chapter && chapter.title && chapter.title.trim() !== '' &&
            chapter.resources.every(resource => resource.title && resource.title.trim() !== '')
        );
    };

    const handleRemoveChapter = (chapterIndex) => {
        const newChapters = [...chapters];
        newChapters.splice(chapterIndex, 1);
        setChapters(newChapters);
    };


    const handleAddResource = (chapterIndex, resourceType, title) => {
        setChapters(prevChapters => {
            const newChapters = [...prevChapters];

            let newResource;
            if (resourceType === 'quiz') {
                newResource = {
                    type: resourceType,
                    title: title,
                    questions: [{
                        title: '',
                        options: [''],
                        correctAnswer: '',
                        marks: 0,
                    }],
                    minMarks: 0,
                    totalMarks: 0
                };
            } else {
                setAddingNewResource(true);
                newResource = {
                    type: resourceType,
                    title: title,
                    // file: null,
                    newFile: true
                };
            }

            // Create a new array for resources using spread syntax
            const updatedResources = [
                ...newChapters[chapterIndex].resources,
                newResource
            ];

            // Create a new chapter object with updated resources array
            const updatedChapter = {
                ...newChapters[chapterIndex],
                resources: updatedResources
            };

            // Update the chapters array with the new chapter object
            newChapters[chapterIndex] = updatedChapter;

            resourcePopups[chapterIndex] = false;
            return newChapters;
        });
    };




    const handleResourceTitleChange = (chapterIndex, resourceIndex, value) => {
        setChapters(prevChapters => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex] = {
                ...updatedChapters[chapterIndex],
                resources: updatedChapters[chapterIndex].resources.map((resource, index) =>
                    index === resourceIndex ? { ...resource, title: value } : resource
                ),
            };
            return updatedChapters;
        });
    };
    // const handleResourceTitleChange = (chapterIndex, resourceIndex, value) => {
    //     setChapters(prevChapters => {
    //         const updatedChapters = [...prevChapters];
    //         updatedChapters[chapterIndex] = {
    //             ...updatedChapters[chapterIndex],
    //             resources: updatedChapters[chapterIndex].resources.map((resource, index) =>
    //                 index === resourceIndex ? { ...resource, title: value } : resource
    //             ),
    //         };
    //         return updatedChapters;
    //     });
    // };

    const handleVideoURLChange = (chapterIndex, resourceIndex, value) => {
        setChapters(prevChapters => {
            const newChapters = [...prevChapters];
            newChapters[chapterIndex] = {
                ...newChapters[chapterIndex],
                resources: newChapters[chapterIndex].resources.map((resource, index) => {
                    if (index === resourceIndex) {
                        return {
                            ...resource,
                            url: value,
                        };
                    }
                    return resource;
                }),
            };
            return newChapters;
        });
    };


    const handleRemoveResource = (chapterIndex, resourceIndex) => {
        setChapters(prevChapters => {
            const updatedChapters = prevChapters.map((chapter, index) => {
                if (index === chapterIndex) {
                    return {
                        ...chapter,
                        resources: chapter.resources.filter((_, i) => i !== resourceIndex),
                    };
                }
                return chapter;
            });

            return updatedChapters;
        });
    };





    const [resourcePopups, setResourcePopups] = useState(new Array(chapters?.length).fill(false));

    const handleResourcePopup = (chapterIndex, show) => {
        const updatedResourcePopups = [...resourcePopups];
        updatedResourcePopups[chapterIndex] = show;
        setResourcePopups(updatedResourcePopups);
    };


    const handleQuestionTitleChange = (chapterIndex, resourceIndex, questionIndex, newQuestionTitle) => {
        setChapters(prevChapters => {
            const newChapters = [...prevChapters];
            newChapters[chapterIndex] = {
                ...newChapters[chapterIndex],
                resources: newChapters[chapterIndex].resources.map((resource, index) => {
                    if (index === resourceIndex && resource.type === 'quiz') {
                        return {
                            ...resource,
                            questions: resource.questions.map((question, qIndex) => {
                                if (qIndex === questionIndex) {
                                    return {
                                        ...question,
                                        title: newQuestionTitle,
                                    };
                                }
                                return question;
                            }),
                        };
                    }
                    return resource;
                }),
            };
            return newChapters;
        });
    };

    const handleAddOption = (chapterIndex, resourceIndex, questionIndex) => {
        setChapters(prevChapters => {
            return prevChapters.map((chapter, i) => {
                if (i !== chapterIndex) {
                    return chapter;
                }

                const newResources = chapter.resources.map((resource, j) => {
                    if (j !== resourceIndex) {
                        return resource;
                    }

                    const newQuestions = resource.questions.map((question, k) => {
                        if (k !== questionIndex) {
                            return question;
                        }

                        const newOptions = [...question.options, ''];
                        return { ...question, options: newOptions };
                    });

                    return { ...resource, questions: newQuestions };
                });

                return { ...chapter, resources: newResources };
            });
        });
    };


    const handleOptionChange = (chapterIndex, resourceIndex, questionIndex, optionIndex, newOption) => {
        setChapters(prevChapters => {
            const newChapters = [...prevChapters];
            newChapters[chapterIndex] = {
                ...newChapters[chapterIndex],
                resources: newChapters[chapterIndex].resources.map((resource, index) => {
                    if (index === resourceIndex && resource.type === 'quiz') {
                        return {
                            ...resource,
                            questions: resource.questions.map((question, qIndex) => {
                                if (qIndex === questionIndex) {
                                    return {
                                        ...question,
                                        options: question.options.map((opt, oIndex) => {
                                            return oIndex === optionIndex ? newOption : opt;
                                        }),
                                    };
                                }
                                return question;
                            }),
                        };
                    }
                    return resource;
                }),
            };
            return newChapters;
        });
    };


    const handleAddQuestion = (chapterIndex, resourceIndex) => {
        setChapters(prevChapters => {
            const newChapters = JSON.parse(JSON.stringify(prevChapters)); // Deep copy the state

            newChapters[chapterIndex].resources[resourceIndex].questions.push({
                title: '',
                options: [''], // Add an empty option by default
                correctAnswer: '',
                marks: 0,
            });

            return newChapters;
        });
    };

    const handleRemoveQuestion = (chapterIndex, resourceIndex, questionIndex) => {
        setChapters(prevChapters => {
            const newChapters = JSON.parse(JSON.stringify(prevChapters)); // Deep copy the state

            const questions = newChapters[chapterIndex].resources[resourceIndex].questions || [];

            // Ensure that at least one question remains before removing
            if (questions.length > 1) {
                questions.splice(questionIndex, 1);
                newChapters[chapterIndex].resources[resourceIndex].questions = questions;
            }

            return newChapters;
        });
    };



    const handleCorrectAnswerChange = (chapterIndex, resourceIndex, questionIndex, correctAnswer) => {
        setChapters(prevChapters => {
            const newChapters = JSON.parse(JSON.stringify(prevChapters)); // Deep copy the state

            newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].correctAnswer = correctAnswer;
            setChapters(newChapters);
        });
    };

    const handleRemoveOption = (chapterIndex, resourceIndex, questionIndex, optionIndex) => {
        setChapters(prevChapters => {
            const newChapters = JSON.parse(JSON.stringify(prevChapters)); // Deep copy the state
            // Ensure that at least one option remains before removing
            if (newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options.length > 1) {
                newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].options.splice(optionIndex, 1);
            }

            return newChapters;
        });
    };

    const handleRemoveQuiz = (chapterIndex, resourceIndex) => {
        setChapters(prevChapters => {
            const newChapters = JSON.parse(JSON.stringify(prevChapters)); // Deep copy the state
            const resources = newChapters[chapterIndex].resources || [];
            // Ensure that at least one question remains before removing
            if (resources.length > 0) {
                resources.splice(resourceIndex, 1);
                newChapters[chapterIndex].resources = resources;
            }
            return newChapters;
        });
    };

    // const handleRemoveChapter = (chapterIndex) => {
    //     const newChapters = [...chapters];
    //     newChapters.splice(chapterIndex, 1);
    //     setChapters(newChapters);
    // };

    const [newResources, setNewResources] = useState([]);
    // const [copiedData, setCopiedData] = useState(productData?.chapters)


    // const fileInputRefs = useRef(chapters?.map(chapter => chapter?.resources?.map(() => createRef())));
    // const fileInputRefs = useRef(
    //     chapters?.map(chapter => chapter.resources.map(() => createRef())) || []
    // );

    const fileInputRefs = useRef([]);


    // useEffect(() => {
    //     // Initialize fileInputRefs based on chapters
    //     fileInputRefs.current = chapters?.map(chapter =>
    //         chapter.resources.map(() => createRef())
    //     );
    // }, [chapters, fileInputRefs]);

    // Initialize fileInputRefs based on chapters
    if (chapters?.length > 0 && fileInputRefs.current.length === 0) {
        fileInputRefs.current = chapters.map(chapter =>
            chapter.resources.map(() => createRef())
        );
    }



    const handleFileButtonClick = (chapterIndex, resourceIndex) => {
        // Trigger the file input click for the corresponding resource
        fileInputRefs?.current[chapterIndex][resourceIndex].current.click();
    };

    // const handleFileButtonClick = (chapterIndex, resourceIndex) => {
    //     if (fileInputRefs.current[chapterIndex] && fileInputRefs.current[chapterIndex][resourceIndex]) {
    //         // Trigger the file input click for the corresponding resource
    //         fileInputRefs.current[chapterIndex][resourceIndex].current.click();
    //     }
    // };

    // const handleFileButtonClick = (chapterIndex, resourceIndex) => {
    //     const fileInputRef = fileInputRefs.current?.[chapterIndex]?.[resourceIndex];

    //     if (fileInputRef) {
    //         // Trigger the file input click for the corresponding resource
    //         fileInputRef.current?.click();
    //     } else {
    //         console.error(`Ref not found for chapterIndex: ${chapterIndex}, resourceIndex: ${resourceIndex}`);
    //     }
    // };



    // const updatedChapters = [...chapters];
    const handleResourceFileChange = (chapterIndex, resourceIndex, file, isEdit) => {
        setChapters(prevChapters => {

            const newChapters = [...prevChapters];

            // Create a new array for resources using spread syntax
            const updatedResources = [...newChapters[chapterIndex].resources];

            if (file) {
                if (isEdit) {
                    updatedResources[resourceIndex] = {
                        ...updatedResources[resourceIndex], isEdit,
                        url: file,
                    };
                }
                updatedResources[resourceIndex] = {
                    ...updatedResources[resourceIndex],
                    url: file
                };
            }
            else {
                updatedResources[resourceIndex] = {
                    ...updatedResources[resourceIndex],
                    url: backupChapters[chapterIndex].resources[resourceIndex].url
                };
            }
            // Update the specific resource with the new file

            // Create a new chapter object with updated resources array
            const updatedChapter = {
                ...newChapters[chapterIndex],
                resources: updatedResources
            };
            // Update the chapters array with the new chapter object
            newChapters[chapterIndex] = updatedChapter;
            return newChapters;
        });
    };

    const handleFileChange = (file, chapterIndex, resourceIndex) => {
        // Call the handleResourceFileChange function with the selected file
        handleResourceFileChange(chapterIndex, resourceIndex, file, true);
    };

    // const handleResourceFileChange = (chapterIndex, resourceIndex, file) => {
    //     setChapters(prevChapters => {
    //         const newChapters = prevChapters.map((chapter, cIndex) => {
    //             if (cIndex === chapterIndex) {
    //                 const updatedResources = chapter.resources.map((resource, rIndex) => {
    //                     if (rIndex === resourceIndex) {
    //                         return {
    //                             ...resource,
    //                             url: file
    //                         };
    //                     }
    //                     return resource;
    //                 });

    //                 return {
    //                     ...chapter,
    //                     resources: updatedResources
    //                 };
    //             }
    //             return chapter;
    //         });

    //         return newChapters;
    //     });
    // };







    const saveChapters = () => {
        const mergedChapters = chapters.map((chapter, key) => ({
            ...chapter,
            resources: [
                ...chapter.resources,
                ...newResources.filter(resource => resource?.chapterIndex === key)
            ]
        }));

        setChapters(mergedChapters);
        setNewResources([]); // Reset new resources after saving
    }

    const handleEditFile = (chapterIndex, resourceIndex) => {
        const newChapters = JSON.parse(JSON.stringify(chapters));
        const existingFile = backupChapters && backupChapters[chapterIndex].resources[resourceIndex].url;
        newChapters[chapterIndex].resources[resourceIndex].editFile = !newChapters[chapterIndex].resources[resourceIndex].editFile;
        if (newChapters[chapterIndex].resources[resourceIndex].editFile === false) {
            newChapters[chapterIndex].resources[resourceIndex].url = existingFile;
            setChapters(newChapters);
        }
        else {
            setChapters(newChapters);
        }
    };

    const [minPassingMarksErr, setMinPassingMarksErr] = useState(false);
    const [minPassingMarksErrMessage, setMinPassingMarksErrMessage] = useState("Minimum passing marks should be greater than 0 and less than total marks");

    const handleMinPassingMarksChange = (chapterIndex, resourceIndex, minPassingMarks) => {
        const newChapters = JSON.parse(JSON.stringify(chapters));
        newChapters[chapterIndex].resources[resourceIndex].minMarks = minPassingMarks;
        setChapters(newChapters);
    };

    const handleQuestionMarksChange = (chapterIndex, resourceIndex, questionIndex, value) => {
        const newChapters = JSON.parse(JSON.stringify(chapters));
        newChapters[chapterIndex].resources[resourceIndex].questions[questionIndex].marks = value;
        setChapters(newChapters);
    };

    const handleTotalMarksChange = (chapterIndex, resourceIndex, totalMarks) => {
        const newChapters = JSON.parse(JSON.stringify(chapters));
        newChapters[chapterIndex].resources[resourceIndex].totalMarks = totalMarks;
        setChapters(newChapters);
    };


    // New module for chapter is end here 

    // const allowedImageFormats = ['image/jpeg', 'image/png', 'image/jpg'];

    const updatedProduct = async (dispatch, product) => {
        dispatch(updateProductStart());
        // setActiveLoader(true);
        try {
            const res = await userRequest.put(`/product/${courseId}`, product);
            if (res.status === 200) {
                setActiveLoader(false);
                setActivePopup(true)
                setPopuptext("Course has been updated.");
                setPopupStatus(true);
                setFileInputs([]);
            }
            dispatch(updateProductSuccess(res.data));
        } catch (error) {
            dispatch(updateProductFailure());
            setActiveLoader(false);
            if (error.response?.status === 409) {
                setTitleError(true);
                setTitleErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const titleErr = validateRequired(title);
        const descErr = validateRequired(description);
        // const priceErr = validateRequired(price);
        const ageGroupErr = validateRequired(ageGroup);
        const courseTypeErr = validateRequired(courseType);
        const overviewErr = validateRequired(overview);
        const topicsErr = validateRequired(topics);
        const numberOfSessionsErr = validateRequired(numberOfSessions);
        // const productImageErr = validateRequired(productImage);
        // const documentFileErr = validateRequired(documentFile);

        if (titleErr) {
            formIsValid = false;
            newErrors.title = titleErr;
        }
        if (descErr) {
            formIsValid = false;
            newErrors.desc = descErr;
        }
        // if (priceErr) {
        //     formIsValid = false;
        //     newErrors.price = priceErr;
        // }

        if (ageGroupErr) {
            formIsValid = false;
            newErrors.ageGroup = ageGroupErr;
        }
        if (courseTypeErr) {
            formIsValid = false;
            newErrors.courseType = courseTypeErr;
        }
        if (overviewErr) {
            formIsValid = false;
            newErrors.overview = overviewErr;
        }
        if (topicsErr) {
            formIsValid = false;
            newErrors.topics = topicsErr;
        }

        if (numberOfSessionsErr) {
            formIsValid = false;
            newErrors.numberOfSessions = numberOfSessionsErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }

    const handleDescriptionChange = (value) => {
        setOverview(value);
    };

    const uploadProductImageToSpace = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fileUploadRequest.post('/upload/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.status === 200) {
                const fileUrl = response.data.fileUrl;
                // setProductImage(fileUrl); // Set the product image URL in state
                return fileUrl
            }
        } catch (error) {
            console.error('Error uploading product image:', error);
        }
    };


    const handleProductImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            // Check if the selected file is an image
            const isImage = allowedImageFormats.includes(files[0].type);
            if (!isImage) {
                // Show an error message or perform some action for invalid image format
                alert('Please select a valid image file (JPEG, PNG, or JPG)');
                event.target.value = null;
                setProductImage('');
                return;
            } else {
                setProductImage(files[0]);
                setImagePreview(URL.createObjectURL(files[0]));
            }
        }
        else {
            setProductImage(null);
            setImagePreview(productData && productData.productImg);
        }
    };

    const handleChapterTitleChange = (chapterIndex, value) => {
        setChapters((prevChapters) => {
            const updatedChapters = [...prevChapters];
            updatedChapters[chapterIndex] = {
                ...updatedChapters[chapterIndex],
                title: value,
            };
            return updatedChapters;
        });
    };

    // Chapter wise code ended here==============

    const overviewImageRef = useRef(null);
    const productImageRef = useRef(null);

    const handleAddImages = (fieldName) => {
        if (fieldName == "overviewImage") {
            overviewImageRef.current.click(); // Trigger file input click event
        }
        if (fieldName == "productImage") {
            productImageRef.current.click(); // Trigger file input click event
        }
    };

    const handleImageSelection = (files, fieldName) => {
        const selectedFiles = files; // Get the selected files
        const newImages = Array.from(selectedFiles).map(file => file);
        if (fieldName == "overviewImage") {
            setOverviewImages(prevImages => [...prevImages, ...newImages]);
        }

        if (fieldName == "productImage") {
            setCurrentProductImg(prevImages => [...prevImages, ...newImages]);
        }
    };

    const handleDeleteOverviewImage = (index, fieldName) => {
        if (fieldName == "overviewImage") {
            const newOverviewImages = [...overviewImages];
            newOverviewImages.splice(index, 1); // Remove the image at the specified index
            setOverviewImages(newOverviewImages); // Update the state with the new array of images
        }

        if (fieldName == "productImage") {
            const newProductImages = [...currentProductImg];
            newProductImages.splice(index, 1); // Remove the image at the specified index
            setCurrentProductImg(newProductImages); // Update the state with the new array of images
        }

    };

    // Function to allow the user to select an image from their device
    const selectImageFromDevice = () => {
        return new Promise((resolve) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.onchange = (e) => {
                const file = e.target.files[0];
                resolve(file);
            };
            input.click();
        });
    };

    //================*************** using blob funcationality***********============

    // const handleUpdateOverviewImage = async (index) => {
    //     const newOverviewImages = [...overviewImages];
    //     const file = await selectImageFromDevice(); // Function to allow the user to select an image
    //     if (file) {
    //         newOverviewImages[index] = URL.createObjectURL(file); // Replace the existing image with the new one at the specified index
    //         setOverviewImages(newOverviewImages); // Update the state with the new array of images
    //     }
    // };


    const handleUpdateOverviewImage = async (index, fieldName) => {
        if (fieldName == "overviewImage") {
            const newOverviewImages = [...overviewImages];
            const file = await selectImageFromDevice(); // Function to allow the user to select an image
            if (file) {
                newOverviewImages[index] = file; // Replace the existing image with the new one at the specified index
                setOverviewImages(newOverviewImages); // Update the state with the new array of images
            }
        }

        if (fieldName == "productImage") {
            const newProductImage = [...currentProductImg];
            const file = await selectImageFromDevice(); // Function to allow the user to select an image
            if (file) {
                newProductImage[index] = file; // Replace the existing image with the new one at the specified index
                setCurrentProductImg(newProductImage); // Update the state with the new array of images
            }
        }
    };


    const handleAgeGroupChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setAgeGroup(selectedOptions);
    };

    const uploadDocumentFilesToSpace = async (files) => {
        const uploadedFileDetails = [];

        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await fileUploadRequest.post('/upload/file', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response && response.status === 200) {
                    const fileUrl = response.data.fileUrl;
                    uploadedFileDetails.push(fileUrl);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }

        return uploadedFileDetails;
    };


    // Function to update product in database
    const handleUpdateProduct = async () => {
        setSuccess(false);
        setError(false);
        setTitleError(false);
        const trimmedTitle = title.trim();
        try {
            if (validateForm()) {
                try {
                    setActiveLoader(true);
                    setLoaderText("Updating")
                    // Checking product title is unique or not
                    const res = await userRequest.post('/product/search', { title: trimmedTitle, id: courseId });

                    if (res && res.status == 200) {
                        // Upload resources for each chapter
                        const updatedChapters = await Promise.all(
                            chapters.map(async (chapter) => {
                                const { title, resources } = chapter;
                                // Upload files for each resource in the chapter
                                const updatedResources = await Promise.all(
                                    resources.map(async (resource) => {
                                        // const { title, type, file, url, questions, minMarks, totalMarks, newFile } = resource;
                                        // Upload the file to the space
                                        if (resource?.url?.name) {
                                            const fileURL = await uploadDocumentFilesToSpace([resource.url]);
                                            resource.url = fileURL[0];
                                            const { editFile, newFile, ...others } = resource;
                                            return {
                                                ...others
                                            };
                                        }
                                        else {
                                            const { editFile, newFile, ...others } = resource
                                            return {
                                                ...others
                                            };
                                        }
                                    })
                                );

                                return {
                                    title,
                                    resources: updatedResources,
                                };
                            })
                        );


                        let productImageURL;

                        // if (productImage?.type) {
                        //     productImageURL = await uploadProductImageToSpace(productImage)
                        // }

                        // else {
                        //     productImageURL = currentProductImg;
                        // }
                        if (overviewImages?.length > 0) {
                            // Map through each item in the overviewImages array
                            await Promise.all(overviewImages.map(async (item, index) => {
                                // Check if the item is a blob URL (indicating a newly added or updated image)
                                // if (item.startsWith('blob:')) {
                                //     // Upload the image to the server
                                //     const imageUrl = await uploadProductImageToSpace(item);
                                //     // Replace the blob URL in the overviewImages array with the uploaded image URL
                                //     overviewImages[index] = imageUrl;
                                // }
                                if (item?.name) {
                                    // Upload the image to the server
                                    const imageUrl = await uploadProductImageToSpace(item);
                                    // Replace the blob URL in the overviewImages array with the uploaded image URL
                                    overviewImages[index] = imageUrl;
                                }
                            }));
                        }

                        if (currentProductImg?.length > 0) {
                            // Map through each item in the overviewImages array
                            await Promise.all(currentProductImg.map(async (item, index) => {
                                // Check if the item is a blob URL (indicating a newly added or updated image)
                                // if (item.startsWith('blob:')) {
                                //     // Upload the image to the server
                                //     const imageUrl = await uploadProductImageToSpace(item);
                                //     // Replace the blob URL in the overviewImages array with the uploaded image URL
                                //     overviewImages[index] = imageUrl;
                                // }
                                if (item?.name) {
                                    // Upload the image to the server
                                    const imageUrl = await uploadProductImageToSpace(item);
                                    // Replace the blob URL in the overviewImages array with the uploaded image URL
                                    currentProductImg[index] = imageUrl;
                                }
                            }));
                        }

                        updatedProduct(dispatch, {
                            title: trimmedTitle,
                            description,
                            ageGroup,
                            courseType,
                            snapAccess,
                            scratchAccess,
                            status,
                            price,
                            priceInDollar,
                            freeInIndia,
                            freeOutOfIndia,
                            isForSubscription,
                            productImg: currentProductImg,
                            topics,
                            overview,
                            skills,
                            numberOfSessions,
                            bestSeller: isBestSeller,
                            shortDescription,
                            chapters: updatedChapters,
                            videoThumbnail,
                            availability,
                            metaTitle,
                            metaDescription,
                            overviewImages
                        });
                    }
                } catch (error) {
                    if (error.response && error.response.status === 409) {
                        setActiveLoader(false);
                        setLoaderText('');
                        setTitleError(true);
                        setTitleErrorMessage(error.response.data);
                    }

                    else {
                        setActiveLoader(false);
                        setLoaderText('');
                        setActivePopup(true);
                        setPopupStatus(false);
                    };
                };
            };
        } catch (error) {
            setActiveLoader(false);
            setLoaderText('');
            console.error('Error uploading files in:', error);
            setError(true);
        };
    };

    const hidePopup = () => {
        setActivePopup(false);
    }

    const dragChapter = useRef(0);
    const draggedOverChapter = useRef(0);

    const dragChapterResource = useRef(0);
    const draggedOverChapterResource = useRef(0);

    const handleChapterAndResourceSort = (dragType, chapterIndex, resourceIndex) => {
        setChapters((prevChapters) => {
            // Create a deep copy of the chapters array
            const chaptersClone = JSON.parse(JSON.stringify(prevChapters));

            if (dragType === 'chapter') {
                // Chapter drag-and-drop logic
                const tempChapter = chaptersClone[dragChapter.current];
                chaptersClone[dragChapter.current] = chaptersClone[draggedOverChapter.current];
                chaptersClone[draggedOverChapter.current] = tempChapter;
            } else if (dragType === 'resource') {
                // Resource drag-and-drop logic
                const draggedResource = chaptersClone[chapterIndex].resources[dragChapterResource.current];
                const draggedResourceIndex = resourceIndex;

                const draggedOverResource = chaptersClone[chapterIndex].resources[draggedOverChapterResource.current];
                const draggedOverResourceIndex = draggedOverChapterResource.current;

                chaptersClone[chapterIndex].resources[draggedResourceIndex] = draggedOverResource;
                chaptersClone[chapterIndex].resources[draggedOverResourceIndex] = draggedResource;
            }

            // Reset the drag type
            // setDragType(null);

            // Log the updated values for debugging

            return chaptersClone;
        });
    };

    const onDragEnd = (dragType, chapterIndex, resourceIndex) => {
        // Call handleChapterAndResourceSort with the appropriate arguments
        if (dragType === 'chapter') {
            handleChapterAndResourceSort(dragType);
        } else if (dragType === 'resource') {
            handleChapterAndResourceSort(dragType, chapterIndex, resourceIndex);
        }
    };


    if (activePopup) {
        return (
            <Popup status={popupStatus} message={popupText} onClickAction={hidePopup} />
        )
    };


    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }


    return (
        <div className='container' >
            <div className="row mt-5 mb-3">
                <div className="col-12">
                    <div className="pageTitleWrapper">
                        <h2 className="page-heading">Update course</h2>
                    </div>
                </div>
            </div >
            <div className="row">
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>title</label>
                        <input type="text" name='title' value={title} className='newCourseInput' onChange={(e) => setTitle(e.target.value)} placeholder='Title' required />
                        {
                            titleError && (
                                <p className='error-text text-danger'>{titleErrorMessage}</p>
                            )
                        }
                        <span className='error-text'>{errors.title}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta title</label>
                        <input type="text" name='meta title' value={metaTitle} className='newCourseInput' onChange={(e) => setMetaTitle(e.target.value)} placeholder='Title' />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>short description</label>
                        <input type="text" name='description' value={shortDescription} className='newCourseInput' onChange={(e) => setShortDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.shortDescription}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>description</label>
                        <input type="text" name='description' value={description} className='newCourseInput' onChange={(e) => setDescription(e.target.value)} placeholder='Description' />
                        <span className='error-text'>{errors.desc}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>meta description</label>
                        <input type="text" name='meta description' value={metaDescription} className='newCourseInput' onChange={(e) => setMetaDescription(e.target.value)} placeholder='Description' />
                        {/* <span className='error-text'>{errors.desc}</span> */}
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Age Group</label>
                        <select name="ageGroup" value={ageGroup} onChange={handleAgeGroupChange} multiple>
                            {/* <option value="" selected disabled>Select</option> */}
                            {
                                ageGroups.map((item, index) => {
                                    return <option value={item.ageGroup} key={index} >{item.ageGroup}</option>
                                })
                            }
                        </select>
                        <span className='error-text'>{errors.ageGroup}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Course Type</label>
                        <select name="courseType" value={courseType} onChange={(e) => setCourseType(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            {
                                courseTypes.map((item, index) => {
                                    return <option value={item.courseType} key={index} >{item.courseType}</option>
                                })
                            }
                        </select>
                        <span className='error-text'>{errors.courseType}</span>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Snap Access</label>
                        <select name="snapAccess" value={snapAccess} onChange={(e) => setSnapAccess(e.target.value)}>
                            <option value="" disabled >Select</option>
                            <option value={true} >Yes</option>
                            <option value={false} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>Scratch Access</label>
                        <select name="scratchAccess" value={scratchAccess} onChange={(e) => setScratchAccess(e.target.value)}>
                            <option value="" disabled >Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free In India</label>
                        <select name="status" value={freeInIndia} onChange={(e) => handleFreeInIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Free Out Of India</label>
                        <select name="status" value={freeOutOfIndia} onChange={(e) => handleFreeOutOfIndia(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"true"} >Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Course is available</label>
                        <select name="availability" value={availability} onChange={(e) => setAvailability(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={"all"} >For All </option>
                            <option value={"onlyForIndia"} >Only For India</option>
                            <option value={"onlyForOutOfIndia"} >Only For Out Of India</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Subscription</label>
                        <select name="status" value={isForSubscription} onChange={(e) => setIsForSubscription(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value={true} >Yes</option>
                            <option value={false} >No</option>
                        </select>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Rupees)" : "Price (In Rupees)"}</label>
                        <input type="text" name='price' value={price} className='newCourseInput' onChange={(e) => setPrice(e.target.value)} placeholder='Price' disabled={freeInIndia === true ? true : false} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-input-wrapper">
                        <label>{isForSubscription === "true" ? "Price Per Month (In Dollar)" : "Price (In Dollar)"}</label>
                        <input type="text" name='price' value={priceInDollar} className='newCourseInput' onChange={(e) => setPriceInDollar(e.target.value)} disabled={freeOutOfIndia === true ? true : false} placeholder='Price' />
                        <span className='error-text'>{errors.priceInDollar}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Status</label>
                        <select name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Active</option>
                            <option value="false" >Draft</option>
                        </select>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Add to best seller</label>
                        <select name="status" value={isBestSeller} onChange={(e) => setIsBestSeller(e.target.value)}>
                            <option value="" selected disabled>Select</option>
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </select>
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Update Image</label>
                        <input type="file" name='file' className='newCourseInput' accept={allowedImageFormats} onChange={handleProductImageChange} placeholder='Price' />
                        <span className='error-text'>{errors.productImage}</span>
                    </div>
                    <div className="form-input-wrapper">
                        <label>Preview Image</label>
                        <div className="course-img-wrapper">
                            {
                                isUrl(imagePreview) ? (
                                    <img src={imagePreview || productImage} alt="course-img" />
                                ) : (productImage?.type) ? (
                                    <img src={imagePreview || productImage} alt="course-img" />
                                ) : (
                                    <img src={MEDIA_URL + imagePreview} alt="course-img" />
                                )
                            }
                        </div>
                    </div>
                    
                </div> */}
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Update Image</label>
                        <input type="file" name='file' className='newCourseInput' onChange={handleProductImageChange} placeholder='Price' />
                        <span className='error-text'>{errors.productImage}</span>
                    </div>
                    <div className="form-input-wrapper">
                        <label>Preview Image</label>
                        {/* <div className="course-img-wrapper"> */}
                        {/* <img src={ MEDIA_URL + imagePreview || productImage} alt="course-img" /> */}
                        {/* {
                                isUrl(imagePreview) ? (
                                    <img src={imagePreview || productImage} alt="product-img" />
                                ) : (productImage?.name) ? (
                                    <img src={imagePreview || productImage} alt="product-img" />
                                ) : (
                                    <img src={MEDIA_URL + imagePreview} alt="product-img" />
                                )
                            } */}
                        <div className="overview-img-wrapper">
                            {
                                currentProductImg?.map((item, index) => {
                                    return (
                                        <div className="course-img-wrapper position-relative">
                                            {item?.name ? ( // Check if item is a local image
                                                <img src={URL.createObjectURL(item)} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}${item}`} alt="overview-img" />
                                            )}
                                            {/* {item?.startsWith('blob:') ? ( // Check if item is a local image
                                                <img src={item} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}/${item}`} alt="overview-img" />
                                            )} */}
                                            {
                                                <div className="update-wrapper-main">
                                                    <div className="update-tool-wrapper">
                                                        <button className='update-btn' onClick={() => handleUpdateOverviewImage(index, "productImage")}>Update</button>
                                                        <button className='delete-btn' onClick={() => handleDeleteOverviewImage(index, "productImage")}>Delete</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                <div className="course-img-wrapper">
                                    <button className='add-more-button' onClick={() => handleAddImages("productImage")}>
                                        <img src={`${process.env.PUBLIC_URL}/images/add-img.png`} className='add-more-icon' />
                                        <strong>{`${overviewImages?.length > 0 ? "Add more" : "Add Images"}`}</strong>
                                    </button>
                                    <input type="file" name='file' style={{ display: "none" }} ref={productImageRef} onChange={(e) => handleImageSelection(e.target.files, "productImage")} accept='image/*' multiple />
                                </div>
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Video Thumbnail</label>
                        <input type="text" name='video-url' className='newCourseInput' value={videoThumbnail} onChange={(e) => setVideoThumbnail(e.target.value)} placeholder='Video URL' />
                        {/* <span className='error-text'>{errors.productImage}</span> */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>overview</label>
                        <ReactQuill value={overview} onChange={handleDescriptionChange} />
                        <span className='error-text'>{errors.overview}</span>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>overview images</label>
                        <div className="overview-img-wrapper">
                            {
                                overviewImages?.map((item, index) => {
                                    return (
                                        <div className="course-img-wrapper position-relative">
                                            {item?.name ? ( // Check if item is a local image
                                                <img src={URL.createObjectURL(item)} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}${item}`} alt="overview-img" />
                                            )}
                                            {/* {item?.startsWith('blob:') ? ( // Check if item is a local image
                                                <img src={item} alt="overview-img" />
                                            ) : ( // If item does not start with "blob:", assume it's a server image
                                                <img src={`${MEDIA_URL}/${item}`} alt="overview-img" />
                                            )} */}
                                            {
                                                <div className="update-wrapper-main">
                                                    <div className="update-tool-wrapper">
                                                        <button className='update-btn' onClick={() => handleUpdateOverviewImage(index, "overviewImage")}>Update</button>
                                                        <button className='delete-btn' onClick={() => handleDeleteOverviewImage(index, "overviewImage")}>Delete</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                <div className="course-img-wrapper">
                                    <button className='add-more-button' onClick={() => handleAddImages("overviewImage")}>
                                        <img src={`${process.env.PUBLIC_URL}/images/add-img.png`} className='add-more-icon' />
                                        <strong>{`${overviewImages?.length > 0 ? "Add more" : "Add Images"}`}</strong>
                                    </button>
                                    <input type="file" name='file' style={{ display: "none" }} ref={overviewImageRef} onChange={(e) => handleImageSelection(e.target.files, "overviewImage")} accept='image/*' multiple />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Topic</label>
                            <button type="button" className='custom-btn' onClick={addTopicField}>
                                Add Topic
                            </button>
                        </div>

                        {topics?.map((topic, index) => {
                            return (<>
                                <div key={index} className='row'>
                                    <div className="col-12 pb-3">
                                        <input className='newCourseInput'
                                            type="text"
                                            name="topicName"
                                            value={topic.topicName}
                                            onChange={(event) => handleTopicChange(index, event)}
                                            placeholder="Enter topic name"
                                        />
                                    </div>
                                    <div className="col-11">
                                        <textarea className='newCourseInput'
                                            name="topicDetails"
                                            value={topic.topicDetails}
                                            onChange={(event) => handleTopicChange(index, event)}
                                            placeholder="Enter topic details"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${topics.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeTopicField(index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-input-wrapper">
                        <div className="topics-header">
                            <label>Skill</label>
                            <button type="button" className='custom-btn' onClick={addSkillField}>
                                Add Skill
                            </button>
                        </div>

                        {skills?.map((skill, index) => {
                            return (<>
                                <div key={index} className='row'>
                                    <div className="col-12 pb-3">
                                        <input className='newCourseInput'
                                            type="text"
                                            name="skillName"
                                            value={skill.skillName}
                                            onChange={(event) => handleSkillChange(index, event)}
                                            placeholder="Enter skill name"
                                        />
                                    </div>
                                    <div className="col-11">
                                        <textarea className='newCourseInput'
                                            name="skillDetails"
                                            value={skill.skillDetails}
                                            onChange={(event) => handleSkillChange(index, event)}
                                            placeholder="Enter skill details"
                                        />
                                    </div>
                                    <div className="col-1">
                                        <div className="remove-btn-wrapper">
                                            <CancelIcon className={`${skills.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                onClick={() => removeSkillField(index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>
                </div>

                <div className='col-12'>
                    <div className="form-input-wrapper">
                        <label className='my-1'>Add Chapters</label>
                        {
                            chapters?.map((chapter, chapterIndex) => (
                                <div key={chapterIndex} className={`chapter-wrapper ${collapsedChapters[chapterIndex] ? 'collapsed' : ''}`}
                                    draggable
                                    onDragStart={() => (dragChapter.current = chapterIndex)}
                                    onDragEnter={() => (draggedOverChapter.current = chapterIndex)}
                                    onDragEnd={() => onDragEnd("chapter")}
                                    onDragOver={(e) => e.preventDefault()}
                                >
                                    <div className="topics-header my-3">
                                        <label className='my-1'>{`Chapter ${chapterIndex + 1}`}</label>
                                        <div className="topics-header-right">
                                            <button type="button" className='custom-btn' onClick={() => handleRemoveChapter(chapterIndex)} >
                                                Remove Chapter
                                            </button>
                                            <ArrowRightIcon className={`chapter-right-arrow ${collapsedChapters[chapterIndex] ? 'collapsed' : ''}`} onClick={() => handleCollapseStage(chapterIndex)} />
                                        </div>
                                    </div>
                                    <input
                                        className='newCourseInput mb-3'
                                        type="text"
                                        placeholder="CHAPTER TITLE"
                                        value={chapter.title}
                                        onChange={(e) => handleChapterTitleChange(chapterIndex, e.target.value)}
                                    />
                                    {
                                        chapter?.resources.map((resource, key) => {
                                            if (resource.type === 'pdf' || resource.type === 'ppt' || resource.type === 'h5p' || resource.type === 'video' || resource.type === 'sb3' || resource.type === 'zip' || resource.type === 'html') {
                                                const acceptedTypes =
                                                    resource.type === 'pdf'
                                                        ? '.pdf'
                                                        : resource.type === 'ppt'
                                                            ? '.ppt, .pptx'
                                                            : resource.type === 'zip'
                                                                ? '.zip'
                                                                : resource.type === 'html' ? '.html' : '.sb3';

                                                return (
                                                    <div key={key} className='row'>
                                                        {
                                                            addingNewResource && resource?.newFile ? (
                                                                <>
                                                                    {
                                                                        resource.type === 'video' ? (
                                                                            <>
                                                                                <div className="col-12"
                                                                                >
                                                                                    <label className='mb-2'>{`Select any ${resource.type} file`}</label>
                                                                                    <input
                                                                                        className='newCourseInput mb-3'
                                                                                        type="text"
                                                                                        placeholder={`${resource.type.toUpperCase()} Title`}
                                                                                        value={resource.title || ''}
                                                                                        onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-11 pb-3">
                                                                                    <input
                                                                                        className='newCourseInput mb-3'
                                                                                        type="text"
                                                                                        placeholder='VIDEO URL'
                                                                                        onChange={(e) => handleVideoURLChange(chapterIndex, key, e.target.value)}
                                                                                        accept={acceptedTypes}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="remove-btn-wrapper">
                                                                                        <CancelIcon className="activeRemoveIcon"
                                                                                            onClick={() => handleRemoveResource(chapterIndex, key)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-12"
                                                                                >
                                                                                    <label className='mb-2'>{`Select any ${resource.type} file`}</label>
                                                                                    <input
                                                                                        className='newCourseInput mb-3'
                                                                                        type="text"
                                                                                        placeholder={`${resource.type.toUpperCase()} Title`}
                                                                                        value={resource.title || ''}
                                                                                        onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-11 pb-3">
                                                                                    <input
                                                                                        className='newCourseInput mb-3'
                                                                                        type="file"
                                                                                        onChange={(e) => handleResourceFileChange(chapterIndex, key, e.target.files[0], true)}
                                                                                        accept={acceptedTypes}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="remove-btn-wrapper">
                                                                                        <CancelIcon className="activeRemoveIcon"
                                                                                            onClick={() => handleRemoveResource(chapterIndex, key)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <div className="col-12 mb-3"
                                                                    draggable
                                                                    onDragStart={() => (dragChapterResource.current = key)}
                                                                    onDragEnter={() => (draggedOverChapterResource.current = key)}
                                                                    onDragEnd={() => onDragEnd("resource", chapterIndex, key)}
                                                                    onDragOver={(e) => e.preventDefault()}
                                                                >
                                                                    <label className='mb-2'>{resource.type !== 'video' ? `Select any ${resource.type} file` : `Enter video details`}</label>
                                                                    <div className="d-flex" style={{ columnGap: '10px' }}>
                                                                        <input
                                                                            className='newCourseInput'
                                                                            type="text"
                                                                            placeholder={`${resource.type.toUpperCase()} Title`}
                                                                            value={resource.title || ''}
                                                                            onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                                        />
                                                                        <div className="file-icon-wrapper">
                                                                            {/* <div>
                                                                                <EditIcon className="editIcon" onClick={() => handleEditFile(chapterIndex, key)} />
                                                                            </div> */}
                                                                            <div className="remove-btn-wrapper">
                                                                                <CancelIcon className="activeRemoveIcon"
                                                                                    onClick={() => handleRemoveResource(chapterIndex, key)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        resource.type === 'video' ? (
                                                                            <div className="col-12 py-2">
                                                                                <input
                                                                                    className='newCourseInput mb-3'
                                                                                    type='text'
                                                                                    value={resource.url}
                                                                                    placeholder="VIDEO URL"
                                                                                    onChange={(e) => handleVideoURLChange(chapterIndex, key, e.target.value)}
                                                                                    accept={acceptedTypes}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    {
                                                                                        resource.isEdit && resource.url?.type && (
                                                                                            <p className='green-text my-2 fw-bold'>{`Selected File : ${resource.url.name}`}</p>
                                                                                        )
                                                                                    }
                                                                                    <button className='custom-btn mt-2' onClick={() => handleFileButtonClick(chapterIndex, key)}>
                                                                                        Update File
                                                                                    </button>
                                                                                    <input
                                                                                        type="file"
                                                                                        // ref={fileInputRefs.current[chapterIndex][key]}
                                                                                        ref={fileInputRefs.current?.[chapterIndex]?.[key] || (() => { })}
                                                                                        style={{ display: 'none' }}
                                                                                        onChange={(e) => handleFileChange(e.target.files[0], chapterIndex, key)}
                                                                                        accept={acceptedTypes}
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    !resource.url && resource.type !== 'video' && (
                                                                                        <p className='error-text p-2'>File not found!</p>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                );
                                            }

                                            if (resource.type === 'video') {
                                                return (
                                                    <div key={key} className='row'
                                                        draggable
                                                        onDragStart={() => (dragChapterResource.current = key)}
                                                        onDragEnter={() => (draggedOverChapterResource.current = key)}
                                                        onDragEnd={() => onDragEnd("resource", chapterIndex, key)}
                                                        onDragOver={(e) => e.preventDefault()}>
                                                        <div className="col-12">
                                                            <label className='mb-2'>Enter video details</label>
                                                            <input
                                                                className='newCourseInput mb-3'
                                                                type="text"
                                                                placeholder={`${resource.type.toUpperCase()} Title`}
                                                                value={resource.title || ''}
                                                                onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-11 pb-3">
                                                            <input
                                                                className='newCourseInput mb-3'
                                                                type={resource.type === 'video' ? "text" : "file"}
                                                                placeholder={resource.type === 'video' ? "VIDEO URL" : ''}
                                                                onChange={(e) => handleVideoURLChange(chapterIndex, key, e.target.value)}
                                                                value={resource.url || ''}
                                                            />
                                                        </div>
                                                        <div className="col-1">
                                                            <div className="remove-btn-wrapper">
                                                                <CancelIcon className="activeRemoveIcon"
                                                                    onClick={() => handleRemoveResource(chapterIndex, key)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (resource.type === 'quiz') {
                                                return (
                                                    <div className="quiz-container">
                                                        <div className="row"
                                                            draggable
                                                            onDragStart={() => (dragChapterResource.current = key)}
                                                            onDragEnter={() => (draggedOverChapterResource.current = key)}
                                                            onDragEnd={() => onDragEnd("resource", chapterIndex, key)}
                                                            onDragOver={(e) => e.preventDefault()}>
                                                            <div className="col-12">
                                                                <div className="form-input-wrapper">
                                                                    <div className="topics-header my-3">
                                                                        <label>Quiz Title</label>
                                                                        <button type="button" className='custom-btn' onClick={() => handleRemoveQuiz(chapterIndex, key)} >
                                                                            Remove Quiz
                                                                        </button>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className='newUserInput'
                                                                        placeholder='Quiz Title'
                                                                        value={resource.title || ''}
                                                                        onChange={(e) => handleResourceTitleChange(chapterIndex, key, e.target.value)}
                                                                    />
                                                                    <span className='error-text'>{errors.title}</span>
                                                                </div>
                                                            </div>

                                                            {/* Question details */}
                                                            {resource.questions?.map((question, questionIndex) => (
                                                                <div key={questionIndex} className="col-12">
                                                                    <div className="form-input-wrapper">
                                                                        <div className="topics-header my-3">
                                                                            <label>{`Question ${questionIndex + 1}`}</label>
                                                                            {
                                                                                resource.questions.length > 1 && (
                                                                                    <button type="button" className='custom-btn' onClick={() => handleRemoveQuestion(chapterIndex, key, questionIndex)} >
                                                                                        Remove Question
                                                                                    </button>
                                                                                )
                                                                            }

                                                                        </div>

                                                                        {/* <input
                                                                            type="text"
                                                                            className="newUserInput"
                                                                            placeholder="Question Title"
                                                                            value={question.title || ''}
                                                                            onChange={(e) => handleQuestionTitleChange(chapterIndex, key, questionIndex, e.target.value)}
                                                                        /> */}
                                                                        <ReactQuill value={question.title || ''} onChange={(value) => handleQuestionTitleChange(chapterIndex, key, questionIndex, value)} modules={{
                                                                            toolbar: [
                                                                                ['image', 'code-block'],
                                                                            ]
                                                                        }} theme="snow" />
                                                                    </div>
                                                                    <div>
                                                                        {question.options.map((option, optionIndex) => (
                                                                            <div key={optionIndex} className="form-input-wrapper">
                                                                                <label>{`Option ${optionIndex + 1}`}</label>
                                                                                <div className="row">
                                                                                    <div className="col-11">
                                                                                        {/* <input
                                                                                            type="text"
                                                                                            className="newUserInput w-100"
                                                                                            placeholder={`Option ${optionIndex + 1}`}
                                                                                            value={option || ''}
                                                                                            onChange={(e) => handleOptionChange(chapterIndex, key, questionIndex, optionIndex, e.target.value)}
                                                                                        /> */}
                                                                                        <ReactQuill value={option || ''} onChange={(value) => handleOptionChange(chapterIndex, key, questionIndex, optionIndex, value)} modules={{
                                                                                            toolbar: [
                                                                                                ['image', 'code-block'],
                                                                                            ]
                                                                                        }} theme="snow" />
                                                                                    </div>
                                                                                    <div className="col-1">
                                                                                        <div className="remove-btn-wrapper">
                                                                                            <CancelIcon className={`${question.options.length > 1 ? "activeRemoveIcon" : "inactiveRemoveIcon"}`}
                                                                                                onClick={() => handleRemoveOption(chapterIndex, key, questionIndex, optionIndex)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        <button className="custom-btn mb-2" onClick={() => handleAddOption(chapterIndex, key, questionIndex)}>Add Option</button>
                                                                    </div>
                                                                    <div className="form-input-wrapper">
                                                                        <label>Correct Answer</label>
                                                                        {
                                                                        }
                                                                        <select className='newUserInput' value={question.correctAnswer || ''} onChange={(e) => handleCorrectAnswerChange(chapterIndex, key, questionIndex, e.target.value)}>
                                                                        <option value="" selected disabled>Select correct option</option>
                                                                            {question.options.map((option, optionIndex) => (
                                                                                <option key={optionIndex} value={option}>
                                                                                    Option {optionIndex + 1}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="form-input-wrapper">
                                                                            <label>Marks for Question</label>
                                                                            <input
                                                                                type="text"
                                                                                className="newUserInput"
                                                                                value={question.marks || ''}
                                                                                onChange={(e) => handleQuestionMarksChange(chapterIndex, key, questionIndex, e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <button className="custom-btn" onClick={() => handleAddQuestion(chapterIndex, key)}>Add Question</button>

                                                            <div className="col-12">
                                                                <div className="form-input-wrapper">
                                                                    <label>Minimum Passing Marks</label>
                                                                    <input
                                                                        type="text"
                                                                        className="newUserInput"
                                                                        value={resource.minMarks || ''}
                                                                        onChange={(e) => handleMinPassingMarksChange(chapterIndex, key, e.target.value)}
                                                                    />
                                                                    {
                                                                        minPassingMarksErr && (
                                                                            <p className="error-text">{minPassingMarksErrMessage}</p>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-input-wrapper">
                                                                    <label>Total Marks</label>
                                                                    <input
                                                                        type="text"
                                                                        className="newUserInput"
                                                                        value={resource.totalMarks || ''}
                                                                        onChange={(e) => handleTotalMarksChange(chapterIndex, key, e.target.value)}

                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12">
                                                            <div className="form-input-wrapper">
                                                                <label>Total Marks (Automatically Calculated)</label>
                                                                <input
                                                                    type="text"
                                                                    className="newUserInput"
                                                                    value={calculateTotalMarks(resource.questions)}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return null; // If resource type is not 'ppt', return null or an empty fragment
                                        })
                                    }
                                    <button className='custom-btn' onClick={() => handleResourcePopup(chapterIndex, true)}>Add Resources</button>
                                    {
                                        resourcePopups[chapterIndex] && (
                                            <div className="resource-container">
                                                <div className="resource-main">
                                                    <div className="resource-header">
                                                        <h4>Add a resource</h4>
                                                        <span>
                                                            <CancelIcon className='close-icon' onClick={() => handleResourcePopup(chapterIndex, false)} />
                                                        </span>
                                                    </div>
                                                    <div className="resource-items-wrapper">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'pdf')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/pdf.png`} alt="" />
                                                                        </div>
                                                                        <p>PDF File</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'ppt')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/ppt.png`} alt="" />
                                                                        </div>
                                                                        <p>PPT File</p>
                                                                    </button>
                                                                </div>
                                                                {/* <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'h5p')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/H5P.png`} alt="" />
                                                                        </div>
                                                                        <p>H5P File</p>
                                                                    </button>
                                                                </div> */}
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'sb3')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/program.png`} alt="" />
                                                                        </div>
                                                                        <p>Sb3 File</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'video')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/video.png`} alt="" />
                                                                        </div>
                                                                        <p>Video</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'quiz')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/quiz.png`} alt="" />
                                                                        </div>
                                                                        <p>Quiz</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'zip')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/zip.png`} alt="" />
                                                                        </div>
                                                                        <p>Zip File</p>
                                                                    </button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button className='resource-item' onClick={() => handleAddResource(chapterIndex, 'html')}>
                                                                        <div className="resource-item-img-wrapper">
                                                                            <img src={`${process.env.PUBLIC_URL}/images/chapter-resources/html-5.png`} alt="" />
                                                                        </div>
                                                                        <p>HTML File</p>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        }
                        <button className='custom-btn' onClick={handleAddChapter}>Add Chapter</button>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-input-wrapper">
                        <label>Number of sessions</label>
                        <input type="text" value={numberOfSessions} className='newCourseInput' onChange={(e) => setNumberOfSessions(e.target.value)} placeholder='No. of sessions' />
                        <span className='error-text'>{errors.numberOfSessions}</span>
                    </div>
                </div>

                <div className="col-12">
                    <div className="newStudentSubmitbtn">
                        <button className="custom-btn purple-bg" onClick={handleUpdateProduct}>
                            {/* <button className="custom-btn purple-bg" onClick={handleUpdateProduct}> */}
                            Submit
                        </button>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    {
                        success ? (
                            <div className='alert alert-success'>
                                {successMessage}
                            </div>
                        ) : error && (
                            <div className='alert alert-danger'>
                                {errorMessage}
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeLoader && (
                    <CircleLoader title={loaderText} />
                )
            }
        </div >

        // </DndProvider >

    )
}

export default EditCourseNew
