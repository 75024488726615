import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userRedux";
import {persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from 'redux-persist/lib/storage'
// import persistReducer from "redux-persist/es/persistReducer";
// import persistStore from "redux-persist/es/persistStore";
import productReducer from './productRedux'
import registeredUserRedux from "./registeredUserRedux";
import orderRedux from "./orderRedux";
import discountRedux from "./discountRedux";
import ageGroupRedux from "./ageGroupRedux";
import courseTypeRedux from "./courseTypeRedux";
import blogRedux from "./blogRedux";
import blogCategory from "./blogCategory";
import testimonialRedux from "./testimonialRedux";
import lectureRedux from "./lectureRedux";
import lectureLibraryRedux from "./lectureLibraryRedux";
import quizRedux from "./quizRedux";
import eventRedux from "./eventRedux";
import newsRedux from "./newsRedux";
import robotixProductRedux from "./robotixProductRedux";
import giftCardRedux from "./giftCardRedux";
import customSectionRedux from "./customSectionRedux";
import contactFormQueriesRedux from "./contactFormQueriesRedux";
import successStoriesRedux from "./successStoriesRedux";
import milestoneRedux from "./milestoneRedux";
import schoolFormQueriesRedux from "./schoolFormQueriesRedux";
import meritusStudentRedux from "./meritusStudentRedux";

const persistConfig = {
    key : 'root',
    version : 1,
    storage,
    whitelist: ['user'],
}

const rootReducer = combineReducers({user : userReducer, products: productReducer, allUsers : registeredUserRedux, orders : orderRedux, discounts : discountRedux, ageGroup : ageGroupRedux, courseType : courseTypeRedux, blog : blogRedux, blogCategory : blogCategory, testimonial : testimonialRedux, lecture : lectureRedux, lectureLibrary : lectureLibraryRedux, quiz : quizRedux, event : eventRedux, news : newsRedux, robotixProducts : robotixProductRedux, giftCards : giftCardRedux, customSections : customSectionRedux, contactFormQueries : contactFormQueriesRedux, successStories : successStoriesRedux, milestones : milestoneRedux, schoolFormQueries : schoolFormQueriesRedux, meritusStudents : meritusStudentRedux});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer : persistedReducer,
    middleware :(getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck : false,
            // serializableCheck : {
            //     ignoreActions : [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
        }),
});

export let persistor = persistStore(store);