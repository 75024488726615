import React, { useEffect, useRef, useState } from 'react';
import './Users.css';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../redux/apiCalls';
import { MEDIA_URL, addRowIndex, changeDateFormat } from '../../config';
import CircleLoader from '../../components/circleLoader/CircleLoader';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { fileUploadRequest, userRequest } from '../../requestMethod';

const Users = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    getAllUsers(dispatch);
  }, [dispatch]);

  const [csvFile, setCSVFile] = useState(null);
  const [isCSVUploaderOpen, setIsCSVUploaderOpen] = useState(false);
  const [isCSVFileUploaded, setIsCSVFileUploaded] = useState(false);
  const [isCSVFileUploading, setIsCSVFileUploading] = useState(false);
  const [isFileSuccess, setIsFileSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState("");

  if (csvFile) {
  }


  const isFetching = useSelector((state) => state.allUsers.isFetching);
  const users = useSelector((state) => state.allUsers.users);

  // const [usersWithIndex, setUsersWithIndex] = useState(null);

  // useEffect(() => {
  //   setUsersWithIndex(addRowIndex(users));
  // }, []);

  // Add index numbers to the users array
  const usersWithIndex = users?.map((user, index) => ({
    ...user,
    index: index + 1, // Add 1 to the index to start from 1 instead of 0
  }));

  const handleCSVOpener = () => {
    setIsCSVUploaderOpen(!isCSVUploaderOpen);
    setIsCSVFileUploaded(false);
    setCSVFile(null);
  }

  const fileInputRef = useRef(null);

  const handleChangeCSVFile = (e) => {
    const files = e.target.files
    setCSVFile(files);
    // const file = e.target.files[0];
    // // Check if a valid CSV file is selected
    // if (file && file.name.endsWith('.csv')) {
    //   setCSVFile(file);
    // } else {
    //   setCSVFile(null);
    // }
  }

  const handleAddCSVFile = () => {
    fileInputRef.current.click(); // Trigger file input click event
  };

  const handleCancelUpload = () => {
    handleCSVOpener();
    setCSVFile(null)
  }


  const handleCSVFileUplaod = async (file) => {
    try {
      setIsCSVFileUploading(true)
      const formData = new FormData();
      formData.append('csvFile', file);
      const result = await fileUploadRequest.post('/user/create-via-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


      if (result?.status === 200) {
        setHasError(false);
        setIsCSVFileUploading(false);
        setIsCSVFileUploaded(true);
        setIsFileSuccess(true);
        setSuccessMessage(result?.data?.message)
      }
    } catch (error) {
      setIsCSVFileUploading(false);
      setHasError(true);
      setIsFileSuccess(false);
      if (error?.response?.status === 400) {
        setIsCSVFileUploaded(true);
        setHasErrorMessage(error.response.data.message)
      }

      if (error?.response?.status === 500) {
        setIsCSVFileUploaded(true);
        setHasErrorMessage(error.response.data)
      }
    }
  }

  const handleUpload = () => {
    if (csvFile) {
      handleCSVFileUplaod(csvFile[0]); // Pass csvFile to the function
    }
  }

  const columns = [
    { field: 'index', headerName: '#', width: 50 },
    // {
    //   field: 'profileImg',
    //   headerName: 'Profile Image',
    //   width: 150,
    //   editable: false,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <img src={MEDIA_URL + params.value} className='courseTable-img' alt="profile-img" />
    //   }
    // },
    {
      field: 'firstName',
      headerName: 'Name',
      width: 150,
      editable: false,
    },

    {
      field: 'email',
      headerName: 'Email',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 300,
    },
    // {
    //   field: 'createdAt',
    //   headerName: 'Created At',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: true,
    //   width: 300,
    //   renderCell: (params) => {
    //     return (
    //      changeDateFormat(params.value)
    //     )
    //   }
    // },
    {
      field: 'View',
      headerName: 'View',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/view-user/' + params.id}>
              <button className='btn btn-primary viewSchoolBtn'>View <Visibility /> </button>
            </Link>
          </>
        )
      }
    },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Link to={'/edit-course/' + params.id}>
    //           <button className='btn btn-primary studentListEdit'>Edit</button>
    //         </Link>
    //         {/* <DeleteOutline className='studentListDelete text-danger' /> */}
    //       </>
    //     )
    //   }
    // },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {/* <Link to={'/edit-course/' + params.id}> */}
    //         {/* <button className='btn btn-primary studentListEdit'>Edit</button> */}
    //         {/* </Link> */}
    //         {/* <DeleteOutline onClick={() => deleteCourse(params.row._id)} className='studentListDelete text-danger' /> */}
    //       </>
    //     )
    //   }
    // },
  ];

  if (isFetching || isCSVFileUploading) {
    return (
      <div className='loader-wrapper'>
        {
          isCSVFileUploading ? (
            <CircleLoader title="Uploading" />
          ) : (
            <CircleLoader />
          )
        }

      </div>
    )
  }

  return (
    <div className='studentTableWrapper py-5'>
      <div className="row">
        <div className="col-12">
          <div className="tableButtonWrapper d-flex justify-content-between mb-3">
            <h2>Users</h2>
            {/* <Link to='/add-micro-course'> */}
            <button className='custom-btn purple-bg' onClick={handleCSVOpener}>Import CSV</button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      {
        users && (
          <DataGrid
            rows={usersWithIndex}
            getRowId={(rows) => rows._id}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection={false}
            disableRowSelectionOnClick
          />
        )
      }

      {
        isCSVUploaderOpen && (
          <div className="csv-box-wrapper">
            <div className="csv-box-container">
              <div className="cvs-box-header d-flex justify-content-between">
                <p>Import users via csv file</p>
                <button onClick={handleCSVOpener}>
                  <CancelIcon />
                </button>
              </div>
              <div className="cvs-box-middle">
                {
                  isCSVFileUploaded && (
                    <div className="csv-box-output-wrapper">
                      {
                        isFileSuccess && (
                          <>
                            <p className='p-3 text-center'>{successMessage}</p>
                            <p className='p-3 text-center'>Kindly refresh your page</p>
                          </>
                        )
                      }

                      {
                        hasError && (
                          <p className='p-3'>{hasErrorMessage}</p>
                        )
                      }

                    </div>
                  )
                }
                {
                  !isCSVFileUploaded && (
                    csvFile && csvFile[0]?.name ? (
                      <div className="csv-box-selected-file">
                        <strong>Selected file</strong>
                        <p>{csvFile[0]?.name}</p>
                        <button className='custom-btn purple-bg' onClick={handleAddCSVFile}>Replace File</button>
                      </div>
                    ) : (
                      <button onClick={handleAddCSVFile}>Add File</button>
                    )
                  )
                }
                <input type="file" name='csvFile' style={{ display: "none" }} ref={fileInputRef} onChange={handleChangeCSVFile} accept='.csv' />
              </div>
              {
                !isCSVFileUploaded && (
                  <div className="cvs-box-bottom d-flex justify-content-between">
                    <button className='custom-btn purple-bg' onClick={handleUpload}>Upload</button>
                    <button className='custom-btn purple-bg' onClick={handleCancelUpload}>Cancel</button>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Users
