import React, { useEffect, useState } from 'react';
import './SingleSchool.css';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../../redux/apiCalls';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { userRequest } from '../../../../requestMethod';

const SingleSchool = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        getAllUsers(dispatch);
    }, [dispatch]);

    const location = useLocation();
    const schoolId = location.pathname.split('/')[2];
    const currentSchool = useSelector((state) => state.allUsers.users?.find((item) => item._id === schoolId))

    // School admins variable
    const schoolAdmins = useSelector((state) => state.allUsers.users?.filter((item) => item.schoolId === schoolId && item.isSchoolAdmin === true))

    // School admins variable
    const schoolStudents = useSelector((state) => state.allUsers.users?.filter((item) => item.schoolId === schoolId && item.isSchoolStudent === true))

    const schoolAdminsWithIndex = schoolAdmins?.map((schoolAdmin, index) => ({
        ...schoolAdmin,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));

    const schoolStudentsWithIndex = schoolStudents?.map((schoolStudent, index) => ({
        ...schoolStudent,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));

    const alluser = useSelector((state) => state.allUsers.users);


    const [activeTable, setActiveTable] = useState("admin");

    // column for admin
    const adminColumn = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.schoolId === schoolId ? currentSchool.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'Admin Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-admin/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    // School HOD variable
    const schoolHOD = useSelector((state) => state.allUsers?.users?.filter((item) => item.schoolId === schoolId && item.isSchoolHOD === true));


    const schoolHODWithIndex = schoolHOD?.map((schoolHOD, index) => ({
        ...schoolHOD,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));



    // columns for HOD
    const hodColumn = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.schoolId === schoolId ? currentSchool.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'HOD Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-hod/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    // columns for students
    const studentsColumn = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.schoolId === schoolId ? currentSchool.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'HOD Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-hod/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];

    // School Teacher variable
    const schoolTeacher = useSelector((state) => state.allUsers.users.filter((item) => item.schoolId === schoolId && item.isSchoolTeacher === true));

    const schoolTeacherWithIndex = schoolTeacher?.map((schoolTeacher, index) => ({
        ...schoolTeacher,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));


    // Coulmn for teacher
    const teacherColumn = [
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'schoolName',
            headerName: 'School',
            width: 200,
            editable: false,
            renderCell: (params) => {
                return params.row.schoolId === schoolId ? currentSchool.schoolName : "Not associated with this school"
            }
        },
        {
            field: 'firstName',
            headerName: 'Teacher Name',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 200,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-school-teacher/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                        {/* <DeleteOutline className='studentListDelete text-danger' /> */}
                    </>
                )
            }
        },
    ];


    // Function to send request for generating the secret key
    const handleSecretKeyRequest = async () => {
        try {
            const res = await userRequest.post('/general/generate-secret-key', { email : currentSchool?.email});
        } catch (error) {
        }
    }


    // Function to verify secret key 
    const handleSecretKeyVerification = async () => {
        try {
            const res = await userRequest.post('/general/verify-secret-key', {email : currentSchool?.email, otp : "238001"});
        } catch (error) {
        }
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className="singleSchool-topSection">
                        <div className="singleSchool-title-wrapper">
                            <div className="singleSchoolLogoWrapper">
                                <img src={`${process.env.PUBLIC_URL}/Images/school-logo.png`} alt="userImg" />
                            </div>
                            <h2 className="page-heading text-capitalize">{currentSchool.schoolName}</h2>
                        </div>
                        <div className="schoolActionBtnWrapper">
                            <button className={`${activeTable === "admin" ? "active" : ""} btn`} onClick={() => setActiveTable("admin")}>School Admin</button>
                            <button className={`${activeTable === "hod" ? "active" : ""} btn`} onClick={() => setActiveTable("hod")}>School HOD</button>
                            <button className={`${activeTable === "teacher" ? "active" : ""} btn`} onClick={() => setActiveTable("teacher")}>School Teacher</button>
                            <button className={`${activeTable === "student" ? "active" : ""} btn`} onClick={() => setActiveTable("student")}>Student</button>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    {
                        activeTable === "admin" && (
                            <div className="schoolAdminTableWrapper">
                                <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                                    <h2>School Admins</h2>
                                    <Link to={`/add-school-admin/${schoolId}`}>
                                        <button className='custom-btn purple-bg'>Create Admin <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                                    </Link>
                                </div>
                                {
                                    schoolAdmins && (
                                        <DataGrid
                                            rows={schoolAdminsWithIndex}
                                            getRowId={(rows) => rows._id}
                                            columns={adminColumn}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10]}
                                            disableRowSelectionOnClick
                                        />
                                    )
                                }

                            </div>
                        )
                    }

                    {
                        activeTable === "hod" && (
                            <div className="schoolHODTableWrapper">
                                <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                                    <h2>School HODs</h2>
                                    <Link to={`/add-school-hod/${schoolId}`}>
                                        <button className='custom-btn purple-bg'>Create HOD <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                                    </Link>
                                </div>
                                {
                                    schoolHOD && (
                                        <DataGrid
                                            rows={schoolHODWithIndex}
                                            getRowId={(rows) => rows._id}
                                            columns={hodColumn}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10]}
                                            disableRowSelectionOnClick
                                        />
                                    )
                                }

                            </div>
                        )
                    }

                    {
                        activeTable === "teacher" && (
                            <div className="schoolTeacherTableWrapper">
                                <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                                    <h2>School Teachers</h2>
                                    <Link to={`/add-school-teacher/${schoolId}`}>
                                        <button className='custom-btn purple-bg'>Create Teacher <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                                    </Link>
                                </div>
                                {
                                    schoolTeacherWithIndex && (
                                        <DataGrid
                                            rows={schoolTeacherWithIndex}
                                            getRowId={(rows) => rows._id}
                                            columns={teacherColumn}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10]}
                                            disableRowSelectionOnClick
                                        />
                                    )
                                }

                            </div>
                        )
                    }

                    {
                        activeTable == "student" && (
                            <div className="schoolStudentWrapper">
                                <p className='text-center'>You must have a secret key to see the students data</p>
                                <input type="text" placeholder='Please enter the secret key' />
                                {/* <div className="submit-wrapper secret-key-btn-wrapper">
                                    <button className='custom-btn' onClick={handleSecretKeyRequest}>Request Key</button>
                                    <button className='custom-btn' onClick={handleSecretKeyVerification}>Submit</button>
                                </div> */}
                                <div className="submit-wrapper">
                                    {/* <button className='custom-btn' onClick={handleSecretKeyRequest}>Request Key</button> */}
                                    <button className='custom-btn'>Submit</button>
                                </div>
                            </div>
                            // <div className="schoolTeacherTableWrapper">
                            //     <div className="singleSchoolHeadingWrapper singleSchoolHeader mb-3">
                            //         <h2>School Students</h2>
                            //         {/* <Link to={`/add-school-teacher/${schoolId}`}>
                            //             <button className='custom-btn purple-bg'>Create Teacher <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            //         </Link> */}
                            //     </div>
                            //     {
                            //         schoolTeacherWithIndex && (
                            //             <DataGrid
                            //                 rows={schoolStudentsWithIndex}
                            //                 getRowId={(rows) => rows._id}
                            //                 columns={studentsColumn}
                            //                 initialState={{
                            //                     pagination: {
                            //                         paginationModel: {
                            //                             pageSize: 10,
                            //                         },
                            //                     },
                            //                 }}
                            //                 pageSizeOptions={[10]}
                            //                 disableRowSelectionOnClick
                            //             />
                            //         )
                            //     }

                            // </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SingleSchool
