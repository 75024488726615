import React from 'react';
import './WidgetLg.css';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';

const WidgetLg = (props) => {

  const Button = ({ type }) => {
    return <button className={"widgetLgButton" + type}>{type}</button>
  }

  const formateDate = (date) => {
    const fetchedDate = new Date(date);
    const formattedDate = fetchedDate.toLocaleDateString();
    return formattedDate
}

  return (
    <div className='widgetLg'>
      <span className="widgetLgTitle ">Recent Transactions</span>
      <table className="widgetLgTable">
        <thead>
          <tr className="widgetLgTr">
            <th className="widgetLgTh">Customer Name</th>
            <th className="widgetLgTh">Date</th>
            <th className="widgetLgTh">Amount</th>
            <th className="widgetLgTh">Status</th>
          </tr>
        </thead>
        <tbody>
          {
            props.orders.map((order, key) => {
              if (key <= 4) {
                return (
                  <tr className="widgetLgTr">
                    <td className="widgetLgUser">
                      <div className="widgetLgImgWrapper">
                        {
                          order.profileImg ? (
                            <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
                          ) : (
                            <img src={`${process.env.PUBLIC_URL}/images/profile.png`} alt="user_img" />
                          )
                        }
                      </div>
                      <span className="widgetLgUserName text-capitalize">{order.userId.firstName + " "+ order.userId.lastName}</span>
                    </td>
                    <td className="widgetLgDate">{formateDate(order.createdAt)}</td>
                    {/* <td className="widgetLgAmount">{order.amount}</td> */}
                    <td className="widgetLgAmount">
                      {
                        order?.currency === "USD" ? (
                          <span>{order.amount} &#36; </span>
                        ) : (
                          <span>{order.amount} &#8377; </span>
                        )
                      }
                    </td>
                    <td className="widgetLgStatus">
                      {
                        order.paymentStatus === "success" ? (
                          <Button type="Success" />
                        ) : order.paymentStatus === "pending" ? (
                          <Button type="Pending" />
                        ) : (
                          <Button type="Failed" />
                        )
                      }
                      
                    </td>
                  </tr>
                )
              } else{
                return null
              }
            })
          }

          {/* <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <div className="widgetLgImgWrapper">
              <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
            </div>
            <span className="widgetLgUserName">Katty lame</span>
          </td>
          <td className="widgetLgDate">2 May 2023</td>
          <td className="widgetLgAmount">$122.00</td>
          <td className="widgetLgStatus">
            <Button type="Declined" />
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <div className="widgetLgImgWrapper">
              <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
            </div>
            <span className="widgetLgUserName">Katty lame</span>
          </td>
          <td className="widgetLgDate">2 May 2023</td>
          <td className="widgetLgAmount">$122.00</td>
          <td className="widgetLgStatus">
            <Button type="Pending" />
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <div className="widgetLgImgWrapper">
              <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
            </div>
            <span className="widgetLgUserName">Katty lame</span>
          </td>
          <td className="widgetLgDate">2 May 2023</td>
          <td className="widgetLgAmount">$122.00</td>
          <td className="widgetLgStatus">
            <Button type="Failed" />
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <div className="widgetLgImgWrapper">
              <img src={`${process.env.PUBLIC_URL}/images/profile.jpg`} alt="user_img" />
            </div>
            <span className="widgetLgUserName">Katty lame</span>
          </td>
          <td className="widgetLgDate">2 May 2023</td>
          <td className="widgetLgAmount">$122.00</td>
          <td className="widgetLgStatus">
            <Button type="Pending" />
          </td>
        </tr> */}
        </tbody>
      </table>
    </div>
  )
}

export default WidgetLg
