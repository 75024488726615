import React, { useEffect, useState } from 'react';
import './SchoolStudentTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DeleteOutline } from '@mui/icons-material';
import { deleteUser, getAllUsersOfSchool } from '../../../../redux/apiCalls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const SchoolStudentTable = () => {

    const dispatch = useDispatch();

    // const currentSchool = useSelector((state) => state.user.currentUser);
    // const schoolId = currentSchool._id;

    const user = useSelector((state) => state.user.currentUser);

    let schoolId;

    user.isSchoolPrincipal ? schoolId = user._id : schoolId = user.schoolId;

    useEffect(() => {
        getAllUsersOfSchool(schoolId, dispatch)
    }, [dispatch, schoolId])

    const state = useSelector((state) => state);

    // School Teacher variable
    const schoolStudents = useSelector((state) => state.allUsers?.users?.filter((item) => item.schoolId === schoolId && item.isSchoolStudent === true));

    // Add index numbers to the array
  const schoolStudentsWithIndex = schoolStudents?.map((schoolStudent, index) => ({
    ...schoolStudent,
    index: index + 1, // Add 1 to the index to start from 1 instead of 0
  }));

    const handleDelete = (studentId) => {
        deleteUser(studentId, dispatch);
        setDeletePopup(false);
    }

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    const handlePopup = (id) => {
        setDeletePopup(true);
        setDeletingItem(id);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }


    const columns = [
        // { field: '_id', headerName: 'Student ID', width: 200 },
        { field: 'index', headerName: '#', width: 50 },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 100,
            editable: false,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 100,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
            editable: false,
            renderCell: (params) => {
            }
        },
        // {
        //     field: 'grade',
        //     headerName: 'Grade',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return params.value ? params.value : "Not assigned";
        //     }
        // },
        // {
        //     field: 'level',
        //     headerName: 'Level',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return params.value ? params.value : "Not assigned";
        //     }
        // },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
        },
        // {
        //     field: 'Action',
        //     headerName: 'Action',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Link to={'/edit-student/' + params.id}>
        //                     <button className='btn btn-primary studentListEdit'>Edit</button>
        //                 </Link>
        //             </>
        //         )
        //     }
        // },
        {
            field: '',
            headerName: 'Progress',
            sortable: false,
            width: 170,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/view-progress/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>View Progress</button>
                        </Link>
                    </>
                )
            }
        },
        // {
        //     field: 'delete',
        //     headerName: 'Delete',
        //     sortable: false,
        //     width: 150,
        //     renderCell: (params) => {
        //         const studentId = params.row._id;
        //         return (
        //             <>
        //                 <DeleteOutline onClick={() => handlePopup(studentId)} className='text-danger' />
        //             </>
        //         )
        //     }
        // },
    ];

    return (
        <div className='studentTableWrapper py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Students</h2>
                        <Link to={`/add-school-student/${schoolId}`}>
                            <button className='custom-btn purple-bg'>Create Student <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                        </Link>
                    </div>
                </div>
            </div>

            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This student will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => handleDelete(deletingItem)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                schoolStudents && (
                    <DataGrid
                        rows={schoolStudentsWithIndex}
                        columns={columns}
                        getRowId={(rows) => rows._id}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        // checkboxSelection
                        disableRowSelectionOnClick
                    />
                )
            }


            {
                schoolStudents?.length < 1 && (
                    <p className='text-center'>No student found</p>
                )
            }
        </div>
    )
}

export default SchoolStudentTable
