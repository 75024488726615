import React, { useEffect, useState } from 'react';
import './SingleOrder.css';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VerifiedIcon from '@mui/icons-material/Verified';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
import { getAllOrders } from '../../../redux/apiCalls';

const SingleOrder = () => {

  const [accountSection, setAccountSection] = useState(false);
  const [shippingSection, setShippingSection] = useState(false);
  const [paymentSection, setPaymentSection] = useState(false);
  const [activeClassForAcc, setActiveClassForAcc] = useState(false);
  const [activeClassForShipping, setActiveClassForShipping] = useState(false);
  const [activeClassForPayment, setActiveClassForPayment] = useState(false);

  useEffect(() => {
    setAccountSection(true);
    setActiveClassForAcc(true);
  }, []);

  const handleAccountSection = () => {
    setAccountSection(true);
    setShippingSection(false);
    setPaymentSection(false);
    setActiveClassForAcc(true);
    setActiveClassForShipping(false);
    setActiveClassForPayment(false);
  };
  const handleShippingSection = () => {
    setAccountSection(false);
    setPaymentSection(false);
    setShippingSection(true);
    setActiveClassForAcc(false);
    setActiveClassForShipping(true);
    setActiveClassForPayment(false);
  };
  const handlePaymentSection = () => {
    setPaymentSection(true);
    setAccountSection(false);
    setShippingSection(false);
    setActiveClassForAcc(false);
    setActiveClassForShipping(false);
    setActiveClassForPayment(true);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    getAllOrders(dispatch)
  }, [dispatch]);

  const location = useLocation();
  const orderId = location.pathname.split('/')[2];
  const order = useSelector((state) => state.orders.orders.find((item) => item._id == orderId));
  const isFetching = useSelector((state) => state.orders.isFetching);

  if (isFetching) {
    return (
      <div className='loader-wrapper'>
        <CircleLoader title="fetching" />
      </div>
    )
  }

  return (
    <div className='container'>
      <div className="row">
        <div className="col-10 mx-auto">
          <div className="orderWrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="order-left-wrapper">
                  {/* <div className="viewOrderImgWrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/product-img-ai.png`} alt="productIimg" />
                  </div> */}
                  <div className="viewOrderTabWrapper">
                    <ul>
                      <li className={accountSection && activeClassForAcc ? 'active' : ""} onClick={handleAccountSection}> <LocalMallIcon style={{ marginRight: "10px" }} /> Order Details</li>
                      <li className={shippingSection && activeClassForShipping ? 'active' : ""} onClick={handleShippingSection}><LocalShippingIcon style={{ marginRight: "10px" }} /> Shipping Address</li>
                      {/* <li className={paymentSection && activeClassForPayment ? 'active' : ""} onClick={handlePaymentSection}><CreditCardIcon style={{ marginRight: "10px" }} />Payment By</li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="order-right-wrapper">
                  {
                    accountSection ? (
                      <>
                        <div className="viewOrderHeadingWrapper">
                          <h2>Order Details</h2>
                          <div className='viewOrderPaymentStatusWrapper'>
                            {
                              order && order.paymentStatus === "success" && (
                                <VerifiedIcon style={{ color: "var(--green)" }} />
                              )
                            }
                            {
                              order && order.paymentStatus === "failed" && (
                                <CancelIcon style={{ color: "var(--red)" }} />
                              )
                            }
                            {
                              order && order.paymentStatus === "pending" && (
                                <PendingIcon style={{ color: "var(--orange)" }} />
                              )
                            }
                            {/* <PendingIcon style={{color:"var(--orange)"}}/> */}
                            <h4 className='text-capitalize'>{order && order.paymentStatus}</h4>
                          </div>
                        </div>
                        {
                          order && order.products.map((item, index) => {
                            return (
                              <div className="viewOrderWrapper">
                                <div className="viewOrderInfo">
                                  <div className="itemCountHeader">
                                    <p className='viewOrderTitle'>Product Id</p>
                                    {
                                      order.products.length > 1 ? (
                                        <>
                                          <p className='viewProductCount'>Item {index + 1}</p>
                                        </>
                                      ) : ""
                                    }
                                  </div>

                                  <p className='viewOrderDetail'>{item.productId?._id}</p>
                                </div>
                                <div className="viewOrderInfo">
                                  <p className='viewOrderTitle'>Title</p>
                                  <p className='viewOrderDetail'>{item.productId?.title}</p>
                                </div>
                                {/* {
                                  item.isRobotixProduct && ( */}
                                    <div className="viewOrderInfo">
                                      <p className='viewOrderTitle'>Quantity</p>
                                      <p className='viewOrderDetail'>{item.productId?.quantity}</p>
                                    </div>
                                  {/* )
                                } */}

                                {/* <div className="viewOrderInfo">
                                  <p className='viewOrderTitle'>Price</p>
                                  {
                                    order?.currency === "USD" ? (
                                      <p className='viewOrderDetail'>
                                        {item.priceInDollar} &#36;
                                      </p>
                                    ) : (
                                      <p className='viewOrderDetail'>
                                        {item.price}  &#8377;
                                      </p>
                                    )
                                  }
                                </div> */}
                              </div>
                            );
                          })
                        }
                        <div className="viewOrderBottom">
                          <div className="totalAmountWrapper">
                            <p className='totalAmountTitle'>Total Pay</p>
                            {/* <p>{order && order.amount} &#8377;</p> */}
                            {
                              order?.currency === "USD" ? (
                                <p>{order.amount} &#36; </p>
                              ) : (
                                <p>{order.amount} &#8377; </p>
                              )
                            }
                          </div>
                        </div>

                      </>
                    ) : shippingSection ? (
                      <>
                        <div className="viewOrderHeadingWrapper">
                          <h2>Shipping Address</h2>
                        </div>
                        <div className="viewOrderShippingWrapper">
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Name</p>
                            <p className='viewOrderDetail'>{`${order.address?.firstName ? order.address?.firstName : "Not Available"} ${order?.address?.lastName ? order?.address?.lastName : ""}`}</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Phone</p>
                            <p className='viewOrderDetail'>{order && order.address?.phoneNumber}</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Address</p>
                            <p className='viewOrderDetail'>{order && order.address?.address}</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>City</p>
                            <p className='viewOrderDetail'>{order && order.address?.city}</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Pin Code</p>
                            <p className='viewOrderDetail'>{order && order.address?.pinCode}</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Country</p>
                            <p className='viewOrderDetail'>{order && order.address?.country}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="viewOrderHeadingWrapper">
                          <h2>Payment Details</h2>
                        </div>
                        <div className="viewOrderPaymentWrapper">
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Card Number</p>
                            <p className='viewOrderDetail'>**** **** **** 8789</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Expiry Date</p>
                            <p className='viewOrderDetail'>Mar-2030</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Type</p>
                            <p className='viewOrderDetail'>Visa</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Bank</p>
                            <p className='viewOrderDetail'>State Bank of India</p>
                          </div>
                          <div className="viewOrderInfo">
                            <p className='viewOrderTitle'>Account Holder</p>
                            <p className='viewOrderDetail'>Moin Khan</p>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleOrder
