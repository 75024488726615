import React, { useState } from 'react';
import './NewStudent.css';
import { useDispatch } from 'react-redux';
import { addUserFailure, addUserStart, addUserSuccess } from '../../redux/registeredUserRedux';
import { userRequest } from '../../requestMethod';
import { validateRequired } from '../../formValidation';

const NewStudent = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const isMeritusStudent = true;
    const isSchoolStudent = true;
    const schoolId = props.schoolId;


    const dispatch = useDispatch();

    const addStudent = async (dispatch, student) => {
        dispatch(addUserStart());
        try {
            const res = await userRequest.post('user/create', student);
            dispatch(addUserSuccess(res.data));
            if (res.status === 200) {
                setSuccess(true);
                setSuccessMessage("Student has been added!");
                setFirstName('');
                setLastName('') ;
                setEmail('');
                setPhone('');
                setDateOfBirth('');
            }
        } catch (error) {
            dispatch(addUserFailure());
            if (error.response.status === 409) {
                setEmailError(true);
                setEmailErrorMessage(error.response.data);
            }

            else {
                setError(true);
                setErrorMessage("Something went wrong!");
            }
        }
    };

    // Validate function for form validation start=======
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};

        const firstNameValidErr = validateRequired(firstName);
        const lastNameValidErr = validateRequired(lastName);
        const emailValidErr = validateRequired(email);
        const phoneValidErr = validateRequired(phone);
        const dobValidErr = validateRequired(dateOfBirth);

        if (firstNameValidErr) {
            formIsValid = false;
            newErrors.firstName = firstNameValidErr;
        }
        if (lastNameValidErr) {
            formIsValid = false;
            newErrors.lastName = lastNameValidErr;
        }
        if (emailValidErr) {
            formIsValid = false;
            newErrors.email = emailValidErr;
        }
        if (phoneValidErr) {
            formIsValid = false;
            newErrors.phone = phoneValidErr;
        }
        if (dobValidErr) {
            formIsValid = false;
            newErrors.dob = dobValidErr;
        }

        setErrors(newErrors);
        return formIsValid;
    }
    // Validate function for form validation end==========z

    const handleStudentData = () => {
        setEmailError(false);
        setSuccess(false);

        if(validateForm()){
            props.isSchool ? (
                addStudent(dispatch, { firstName, lastName, email, phone, dateOfBirth, isSchoolStudent, schoolId })
            ) : (
                addStudent(dispatch, { firstName, lastName, email, phone, dateOfBirth, isMeritusStudent })
            )
        }
    }


    return (
        <>
            <div className="NewRegisterFormWrapper">
                <div className="container">
                    <div className="row">
                        <h1 className="page-heading py-4">Add new student</h1>
                        <div className="col-10">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>first name</label>
                                        <input type="text" className='newUserInput' placeholder='e.g. john' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        <span className='error-text'>{errors.firstName}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>last name</label>
                                        <input type="text" className='newUserInput' placeholder='e.g. smith' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        <span className='error-text'>{errors.lastName}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>email</label>
                                        <input type="text" className='newUserInput' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <span className='error-text'>{errors.email}</span>
                                        {
                                            emailError && (
                                                <p className='error-text text-danger'>{emailErrorMessage}</p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>phone</label>
                                        <input type="number" className='newUserInput' placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                        <span className='error-text'>{errors.phone}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>date of birth</label>
                                        <input type="date" className='newUserInput' placeholder='Date of birth' value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                                        <span className='error-text'>{errors.dob}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>Profile picture</label>
                                        <input type="file" className='newUserInput' />
                                    </div>
                                </div>
                                {/* <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>Grade</label>
                                        <select name="grade">
                                            <option value="">select a grade</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-input-wrapper">
                                        <label>Level</label>
                                        <select name="level">
                                            <option value="">select a level</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="newStudentSubmitbtn">
                                        <button className="custom-btn purple-bg" onClick={handleStudentData}>create</button>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    {
                                        success ? (
                                            <div className='alert alert-success'>
                                                {successMessage}
                                            </div>
                                        ) : error && (
                                            <div className='alert alert-danger'>
                                                {errorMessage}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewStudent
