import React, { useEffect, useState } from 'react';
import './OrderTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
// import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { getAllOrders } from '../../../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../global/Loader';
import { addRowIndex } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';
// import { userRequest } from '../../../requestMethod';
// import { useDispatch, useSelector } from 'react-redux';
// import { deleteProducts, getProducts } from '../../../redux/apiCalls';
// import Loader from '../../../global/Loader';

const OrderTable = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    getAllOrders(dispatch)
  }, [dispatch]);

  const orders = useSelector((state) => state.orders.orders.filter((order) => !order.isItFromSpecificAccess));
  // const allusers = useSelector((state) => state.allUsers.users);

  const isFetching = useSelector((state) => state.orders.isFetching);

  // Add index numbers to the orders array
  const ordersWithIndex = orders?.map((order, index) => ({
    ...order,
    index: index + 1, // Add 1 to the index to start from 1 instead of 0
  }));

  // const [ordersWithIndex, setOrdersWithIndex] = useState(null);

  // useEffect(() => {
  //   setOrdersWithIndex(addRowIndex(orders));
  // }, []);


  // const [updatedOrders, setUpdatedOrders] = useState([]);

  // const orderWithUser = orders.map((order) => {
  //   const user = allusers && allusers.find((user) => user._id === order.userId);
  //   if (user) {
  //     // setFullOrders[...fullOrders, ]
  //     return {
  //       ...order,
  //       user: user
  //     };
  //   };
  //   return order
  // });

  // useEffect(() => {
  //   setUpdatedOrders(orderWithUser);
  // }, [])


  const columns = [
    { field: 'index', headerName: '#', width: 50 },
    {
      field: 'firstName',
      headerName: 'Customer Name',
      cellClassName: 'text-capitalize',
      width: 200,
      editable: false,
      renderCell: (params) => {
        const fullName = params.row.userId.firstName + " " + params.row.userId.lastName
        return (
          fullName
        )
      },
    },

    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      width: 100,
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   // description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 100,
    //   // renderCell: (params) => {
    //   //     return params.row.status ? "Active" : "Draft";
    //   // }
    // },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      sortable: false,
      width: 150,
      cellClassName: 'payment-status-text',
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const fetchedDate = new Date(params.value);
        const formattedDate = fetchedDate.toLocaleDateString();
        return formattedDate
      }
    },
    {
      field: 'View',
      headerName: 'View Order',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/view-order/' + params.id}>
              <button className='btn btn-primary viewSchoolBtn'>View Order <Visibility /> </button>
            </Link>
          </>
        )
      }
    },

    // {
    //     field: 'delete',
    //     headerName: 'Delete',
    //     sortable: false,
    //     width: 100,
    //     renderCell: (params) => {
    //         return (
    //             <>
    //                 {/* <Link to={'/edit-course/' + params.id}> */}
    //                 {/* <button className='btn btn-primary studentListEdit'>Edit</button> */}
    //                 {/* </Link> */}
    //                 {/* <DeleteOutline onClick={() => deleteCourse(params.row._id)} className='studentListDelete text-danger' /> */}
    //             </>
    //         )
    //     }
    // },
  ];

  if (isFetching) {
    return (
      <div className='loader-wrapper'>
        <CircleLoader />
      </div>
    )
  }


  return (
    <div className='py-5'>
      <div className="row">
        <div className="col-12">
          <div className="tableButtonWrapper d-flex justify-content-between mb-3">
            <h2>Orders</h2>
            {/* <Link to='/create-order'>
              <button className='custom-btn purple-bg'>Create Order</button>
            </Link> */}
          </div>
        </div>
      </div>

      {
        orders && (
          <DataGrid
            rows={ordersWithIndex}
            getRowId={(rows) => rows._id}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection={false}
            disableRowSelectionOnClick
          />
        )
      }

    </div>
  )
}

export default OrderTable
