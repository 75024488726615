import React, { useEffect, useState } from 'react';
import './CourseTable.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProducts, getProducts } from '../../../redux/apiCalls';
import Loader from '../../../global/Loader';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { publicRequest } from '../../../requestMethod';
import { MEDIA_URL, addRowIndex, extractFileName, isUrl } from '../../../config';
import CircleLoader from '../../../components/circleLoader/CircleLoader';


const CourseTable = () => {

    const dispatch = useDispatch();

    const [deletePopup, setDeletePopup] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);
    const [productImageName, setProductImageName] = useState(null);

    const handlePopup = (id, filename) => {
        setDeletePopup(true)
        setDeletingItem(id)
        setProductImageName(filename);
    };

    const handlePopupCancel = () => {
        setDeletePopup(false)
    }

    useEffect(() => {
        getProducts(dispatch)
    }, [dispatch]);


    const isFetching = useSelector((state) => state.products.isFetching);
    // const course = useSelector((state) => state.products.products);
    // const courseOfRobo = useSelector((state) => state.products.products.filter((item) => item.isRobotixProduct));
    const course = useSelector((state) => state.products.products?.filter((item) => item && !item?.isRobotixProduct));

    // Add index numbers to the course array
    const courseWithIndex = course?.map((courseItem, index) => ({
        ...courseItem,
        index: index + 1, // Add 1 to the index to start from 1 instead of 0
    }));


    // const [courseWithIndex, setCourseWithIndex] = useState([]);

    // useEffect(() => {
    //     setCourseWithIndex(addRowIndex(course));
    // }, [course, dispatch]);


    const deleteCourse = (productId, filename) => {
        deleteProducts(productId, filename, dispatch);
        setDeletePopup(false);
    }

    const columns = [
        {
            field: 'index', headerName: '#', width: 50,
        },
        {
            field: 'productImg',
            headerName: 'Image',
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (params) => {
                if(isUrl(params.value)){
                    return <img src={params?.value[0]} className='courseTable-img' alt="product-img" />
                }
                else{
                    return <img src={ MEDIA_URL + params?.value[0]} className='courseTable-img' alt="product-img" />
                }
            }
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 200,
            editable: false,
        },

        {
            field: 'price',
            headerName: 'Price',
            description: 'This column has a value getter and is not sortable.',
            sortable: true,
            width: 100,
        },
        {
            field: 'status',
            headerName: 'Status',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return params?.row?.status ? "Active" : "Draft";
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/edit-course/' + params.id}>
                            <button className='btn btn-primary studentListEdit'>Edit</button>
                        </Link>
                    </>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const courseId = params.row._id;
                const productImageURL = params.row.productImg;
                const productDocumentURL = params.row.document;
                
                // const productImageName = extractFileName(productImageURL);
                // const documentFileName = extractFileName(productDocumentURL[0]);
                // Create a URL object
                // const url = new URL(productImageURL);
                // // Extract the filename
                // const filename = url.pathname.split('/').pop();

                return (
                    <>
                        <DeleteOutline onClick={() => handlePopup(courseId, productImageName)} className='courseListDelete text-danger' />
                    </>
                )
            }
        },
    ];

    if (isFetching) {
        return (
            <div className='loader-wrapper'>
                <CircleLoader />
            </div>
        )
    }

    return (

        <div className='py-5'>
            <div className="row">
                <div className="col-12">
                    <div className="tableButtonWrapper d-flex justify-content-between mb-3">
                        <h2>Courses</h2>
                        <div className="buttonsWrapper d-flex gx-2">
                            <Link to='/course-age-groups'>
                                <button className='custom-btn purple-bg'>View Age Groups <Visibility className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/course-types'>
                                <button className='custom-btn purple-bg'>View Course Type <Visibility className='newStudentIcon' /></button>
                            </Link>
                            <Link to='/add-course'>
                                <button className='custom-btn purple-bg'>New Course <AddCircleOutlineOutlinedIcon className='newStudentIcon' /></button>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            {
                deletePopup && (
                    <div className="deletePopupAlertWrapper">
                        <div className="deletePopupAlert">
                            <WarningAmberIcon style={{ color: "var(--orange)", fontSize: "40px" }} />
                            <h4>Warning</h4>
                            <div className="deleteAlertTextWrapper text-center">
                                <p>This course will be deleted permanently.</p>
                                <p>Are you sure?</p>
                            </div>

                            <div className="deleteBtnsWrapper">
                                <button className='btn' onClick={handlePopupCancel} style={{ background: "var(--green)", color: "var(--white)" }}>Cancel</button>
                                <button className='btn' onClick={() => deleteCourse(deletingItem, productImageName)} style={{ background: "var(--red)", color: "var(--white)" }}>Delete</button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                course && (
                    <DataGrid
                        rows={courseWithIndex}
                        getRowId={(rows) => rows._id}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection = {false}
                        disableRowSelectionOnClick
                    />
                )
            }

        </div>
    )
}

export default CourseTable
